import React from 'react'
import styled from 'styled-components'

// Core
import { P, TintedBlockH3 } from '../../elements/typography'

// Subcomponent
import LinkBlock from './linkBlock'

// Placeholder image
import CompanyCard from './companyCard'

// Module styling
const AllCompanyBlockWrapper = styled.div`
  background-size: auto 100%;

  .textWrapper {
    width: calc(100% - 320px);
    display: inline-block;
    vertical-align: bottom;
    padding-right: 80px;

    .titleWrapper {
      line-height: 4.2rem;
      min-height: 4.2rem;
      h3 {
        font-size: 2.5rem;
      }
    }

    p {
      max-width: 1000px;
      padding-bottom: 2rem;
    }
  }

  .imageWrapper {
    display: inline-block;
    vertical-align: top;
    width: 320px;
    margin-top: 30px;

    img {
      max-width: 100%;
      float: right;
    }
  }

  .linkWrapper {
    display: block;
    vertical-align: top;
    padding-top: 40px;
  }

  @media screen and (max-width: 1000px) {
    .textWrapper,
    .imageWrapper {
      width: 100%;
      padding: 0;
      margin: 0;
      display: block;
      max-width: 100%;
    }

    .imageWrapper {
      padding-top: 60px;

      > div {
        width: 100%;
        max-width: 100%;
      }
    }
  }
`

const AllCompanyBlock = ({ title, image, desc, companies, cards }) => {
  return (
    <AllCompanyBlockWrapper backgroundImage={image}>
      <div className="textWrapper">
        <div className="titleWrapper">
          <TintedBlockH3> {title} </TintedBlockH3>
        </div>
        <P text={desc} />

        <LinkBlock links={companies} />
      </div>
      <div className="imageWrapper">
        {/* <BuildImage image={image} /> */}
        {cards &&
          cards.map &&
          cards.map((card, i) => {
            return (
              <CompanyCard
                isMiniture
                key={`company_card-${i}`}
                company={card}
              />
            )
          })}
      </div>
    </AllCompanyBlockWrapper>
  )
}
AllCompanyBlock.defaultProps = {}
export default AllCompanyBlock
