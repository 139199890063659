import React from 'react'
import styled from 'styled-components'
// import { useHistory } from 'react-router-dom'

// import img from '../../../assets/media/building.png'
import BuildImage from '../../elements/imageBuilder'
import BuildLinkElement from '../../elements/linkBuilder'

import { RecentProjectsCardH3 } from '../../elements/typography'
import { BuildLinkWrapper } from '../../elements/linkBuilder'

const CardWrapper = styled.div`
  .imageContainer {
    min-width: 100%;
    height: 100%;
    max-height: 178px;
    overflow: hidden;
    margin-bottom: 12px;
    cursor: pointer;

    img {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: center center;

      ${props => props.imagePosition ? `
        
        object-position: ${props.imagePosition};
      ` : ''}
    }
  }

  span {
    position: relative;
    top: 6px;
  }

  strong {
    font-weight: 400;
  }

  @media screen and (max-width: 650px) {
    .imageContainer {
      max-height: 400px;
    }
  }

  .cardData {
    height: 130px;
    overflow: hidden;
  }
`

const Card = ({ image, title, link, data = {}, imagePosition }) => {
  // const history = useHistory()

  const processData = (dataObj) => {
    const Elements = []
    for (const [key, value] of Object.entries(dataObj)) {
      Elements.push(
        <p>
          {' '}
          <strong> {key}: </strong> {value}{' '}
        </p>
      )
    }

    return Elements
  }
  const cardData = processData(data)

  return (
    <CardWrapper imagePosition={imagePosition}>
      <div className="imageContainer">
        <BuildLinkWrapper link={link}>
          <BuildImage image={image} width={300} height={178} />
        </BuildLinkWrapper>
      </div>
      <RecentProjectsCardH3>
        <BuildLinkWrapper link={link}> {title} </BuildLinkWrapper>
      </RecentProjectsCardH3>
      <div className="cardData">{cardData}</div>
      <BuildLinkElement link={link} linkStyle="ReadMoreButton" showArrow />
      {/* <ReadMoreButton href={link}> Bekijken </ReadMoreButton> */}
    </CardWrapper>
  )
}
Card.defaultProps = {}
export default Card
