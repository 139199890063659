import React from 'react'

import Section from '../../modules/sharePage'
import SectionWrapper from '../../modules/sectionBackgroundWrapper'
// import media from '../../../assets/media/person.png'

const defaultSectionData = {
  type: 'blank',
  paddingType: 'blank',
}

const SharePage = (props) => {
  const newProps = {
    ...defaultSectionData,
    ...props.sectionData,
    notFullWidth: !props.isFullScreen,
  }
  return (
    <SectionWrapper {...newProps}>
      <Section {...props} />
    </SectionWrapper>
  )
}
SharePage.defaultProps = {}
export default SharePage
