import React from 'react'
import styled from 'styled-components'
import { SmallHeader } from '../../../../elements/typography'
import BigArticleCard from './bigCard'
import SmallArticleCard from './smallCard'

const SearchResultsContainer = styled.div`
  background-color: #fff;
  /* padding: 0 2rem; */
  .articlesTitle {
    h3 {
      color: #000000;
    }
  }

  .bigArticles {
    padding: 0 0 30px 0;

    > div + div {
      margin-left: 14px;
      width: calc(33.33% - 14px);

      @media screen and (max-width: 800px) {
        width: 100%;
        margin: 0 0 22px 0 !important;
      }
    }

    h3,
    p {
      height: 52px;
      overflow: hidden;
    }

    h3 {
      height: 45px;
    }

    > div:first-child {
      width: 33.33%;

      @media screen and (max-width: 800px) {
        width: 100%;
        margin: 0 0 22px 0;
      }
    }
  }
  .smallArticles {
    padding: 30px 0;
    border-bottom: 1px solid #005496;
    border-top: 1px solid #005496;

    h3,
    p {
      height: 45px;
      overflow: hidden;
    }
  }

  @media screen and (max-width: 680px) {
    font-size: 16px;
    .bigArticles {
      padding: 0;
      > div + div {
        margin: 0;
      }
    }
    .smallArticles {
      padding: 0;
      border: 0;
    }
  }
`

const SearchResults = ({ results, texts, searchValue }) => {
  // console.log(results);

  let bigArticles = results?.items.slice(0, 3) || []
  let smallArticles = results?.items.slice(3, 5) || []
  return (
    <SearchResultsContainer>
      <div className="articlesTitle">
        <SmallHeader>
          {searchValue.length < 5
            ? texts.title
            : `${texts.title} '${searchValue}'`}
        </SmallHeader>
      </div>
      {bigArticles.length > 0 && (
        <div className="bigArticles">
          {bigArticles.map((article, index) => {
            return (
              <BigArticleCard
                key={`big-article-${article.title}-${index}`}
                {...article}
              />
            )
          })}
        </div>
      )}
      {smallArticles.length > 0 && (
        <div className="smallArticles">
          {smallArticles.map((article, index) => {
            return (
              <SmallArticleCard
                key={`small-article-${article.title}-${index}`}
                {...article}
              />
            )
          })}

          {bigArticles.length === 0 && smallArticles.length === 0 && (
            <div>{texts.notFound || 'Geen resultaten gevonden'}</div>
          )}
        </div>
      )}
    </SearchResultsContainer>
  )
}

export default SearchResults
