import React from 'react'
import styled from 'styled-components'

import { PageWrapper, WIDTH } from '../../elements/pageContainer'

// Core
import { TintedBlockH3, P } from '../../elements/typography'
import BuildLinkElement from '../../elements/linkBuilder'

// Module styling
const BLOCK_WIDTH = WIDTH / 2
const BigMediaTextBlock = styled.div`
  background: #f7fbfc;
  .mediaCol {
    width: 50%;
    position: relative;
    display: inline-block;
    vertical-align: top;

    .mediaOverlay {
      padding: 180px 0;

      > div {
        padding: 0px 0px;
      }

      p {
        padding: 0rem 0 4.25rem 0;

        p {
          padding: 0 !important;
          margin: 0 0 12px 0 !important;
        }
      }
    }

    span {
      a {
        font-size: 1rem;
      }
      svg {
        margin-top: -1px;
      }
    }

    h3 {
      font-size: 2.5rem;
      height: 7.5rem;
    }

    &.hasMedia {
      background: #005496;
      background-size: cover;
      background-repeat: no-repeat;

      .mediaOverlay {
        background: rgba(0, 84, 150, 0.8);
      }

      a,
      h3,
      p {
        color: #fff;
      }

      svg g {
        path,
        line {
          stroke: #fff;
        }
      }

      span {
        :hover {
          a {
            color: #00a1d5;
          }

          svg {
            g path,
            g line {
              stroke: #00a1d5;
            }
          }
        }
      }
    }

    .alignRight,
    .alignLeft {
      width: 100%;
      max-width: calc(${BLOCK_WIDTH}px);
    }

    .alignRight {
      float: right;
      padding: 0px 8vw 0 0;
    }

    .alignLeft {
      float: left;
      padding: 0px 0px 0 8vw;
    }
  }

  @media screen and (max-width: 1900px) {
    .mediaCol {
      .alignRight,
      .alignLeft {
        width: 100%;
        max-width: calc(${BLOCK_WIDTH}px);
        padding: 0px 8vw;
      }
    }
  }

  @media screen and (max-width: 940px) {
    .mediaCol {
      width: 100%;
      display: block;

      .alignRight,
      .alignLeft {
        width: 100%;
        max-width: calc(${BLOCK_WIDTH}px);
        padding: 0px 8vw;
      }

      .mediaOverlay {
        padding-top: 180px;
        padding-bottom: 180px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .mediaCol {
      .mediaOverlay {
        padding-top: 60px;
        padding-bottom: 60px;
      }
    }
  }
`

const FullscreenWrapper = ({ children }) => <div> {children} </div>
const SmallWrapper = ({ children }) => <PageWrapper> {children} </PageWrapper>

let checkInterval
let checks = 0
const BigMediaTextModule = ({
  blocks = [],
  small,
  fullscreenAlign = true,
  children,
}) => {
  const Wrapper = !small ? FullscreenWrapper : SmallWrapper
  const containerReference = React.useRef()
  let handleResponsive = null

  const handleContentSizing = () => {
    const elements = containerReference?.current?.querySelectorAll(
      '.textBlockSection'
    )

    if (elements && elements.length > 0) {
      const newDom = [...elements]
      let minHeight = 0

      newDom.forEach((element) => {
        element.removeAttribute('style')
      })

      newDom.forEach((element) => {
        if (element.offsetHeight > minHeight) {
          minHeight = element.offsetHeight
        }
      })

      newDom.forEach((element) => {
        element.setAttribute('style', `min-height: ${minHeight}px ;`)
      })

      const overlayElements = containerReference?.current?.querySelectorAll(
        '.mediaOverlay'
      )

      if (overlayElements && overlayElements.length > 0) {
        const newElementsArray = [...overlayElements]

        newElementsArray.forEach((overlayEle) => {
          if (minHeight > 400) {
            overlayEle.setAttribute('style', 'padding: 120px 0;')
          } else {
            overlayEle.setAttribute('style', '')
          }
        })
      }
    }
  }

  React.useEffect(() => {
    handleContentSizing()

    const handleResize = () => {
      clearTimeout(handleResponsive)

      handleResponsive = setTimeout(() => {
        handleContentSizing()
      }, 35)
    }

    clearInterval(checkInterval)
    checkInterval = setInterval(() => {
      checks = checks + 1

      if (checks >= 4) {
        clearInterval(checkInterval)
      }
      handleContentSizing()
    }, 500)

    // Event listener
    window.removeEventListener('resize', handleResize)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Wrapper>
      <BigMediaTextBlock ref={containerReference}>
        {children}
        {!children &&
          blocks &&
          blocks.map &&
          blocks.map((col, i) => {
            const hasMedia = !!col?.image?.src
            const mediabg = col?.image?.src
              ? `${process.env.REACT_APP_URL || ''}/resize/900/0?image=${
                  col?.image?.src
                }`
              : ''

            const style = { backgroundImage: `url(${mediabg})` }

            if (col?.image?.position) {
              style.backgroundPosition = col?.image?.position
            }

            return (
              <div
                key={`big-media-item-${Date.now()}-${i}`}
                style={style}
                className={`mediaCol ${
                  hasMedia ? 'hasMedia mediaElement' : ''
                }`}
              >
                <div className="mediaOverlay">
                  <div>
                    <div
                      className={`${
                        fullscreenAlign
                          ? ` ${i % 2 ? 'alignLeft' : 'alignRight'}`
                          : ''
                      }`}
                    >
                      <TintedBlockH3> {col?.title} </TintedBlockH3>
                      <div className="textBlockSection">
                        {' '}
                        <P dontCrop text={col?.text || ''}></P>{' '}
                      </div>

                      <BuildLinkElement
                        link={col.link}
                        linkStyle="ReadMoreButton"
                        showArrow
                      />
                    </div>
                    <div style={{ clear: 'both' }}></div>
                  </div>
                </div>
              </div>
            )
          })}
      </BigMediaTextBlock>
    </Wrapper>
  )
}
BigMediaTextModule.defaultProps = {}
export default BigMediaTextModule
