import React, { useState } from 'react'

import BigMediaBlock from '../../modules/bigMediaTextBlock'
import Iframe from '../../elements/iframe'
import { TintedBlockH3, P } from '../../elements/typography'
import { ButtonInversed } from '../../elements/typography'
import styled, { createGlobalStyle } from 'styled-components'

const ScrollLockOverlay = createGlobalStyle`
  body,html {
    overflow:hidden;
  }
`

const OverlayBody = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 29, 70, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  animation: fadein 0.5s;
  box-sizing: border-box;
  padding: 40px 80px;

  @media screen and (max-width: 680px) {
    padding: 40px;
  }

  .modal,
  .overlay {
    z-index: 20;
    background: #fff;
    width: 100%;
    height: 100%;
    animation: fadein 0.5s;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .close-button {
    border: none;
    background: transparent;
    cursor: pointer;
    color: #fff;
    font-size: 36px;
    position: absolute;
    right: 0px;
    padding: 6px 15px;
  }

  .close-button:hover {
    color: #fafafa;
  }
`

const Overlay = ({ isOpen, setIsOpen, iFrame }) => {
  const elementRef = React.useRef()

  const handleClicksInOverlay = (e) => {
    const { pageX, pageY } = e
    const {
      left,
      top,
      bottom,
      right,
    } = elementRef.current.getBoundingClientRect()

    if (pageY >= top && pageY <= bottom && pageX >= left && pageX <= right) {
      return
    } else {
      setIsOpen(!isOpen)
    }
  }

  return (
    <OverlayBody onClick={handleClicksInOverlay}>
      <div className="overlay">
        <div className="modal" ref={elementRef}>
          <button onClick={() => setIsOpen(!isOpen)} className="close-button">
            &times;
          </button>
          <Iframe title="100jaarvorm" src={iFrame} />
        </div>
      </div>
    </OverlayBody>
  )
}

let handleResponsive = null
let checkInterval
let checks = 0
const Section = ({
  iFrame,
  blocks = [],
  small,
  fullscreenAlign = true,
  newsIframe,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isNewsOpen, setIsNewsOpen] = useState(false)
  const containerReference = React.useRef()

  const block1 = blocks[0] ?? []
  const block2 = blocks[1] ?? []

  const handleContentSizing = () => {
    const wrapper = containerReference?.current

    if (wrapper) {
      const height = wrapper.offsetHeight

      wrapper.parentNode.querySelector(
        '.mediaElement'
      ).style.height = `${height}px`
      wrapper.parentNode
        .querySelector('.overlayBlue')
        .setAttribute('style', `height: ${height}px;`)
    }
  }

  React.useEffect(() => {
    handleContentSizing()

    const handleResize = () => {
      clearTimeout(handleResponsive)

      handleResponsive = setTimeout(() => {
        handleContentSizing()
      }, 35)
    }

    clearInterval(checkInterval)
    checkInterval = setInterval(() => {
      checks = checks + 1

      if (checks >= 4) {
        clearInterval(checkInterval)
      }
      handleContentSizing()
    }, 500)

    // Event listener
    window.removeEventListener('resize', handleResize)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isOpen, isNewsOpen])

  return (
    <div>
      <BigMediaBlock small={small} fullscreenAlign={fullscreenAlign}>
        {isOpen && <ScrollLockOverlay />}
        <div
          key={`big-media-item-${Date.now()}-${1}`}
          className={`mediaCol`}
          ref={containerReference}
        >
          <div className="mediaOverlay">
            <div>
              <div className={`${fullscreenAlign ? `alignLeft` : ''}`}>
                <TintedBlockH3> {block1.title} </TintedBlockH3>
                <div className="textBlockSection">
                  {' '}
                  <P dontCrop text={block1.text || ''}></P>{' '}
                </div>

                <ButtonInversed onClick={() => setIsOpen(!isOpen)}>
                  {block1.link?.linkText}
                </ButtonInversed>

                {newsIframe && (
                  <ButtonInversed onClick={() => setIsNewsOpen(!isOpen)}>
                    {block1.newsLink?.linkText}
                  </ButtonInversed>
                )}
              </div>
              <div style={{ clear: 'both' }}></div>
            </div>
          </div>
        </div>

        <div
          key={`big-media-item-${Date.now()}-${2}`}
          style={{ backgroundImage: `url(${block2.image?.src})` }}
          className={`mediaCol ${
            block2.image?.src ? 'hasMedia mediaElement' : ''
          }`}
        >
          <div className="mediaOverlay overlayBlue">
            <div>
              <div className={`${fullscreenAlign ? ` alignRight` : ''}`}></div>
            </div>
          </div>
        </div>
      </BigMediaBlock>
      {isOpen && iFrame && (
        <Overlay iFrame={iFrame} isOpen={isOpen} setIsOpen={setIsOpen} />
      )}

      {isNewsOpen && newsIframe && (
        <Overlay
          iFrame={newsIframe}
          isOpen={isNewsOpen}
          setIsOpen={setIsNewsOpen}
        />
      )}
    </div>
  )
}
Section.defaultProps = {}
export default Section
