import React from 'react'
import styled from 'styled-components'

// import { PageWrapper } from '../../elements/pageContainer'
import LinkBuilder from '../../elements/linkBuilder'

const BreadWrapper = styled.div`
  position: relative;
  z-index: 9;

  ${(props) => (props.noPad ? 'padding-bottom: 0;' : 'padding-bottom: 60px;')}

  @media screen and (max-width: 950px) {
    padding-bottom: 0;
  }

  strong {
    font-weight: light !important;
    color: #000;
    padding-right: 8px;
  }

  > span {
    padding: 8px;
    display: inline-block;
    vertical-align: top;
  }

  .breadcrumbitem {
    display: inline-block;
    vertical-align: top;
    padding: 8px 0;

    span.arrow {
      margin: 0 8px 0 4px;
      position: relative;
      top: 1px;
    }

    a {
      color: #000;
    }

    &.active {
      a {
        color: #005496;
        font-weight: 500;
      }
    }
  }

  @media screen and (max-width: 1800px) {
    .contentPadding {
      width: 100%;
      padding-left: 8vw !important;
      padding-right: 8vw !important;
    }
  }
`

const BreadModule = ({ data = [], noPad }) => {
  if (!data || data?.length === 0) {
    return null
  }

  return (
    <BreadWrapper noPad={noPad}>
      <span> Je bent hier: </span>
      {data &&
        data.map &&
        data.map((item, key) => (
          <div
            key={`breadcrumb-${key}`}
            className={`breadcrumbitem ${
              key === data?.length - 1 ? 'active' : ''
            }`}
          >
            <LinkBuilder link={item} />

            {key === data?.length - 1 ? null : (
              <span className="arrow"> {`>`} </span>
            )}
          </div>
        ))}
    </BreadWrapper>
  )
}
BreadModule.defaultProps = {}
export default BreadModule
