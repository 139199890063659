import * as React from 'react'
import styled from 'styled-components'

const ProtectedLinkWrapper = styled.div`
  cursor: pointer;
  display: inline-block !important;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

export default function ProtectedLink({ link, type }) {
  if (!link || !type || !['email', 'phone'].includes(type)) return

  const handleClick = () => {
    window.location = `${type == 'email' ? 'mailto' : 'tel'}:${link}`
  }

  return (
    <ProtectedLinkWrapper onClick={handleClick}>{link}</ProtectedLinkWrapper>
  )
}
