import React from 'react'

import WrapperSection from '../../../core/modules/expertiseBlock'
import ExpertiseIconBlock from '../../modules/expertisesIconBlock'
// import media from '../../../assets/media/person.png'

const ExpertiseSection = (props) => {
  return (
    <WrapperSection {...props}>
      <ExpertiseIconBlock
        expertises={props.expertises}
        link={props.expertiseLink}
      />
    </WrapperSection>
  )
}
ExpertiseSection.defaultProps = {}
export default ExpertiseSection
