import React from 'react'

import BigGradientMediaBlock from '../../../core/modules/bigMediaGradientBlock'
// import imageElement from '../../../assets/media/person.png'

const Section = (props) => {
  return <BigGradientMediaBlock {...props} />
}
Section.defaultProps = {}
export default Section
