import React from "react"

import {RecentProjectsCardH3} from "../../../elements/typography"
import CheckboxField from "../../../elements/checkBox"

const Checkbox = ({ title, subTitle, filters, updateLocalState }) => {
  return <>
    {title && title?.length > 0 && (
      <RecentProjectsCardH3> {title} </RecentProjectsCardH3>
    )}
    {subTitle && subTitle?.length > 0 && (
      <p> {subTitle} </p>
    )}

    {filters &&
      filters.map((filter) => {
        return (
          <div
            className="filterElement"
            key={`filter-${filter.labelId}`}
          >
            <CheckboxField
              {...filter}
              onChange={() => {
                updateLocalState(filter.labelId)
              }}
            />
          </div>
        )
    })}
  </>
}

export default Checkbox
