import React from 'react'
import styled from 'styled-components'

// Core
import { P, TintedBlockH3 } from '../../elements/typography'
import BuildImage from '../../elements/imageBuilder'

import bg from '../../../assets/svg/bluedots_upperright.svg'

const StatementContainer = styled.div`
  padding-top: 180px;

  position: relative;
  h3 {
    padding-bottom: 2rem;
    max-width: 900px;
  }

  p {
    white-space: normal;
    max-width: 900px;

    padding: 0 !important;

    h3,
    h4 {
      padding: 18px 0 !important;
      margin: 0 !important;
    }

    p {
      padding: 0 !important;
      margin: 0 !important;
    }
  }

  .background {
    position: absolute;
    top: 0;
    right: 0;
    width: 430px;
  }

  @media screen and (max-width: 600px) {
    padding-top: 180px;

    .background {
      top: 30px;
    }
  }

  .attachmentContainer {
    padding-bottom: 32px;

    span {
      display: inline-block;
      padding: 0 12px;

      a {
        color: #005496;
        font-weight: bold;
      }
    }
  }
`

const StatementBlock = ({ title, desc, attachments }) => {
  return (
    <StatementContainer>
      {attachments !== undefined && Array.isArray(attachments.files) && (
        <div className="attachmentContainer">
          <strong>{attachments.title}</strong>{' '}
          {attachments.files.map((item) => (
            <span key={item.title}>
              <a href={item.href} target="_blank" rel="noreferrer">
                {' '}
                {item.title}{' '}
              </a>
            </span>
          ))}
        </div>
      )}
      <TintedBlockH3> {title} </TintedBlockH3>
      <P text={desc} dontCrop />
      <div className="background">
        <BuildImage image={{ src: bg }} />
      </div>
    </StatementContainer>
  )
}
StatementBlock.defaultProps = {}
export default StatementBlock
