import React from 'react'
import styled from 'styled-components'
import { Characteristic } from '../../elements/typography'
import { ReactComponent as PhoneIcon } from '../../../assets/svg/ic_call.svg'
import ProtectedLink from '../../elements/protectedLink'

const Wrapper = styled.div`
  display: inline-block !important;
  width: 100%;
  height: 100%;
  padding-top: 40px;

  svg {
    display: inline-block;
    vertical-align: middle;
    padding-right: 22px;
  }

  .textWrapper {
    display: inline-block;
    vertical-align: middle;

    p {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 400px) {
    svg {
      padding: 0;
      display: block;
      margin-bottom: 20px;
      margin-left: 10px;
      display: none;
    }
    .textWrapper {
      display: block;
      padding: 8px 0;
    }
  }
`

// Element
const InfoElement = ({ phone, email }) => {
  return (
    <Wrapper>
      <PhoneIcon />
      <div className="textWrapper">
        <Characteristic>
          {' '}
          <strong>Telefoon:</strong> <ProtectedLink link={phone} type="phone" />
        </Characteristic>
        {/* {fax && fax.length > 0 && <Characteristic>Fax: {fax}</Characteristic>} */}
        <Characteristic>
          {' '}
          <strong>E-mail:</strong> <ProtectedLink link={email} type="email" />
        </Characteristic>
      </div>
    </Wrapper>
  )
}
InfoElement.defaultProps = {}

export default InfoElement
