import React from 'react'
import styled from 'styled-components'
import { Characteristic, ContactAddress } from '../../elements/typography'

import { ReactComponent as MailIcon } from '../../../assets/svg/ic_mail.svg'
import { ReactComponent as ContactIcon } from '../../../assets/svg/ic_contact.svg'

const Wrapper = styled.div`
  display: inline-block !important;
  width: 100%;
  height: 100%;
  padding-top: 40px;

  svg {
    display: inline-block;
    vertical-align: middle;
    padding-right: 22px;
  }

  .textWrapper {
    display: inline-block;
    vertical-align: middle;
  }

  @media screen and (max-width: 400px) {
    svg {
      padding: 0;
      display: block;
      margin-bottom: 20px;
      margin-left: 10px;
      display: none;
    }
    .textWrapper {
      display: block;
      padding: 8px 0;
    }
  }

  .smallIcon {
    transform: scale(0.8);
  }
`

let mapIcon = {
  mail: MailIcon,
  contact: ContactIcon,
}

// Element
const AdressElement = ({ title, address = {}, type, smallIcon }) => {
  let { name, street, postal_code, city } = address
  let Icon = mapIcon[type]
  return (
    <Wrapper>
      {Icon && <Icon className={`${smallIcon ? 'smallIcon' : 'regular'}`} />}
      <div className="textWrapper">
        <Characteristic>{title}</Characteristic>
        <ContactAddress>{name}</ContactAddress>
        <ContactAddress>{street}</ContactAddress>
        <ContactAddress>
          {postal_code} {city}
        </ContactAddress>
      </div>
    </Wrapper>
  )
}
AdressElement.defaultProps = {}

export default AdressElement
