import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

const TabsStyling = styled.div`
  > div > div > div {
    display: inline-block !important;
    vertical-align: top;

    &:nth-child(3) {
      width: calc(100% - 72px);
      margin-right: 12px;
      margin-left: 12px;
    }

    &:nth-child(1),
    &:nth-child(4) {
      position: relative;
      top: 3px;
      width: 20px !important;
      overflow: hidden;
      height: 20px;
    }
  }

  .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3;
  }

  button {
    height: auto !important;
  }
`

const useTabStyles = makeStyles(() => ({
  root: {
    '& button': {
      fontFamily: "'Ekster', sans-serif !important",
      color: '#005496 !important',
      fontWeight: 400,
      fontSize: '0.8rem',
      padding: '8px 0px 4px',
      lineHeight: '0.8rem',
      height: '12px',
      minHeight: '25px',
      width: 'auto',
      minWidth: '20px',
      marginRight: '30px',
    },
  },
}))

// Module
const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const BasicTabs = (props) => {
  let {
    tabs,
    InnerComponent,
    defaultTab = 1,
    scrollButtons = true,
    setTab = () => {},
  } = props

  // If url contains ?defaultTab=1
  // use that to assign the default tab
  const urlQuery = new URLSearchParams(window.location.search)
  const defaultTabFromQuery = urlQuery.get('defaultTab') || defaultTab

  const defaultTabValue = defaultTabFromQuery
    ? parseInt(defaultTabFromQuery) - 1 > -1 &&
      parseInt(defaultTabFromQuery) - 1 <= tabs.length
      ? parseInt(defaultTabFromQuery) - 1
      : 0
    : 0

  // Setup tabs
  const classes = useTabStyles()
  const [value, setValue] = React.useState(defaultTabValue)

  const handleChange = (event, newValue) => {
    setTab(newValue)
    setValue(newValue)
  }

  if (!tabs) {
    return null
  }

  return (
    <Box sx={{ width: '100%' }}>
      <TabsStyling>
        <Box sx={{ borderBottom: 0, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="statement_tabs"
            className={classes.root}
            TabIndicatorProps={{ style: { background: '#005496' } }}
            variant="scrollable"
            scrollButtons={scrollButtons}
            allowScrollButtonsMobile
          >
            {tabs &&
              tabs.map &&
              tabs.map((tab, index) => {
                return (
                  <Tab
                    wrapped
                    label={tab.title}
                    {...a11yProps(index)}
                    key={tab.title}
                  />
                )
              })}
          </Tabs>
        </Box>
      </TabsStyling>
      {tabs &&
        tabs.map((tab, index) => {
          let Component = InnerComponent
          return (
            <TabPanel value={value} index={index} key={tab.title}>
              <Component {...tab} />
            </TabPanel>
          )
        })}
    </Box>
  )
}

BasicTabs.defaultProps = {}

export default BasicTabs
