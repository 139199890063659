import React from 'react'
import styled from 'styled-components'
import InnerHTML from 'dangerously-set-html-content'

// Core
import SectionWrapper from '../../modules/sectionBackgroundWrapper'
import { P, TintedBlockH3 } from '../../elements/typography'

const InnerHTMLWrapper = styled.div`
  padding: 20px 0;

  .textContentWrapper {
    padding-bottom: 20px;
  }
`

// Section
const defaultSectionData = {
  type: 'blank',
  paddingType: 'blank',
  notFullWidth: true,
  titleType: 'tintedBlockH3',
}

const CookieBotSection = ({
  sectionData,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  title,
  desc,
}) => {
  const newProps = {
    ...defaultSectionData,
    ...sectionData,
    notFullWidth: true,
  }
  return (
    <SectionWrapper
      {...newProps}
      topQuote={topQuote}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
    >
      <div className="cookiebotDec">
        <div className="textContentWrapper">
          <div className="textWrapper">
            <div className="titleWrapper">
              {title && <TintedBlockH3> {title} </TintedBlockH3>}
            </div>
            {desc && <P text={desc} />}
          </div>
        </div>

        <InnerHTMLWrapper>
          <InnerHTML
            html={`<script id="CookieDeclaration" src="https://consent.cookiebot.com/b380f6eb-dd3a-4ee6-aaf0-6813ff49fd04/cd.js" type="text/javascript" async></script>`}
          />
        </InnerHTMLWrapper>
      </div>
    </SectionWrapper>
  )
}
CookieBotSection.defaultProps = {}

export default CookieBotSection
