import React from 'react'
import styled from 'styled-components'

import { P, TintedBlockH3 } from '../../elements/typography'
import BuildLinkElement from '../../elements/linkBuilder'

const AllVacancyCardWrapper = styled.div`
  width: 260px;
  background-color: #00a1d5;
  overflow: hidden;

  .bg-wrapper {
    position: relative;
    height: 220px;

    ${(props) => (props.portraitImage ? 'height: 350px;' : '')}
  }

  h3 {
    color: #fff !important;
    padding: 1rem;
  }

  span {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 1rem;

    a {
      color: #fff !important;
      text-decoration: none;
    }
    svg g {
      path,
      line {
        stroke: #fff;
      }
    }
  }

  @media screen and (max-width: 800px) {
    width: 33%;
  }

  @media screen and (max-width: 650px) {
    width: 50%;
  }

  @media screen and (max-width: 400px) {
    width: 100%;
  }
`

// Module
const CardModule = ({ title, desc, link = {}, portraitImage }) => {
  return (
    <AllVacancyCardWrapper portraitImage={portraitImage}>
      <div className="bg-wrapper">
        <TintedBlockH3> {title} </TintedBlockH3>
        {desc && <P text={desc} />}
        <BuildLinkElement link={link} linkStyle="ReadMoreButton" showArrow />
      </div>
    </AllVacancyCardWrapper>
  )
}
CardModule.defaultProps = {}
export default CardModule
