import React from 'react'
import styled from 'styled-components'
import Detail from '../../../core/modules/expertiseHeadlineBlock'
import HeadlineCard from '../../modules/headlineCard/headlineCard'
import SectionWrapper from '../../modules/sectionBackgroundWrapper'

const CardBlock = styled.div`
  width: 100%;
  display: inline-block !important;
  padding-top: 2rem;
`

// Config for this section
const defaultSectionData = {
  type: 'blank',
  titleType: 'tintedBlockH3',
  notFullWidth: true,
}

const ExpertiseSection = ({
  detail,
  subHeadlines,
  sectionData,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
}) => {
  const newProps = { ...defaultSectionData, ...sectionData }
  return (
    <SectionWrapper
      {...newProps}
      topQuote={topQuote}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
    >
      <Detail {...detail} />
      <CardBlock>
        {subHeadlines &&
          subHeadlines.map &&
          subHeadlines.map((item) => {
            return (
              <HeadlineCard
                siblings={subHeadlines?.length}
                headline={item}
                type="small"
                key={item.title}
              />
            )
          })}
      </CardBlock>
    </SectionWrapper>
  )
}
ExpertiseSection.defaultProps = {}
export default ExpertiseSection
