import React from 'react'
import styled from 'styled-components'
// import { useHistory } from 'react-router-dom'
// placeholder image
// import image from '../../../assets/media/building.png'
import BuildImage from '../../elements/imageBuilder'
import truncateString from '../../utils/truncateString'
import { PodcastsIcons } from '../podcastDetails'
import { RecentProjectsCardH3, P } from '../../elements/typography'
import BuildLinkElement from '../../elements/linkBuilder'
import { BuildLinkWrapper } from '../../elements/linkBuilder'

const VacancyCardWrapper = styled.div`
  width: 280px;
  min-height: 220px;
  display: inline-block !important;
  vertical-align: top;

  .image-wrapper {
    width: 100%;
    height: 220px;
    ${(props) => (props.portraitImage ? 'height: 350px;' : '')}
    overflow: hidden;
    margin-bottom: 12px;
    cursor: pointer;

    img {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  ${(props) =>
    !props.showMoreCardInformation
      ? `
    h3 {
      overflow: hidden;
    }

    p {
      overflow: hidden;
      height: 62px;
      margin-bottom: 5px;
      padding-top: 0;
      padding-bottom: 17px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
  `
      : `
    h3 {
      height: 50px;
      overflow: hidden;
      white-space: initial;
    }

    p {
      overflow: hidden;
      height: 83px;
      margin-bottom: 5px;
      padding-top: 10px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
  `}

  p p {
    padding: 0 !important;
    margin: 0 !important;
  }

  @media screen and (max-width: 800px) {
    width: 50%;
  }

  @media screen and (max-width: 650px) {
    width: 100%;
  }

  @media screen and (max-width: 400px) {
    width: 100%;
  }
`

const VacancyCardSmallWrapper = styled.div`
  width: 250px;
  max-width: 250px;
  display: inline-block !important;
  min-width: 220px;

  .image-wrapper {
    width: 100%;
    height: 180px;
    ${(props) => (props.portraitImage ? 'height: 280px;' : '')}
    overflow: hidden;
    margin-bottom: 12px;
    cursor: pointer;

    img {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: center center;

      ${props => props.imagePosition ? `
        
        object-position: ${props.imagePosition};
      ` : ''}
    }
  }

  ${(props) =>
    !props.showMoreCardInformation
      ? `
    h3 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      overflow: hidden;
      height: 62px;
      margin-bottom: 5px;
      padding-top: 0;
      padding-bottom: 17px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
  `
      : `
    h3 {
      height: 50px;
      overflow: hidden;
      white-space: initial;
    }

    p {
      overflow: hidden;
      height: 91px;
      margin-bottom: 5px;
      padding-top: 0px;
      padding-bottom: 17px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
  `}

  p p {
    padding: 0 !important;
    margin: 0 !important;
  }

  @media screen and (max-width: 800px) {
    width: 50%;
    padding: 2vw 1vw 2vw 1vw;
    max-width: 100%;
  }

  @media screen and (max-width: 650px) {
    width: 100%;
    padding: 12px 0;
  }

  @media screen and (max-width: 400px) {
    width: 100%;
    padding: 12px 0;
  }
`

// Map type to element
const map = {
  small: VacancyCardSmallWrapper,
  large: VacancyCardWrapper,
  big: VacancyCardWrapper,
}

// Module
const CardModule = ({
  image,
  title,
  desc,
  link,
  showMoreCardInformation,
  portraitImage,
  imagePosition,
  podcastLinks
}) => {
  // const history = useHistory()
  let Wrapper = map['large']

  if (!Wrapper) {
    return null
  }

  return (
    <Wrapper
      showMoreCardInformation={showMoreCardInformation}
      className="vacancyCard"
      portraitImage={portraitImage}
      imagePosition={imagePosition}
    >
      <div className="image-wrapper">
        <BuildLinkWrapper link={link}>
          {' '}
          <BuildImage
            image={image}
            width={420}
            imagePosition={imagePosition}
            height={portraitImage ? 350 : 260}
          />{' '}
        </BuildLinkWrapper>
      </div>
      <RecentProjectsCardH3>
        <BuildLinkWrapper link={link}> {title} </BuildLinkWrapper>
      </RecentProjectsCardH3>
      {podcastLinks && <PodcastsIcons showText compact links={podcastLinks}/>}
      {desc && <P text={truncateString(desc, 248)} />}
      <BuildLinkElement link={link} linkStyle="ReadMoreButton" showArrow />
    </Wrapper>
  )
}
CardModule.defaultProps = {}
export default CardModule
