import React from 'react'
import styled from 'styled-components'
import BuildImage from '../../../../elements/imageBuilder'
import truncateString from '../../../../utils/truncateString'

import { SmallArticleHeader, P } from '../../../../elements/typography'
import { BuildLinkWrapper } from '../../../../elements/linkBuilder'

const CardWrapper = styled.div`
  display: inline-block !important;
  vertical-align: top;

  width: 30%;

  a:hover {
    text-decoration: none;
  }

  .image-wrapper {
    width: 100%;
    height: 180px;
    overflow: hidden;
    margin-bottom: 16px;
    cursor: pointer;

    img {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: center center;

      ${props => props.imagePosition ? `
        
        object-position: ${props.imagePosition};
      ` : ''}
    }
  }

  p {
    padding: 22px 0 12px 0;
  }

  .truncate {
    p {
      padding: 0;
    }
    p > span {
      font-size: 16px;
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.25rem;
    }
  }
  .subheader {
    height: 28px;
    overflow: hidden;
    p {
      padding: 0;
    }
    span {
      font-size: 12px;
      line-height: 1.25rem;
    }
  }

  span {
    a {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 680px) {
    width: 100%;
    .image-wrapper {
      width: 100%;
      height: unset;
      img {
        aspect-ratio: 1.5;
      }
    }
  }
`

// const sizeMap = {
//   big: CardWrapper,
//   large: CardWrapper,
//   small: SmallCardWrapper,
// }

// Module
const BigArticleCard = ({ image, title, desc, link, category, date, imagePosition }) => {
  let subheader = `${category}${date ? ` • ${date}` : ''}`.toUpperCase()
  return (
    <CardWrapper imagePosition={imagePosition}>
      <BuildLinkWrapper link={link}>
        <div className="image-wrapper">
          <BuildImage image={image} width={310} height={180} />
        </div>
        <SmallArticleHeader>{title}</SmallArticleHeader>
        <div className="subheader">
          <P text={subheader} />
        </div>
        <span className="truncate">
          <P text={truncateString(desc, 248)} />
        </span>
      </BuildLinkWrapper>
    </CardWrapper>
  )
}

export default BigArticleCard
