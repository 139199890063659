import React, { useEffect, useState, useLayoutEffect, useRef } from 'react'
import styled from 'styled-components'
import Vimeo from '@u-wave/react-vimeo'
import DetectViewport from '../../utils/detectViewport'

const Container = styled.div`
  width: 100%;
`

const VimeoBlock = ({
  video,
  autoplay = false,
  controls = true,
  loop = false,
  muted = false,
}) => {
  const containerRef = useRef()
  const intersecting = DetectViewport(containerRef);

  // Get the containerwidth and set the width of the vimeo player
  const [width, setWidth] = useState()
  const [paused, setPaused] = useState(! autoplay)

  // const [height, setHeight] = useState();
  useLayoutEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.offsetWidth)
      // setWidth(containerRef.current.offsetWidth);
    }
  }, [])

  useEffect(() => {
    if (autoplay) {
      setPaused(! intersecting)
    } else if (! intersecting) {
      // Only apply pause if scrolled away. User will manually have to click play again.
      setPaused(true)
    }
  }, [intersecting]);

  function handlePlayed() {
    setPaused(false);
  }
  function handlePaused() {
    setPaused(true);
  }

  if (!video) {
    return null
  }

  return (
    <Container ref={containerRef}>
      {width && video && video.id && (
        <Vimeo
          video={video.id}
          autoplay={autoplay}
          controls={controls}
          loop={loop}
          muted={muted}
          width={width}
          paused={paused}
          responsive
          onPause={handlePaused}
          onPlay={handlePlayed}
          // background={true}
          // showByline={false}
        />
      )}
    </Container>
  )
}
VimeoBlock.defaultProps = {}
export default VimeoBlock
