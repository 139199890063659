import React from 'react'
import styled from 'styled-components'

// import SwipableComponent from '../../../core/modules/swipeableVacanciesBlock'
import SectionWrapper from '../../../core/modules/sectionBackgroundWrapper'
import SearchItem from '../../modules/search/searchItem'
import SearchResultsOrder from '../../modules/search/searchResultsOrder'
import SearchFilters from '../../modules/search/searchFilters'
import SearchbarV2 from '../../elements/searchbarV2'
import Pagination from '../../modules/pagination'
import { ReactComponent as ArrowRight } from '../../../assets/svg/arrow_right.svg'
import filterIcon from './filter.png'
import { fetchApi } from '../../utils/api/index'
import { mocksearch } from '../../../stories/sections/header/module.stories'
import Loader from '../../elements/loader'

const Wrapper = styled.div`
  padding: 0 0 22pt;
  .filters {
    display: inline-block;
    vertical-align: top;
    width: 280px;
  }
  .resultList {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 300px);
    padding-left: 2rem;
    position: relative;
    .searchContainer {
      position: absolute;
      top: -200px;
      width: 100%;

      #searchbar {
        background: none !important;
      }
    }
  }

  .searchresults {
    position: relative;

    @media screen and (max-width: 1000px) {
      padding-top: 60px;
    }
  }

  .loadingIndicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.7);

    .loaderIcon {
      position: absolute;
      top: 20%;
      left: 50%;
      margin: -45px 0 0 -45px;
    }
  }

  .paginationWrapper {
    width: 100%;
    padding-top: 5rem;
    text-align: center;
    margin: 0 auto;
    .pageOf {
      color: #0d5081;
      font-size: 0.75rem;
    }
    .pagination {
      display: flex;
      margin-top: 0.75rem;
      div {
        margin: 0 auto;
      }
    }
  }
  .filterButton,
  .filterModal {
    display: none;
  }

  .filterButton {
    img {
      width: 40px;
      height: 40px;
    }
  }
  @media screen and (max-width: 980px) {
    .filters {
      display: none;
      width: 100%;
    }
    .resultList {
      width: 100%;
      padding: 0;
    }
    .searchContainer {
      top: -140px;
    }
    .filterButton {
      display: inline-block;
      vertical-align: top;
      width: 20pt;
      height: 20pt;
    }
    .filterModal {
      display: block;
      width: 100%;
      height: 100vh;
      position: fixed;
      left: 0;
      top: 0;
      background-color: #fff;
      overflow-y: scroll;
      padding: 2rem;
      .return {
        width: 100%;
        padding-bottom: 2rem;
        .arrow {
          display: inline-block;
          vertical-align: middle;
          svg {
            transform: rotate(180deg);
          }
        }
        .text {
          display: inline-block;
          vertical-align: middle;
          font-size: 14pt;
        }
      }
    }
  }
`

export const buildDefaultFilterState = (search) => {
  const defaultState = {
    category: 'Alles',
  }

  // Find the main category
  if (search?.results) {
    for (const [key] of Object.entries(search.results)) {
      if (search.results[key].selected) {
        defaultState.category = key
      }
    }
  }

  // Get the other selected filters
  if (search.filters && search.filters.length) {
    search.filters.forEach((filter) => {
      for (const [key, value] of Object.entries(filter)) {
        let filterType = null

        if (value && value.length) {
          value.forEach((option) => {
            filterType = option.key
            if (option.values) {
              const selected = option.values.filter((item) => item.selected)[0]

              if (selected) {
                defaultState[filterType] = selected.value
              }
            }
          })
        }
      }
    })
  }

  return defaultState
}

// Config for this section
const defaultSectionData = {
  type: 'blank',
  titleType: 'subHeader',
  notFullWidth: true,
}

let searchdebounce = null

// Render the section
const Search = ({
  search,
  sectionData,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
}) => {
  const passedSearch =
    search === '' ||
    !search ||
    (typeof search === 'object' && Object.keys(search).length === 0)
      ? mocksearch
      : search
  const { filters, sorting } = search
  const [page, setPage] = React.useState(1)
  const [searched, setSearched] = React.useState('')
  const [searchTerm, setSearchTerm] = React.useState(search.searchTerm || '')
  const [searchResults, setSearchResults] = React.useState()
  const [selectedFilters, setSelectedFilters] = React.useState(
    buildDefaultFilterState(search)
  )
  const [order, setOrder] = React.useState(sorting[0])
  const [showModal, setShowModal] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [searchTermMinLength] = React.useState(
    search.minSearchLength &&
      Number.isInteger(Number.parseInt(search.minSearchLength))
      ? Number.parseInt(search.minSearchLength)
      : 3
  )

  React.useEffect(() => {
    handleSearch()
  }, [page, selectedFilters, order])

  React.useEffect(() => {
    clearTimeout(searchdebounce)

    // TODO: Should we respect SAYT here also?
    if (searchTerm.length >= searchTermMinLength) {
      searchdebounce = setTimeout(() => {
        handleSearch()
      }, 500)
    }
  }, [searchTerm])

  const newProps = { ...defaultSectionData, ...sectionData }

  const handleSearch = () => {
    setLoading(true)
    setSearched(searchTerm)
    let filterString = ''
    Object.keys(selectedFilters).forEach((filter) => {
      // Category works different. The backend expects a different value than what we use in the frontend. Therefore
      // we set the value like shown, which returns the wanted value
      const value =
        filter === 'category'
          ? search.results[selectedFilters[filter]].key
          : selectedFilters[filter]

      if (value) {
        filterString = filterString + filter + '=' + value + '&'
      }
    })
    let searchUrl = `${passedSearch.api}?${
      searchTerm && `search=${searchTerm}&`
    }${filterString}sort=${order.value}&page=${page}`

    fetchApi(searchUrl)
      .then((r) => {
        setSearchResults(r.search)
        setLoading(false)
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
      })
  }

  const handlePageChange = (e, value) => {
    setPage(value)
  }

  let data =
    searchResults?.results[selectedFilters['category']] ??
    passedSearch?.results[selectedFilters['category']]

  // const data = !loading
  //     ? searchResults?.results[selectedFilters['category']] || []
  //     : passedSearch?.results[selectedFilters['category']] || []

  return (
    <SectionWrapper
      {...newProps}
      hideOverflow
      topQuote={topQuote}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
      paddingSize="l"
    >
      <Wrapper className="cardContainer">
        <div className="filters">
          <SearchFilters
            search={search}
            filters={filters}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        </div>
        <div className="resultList">
          <div className="searchContainer">
            <SearchbarV2
              texts={passedSearch.texts}
              search={searchTerm}
              setSearch={setSearchTerm}
              handleSearch={handleSearch}
              setSelectedFilters={setSelectedFilters}
            />
          </div>
          <SearchResultsOrder
            data={data}
            sorting={sorting}
            order={order}
            setOrder={setOrder}
            searched={searched}
          />
          <div
            className="filterButton"
            onClick={() => {
              setShowModal(true)
            }}
          >
            <img className="filterIcon" src={filterIcon} alt="filter" />
          </div>
          <div className="searchresults">
            {data?.items?.length > 0 &&
              data.items.map((item, i) => {
                return (
                  <SearchItem
                    key={`search-result-${i}-${item.title}`}
                    {...item}
                  />
                )
              })}

            {data?.items?.length === 0 && (
              <div>
                {passedSearch.texts.notFound || 'Geen resultaten gevonden'}
              </div>
            )}

            {loading && (
              <div className="loadingIndicator">
                <div className="loaderIcon">
                  <Loader />
                </div>
              </div>
            )}
          </div>
        </div>
        {data?.items?.length > 0 && (
          <div className="paginationWrapper">
            <span className="pageOf">
              Pagina {page} van {data?.totalPages}
            </span>
            <div className="pagination">
              <Pagination
                count={data?.totalPages}
                page={page}
                handleChange={handlePageChange}
              />
            </div>
          </div>
        )}

        {showModal && (
          <div className="filterModal">
            <div className="return">
              <div className="arrow" onClick={() => setShowModal(false)}>
                <ArrowRight />
              </div>
              <span className="text">Terug naar de resultaten pagina</span>
            </div>

            <SearchFilters
              search={search}
              filters={filters}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
          </div>
        )}
      </Wrapper>
    </SectionWrapper>
  )
}
Search.defaultProps = {
  sectionData: {},
}
export default Search
