import React from "react"

import {RecentProjectsCardH3} from "../../../elements/typography"
import SelectBox from '../../../elements/selectBox'

const Select = ({ title, subTitle, filters, updateLocalState, currentState }) => {
  return <>
    {title && title?.length > 0 && (
      <RecentProjectsCardH3> {title} </RecentProjectsCardH3>
    )}
    {subTitle && subTitle?.length > 0 && (
      <p> {subTitle} </p>
    )}

    {filters &&
      filters.map((filter) => {
        const classNameToUse =
          filter.layoutType === 'full' ? 'fullControl' : 'halfControl'
        return (
          <div
            key={`filters-item-${filter.labelId}`}
            className={classNameToUse}
          >
            <SelectBox
              {...filter}
              value={currentState[filter.labelId] || 'none'}
              handleChange={(e) => updateLocalState(filter, e)}
              styleVariant="bordered"
            />
          </div>
        )
      })}
  </>
}

export default Select
