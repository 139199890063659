import React from 'react'
import styled from 'styled-components'
// import { useHistory } from 'react-router-dom'

// Core
import { VerticalWhiteTitle } from '../../elements/typography'
import { ReactComponent as Arrow } from '../../../assets/svg/ic_button_arrow.svg'
import { BuildLinkWrapper } from '../../elements/linkBuilder'

// Card Wrap
const Card = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: inline-block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  .overlay {
    background-color: rgba(0, 84, 150, 0.8);
    width: 100%;
    height: 100%;
    position: relative;
    .centeredItems {
      width: fit-content;
      line-height: 2.5rem;
      text-align: center;
      padding-bottom: 5rem;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      height: calc(100% - 5rem);
      h3 {
        padding-top: 3rem;
        height: inherit;
        text-align: left;
      }
      .hiddenObj {
        height: 4rem;
      }
      .pijlerLink {
        height: 1rem;
        svg {
          overflow: visible;
          g {
            circle,
            path,
            line {
              stroke: #fff;
            }
          }
        }
      }
    }
  }
`

// Card variants
const Wrapper = styled.div`
  display: inline-block !important;
  width: 25%;
  height: 750px;
  overflow: hidden;
  padding-right: 2rem;

  @media screen and (max-width: 800px) {
    width: 50%;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    padding: 0;
  }
`

// Element
const PijlerElement = ({ pijler = {} }) => {
  // const history = useHistory()
  let { title, href, image } = pijler
  // const handleClick = useCallback(() => {
  //   history.push(href)
  // }, [history, href])
  const imageBg = `${process.env.REACT_APP_URL || ''}/resize/500/0?image=${
    image?.src
  }`

  const style = { backgroundImage: `url(${imageBg})` }

  if (image?.position) {
    style.backgroundPosition = image?.position
  }

  return (
    <Wrapper>
      <BuildLinkWrapper link={{ href }}>
        <Card className="mediaElement" style={style}>
          <div className="overlay">
            <div className="centeredItems">
              <VerticalWhiteTitle>{title}</VerticalWhiteTitle>

              <div className="pijlerLink">
                <Arrow />
              </div>
            </div>
          </div>
        </Card>
      </BuildLinkWrapper>
    </Wrapper>
  )
}

PijlerElement.defaultProps = {}

export default PijlerElement
