import React from 'react'

import SectionWrapper from '../../../core/modules/sectionBackgroundWrapper'
import VimeoBlock from '../../../core/modules/vimeoBlock'

// Config for this section
const defaultSectionData = {
  type: 'blank',
}

// Render the section
const RecentProjects = ({
  video,
  sectionData,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  isFullScreen,
  autoplay = false,
  controls = true,
  loop = false,
  muted = false,
}) => {
  const newProps = { ...defaultSectionData, ...sectionData }

  if (isFullScreen) {
    return (
      <VimeoBlock
        video={video}
        autoplay={autoplay}
        controls={controls}
        loop={loop}
        muted={muted}
      />
    )
  }

  return (
    <SectionWrapper
      {...newProps}
      topQuote={topQuote}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
      paddingSize="l"
    >
      <VimeoBlock video={video} />
    </SectionWrapper>
  )
}
RecentProjects.defaultProps = {}
export default RecentProjects
