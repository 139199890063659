import React from 'react'

import PijlerHeadline from '../../modules/pijlerHeadline'
// import media from '../../../assets/media/person.png'

const PijlerHeadlineSection = (props) => {
  return <PijlerHeadline {...props} />
}

PijlerHeadlineSection.defaultProps = {}
export default PijlerHeadlineSection
