import React from 'react'
import styled from 'styled-components'

// import SwipableComponent from '../../../core/modules/swipeableVacanciesBlock'
import SectionWrapper from '../../../core/modules/sectionBackgroundWrapper'
import TestimonialCard from '../../modules/imageDescCard'

const TestimonialWrapper = styled.div`
  width: 100%;

  .card-container {
    width: 25%;
    display: inline-block;
    vertical-align: top;

    padding: 0 0.5rem;

    &:first-child {
      padding: 0 0.5rem 0 0;
    }
    &:last-child {
      padding: 0 0 0 0.5rem;
    }
  }

  @media screen and (max-width: 1000px) {
    .card-container {
      width: 33%;
    }
  }

  @media screen and (max-width: 840px) {
    .card-container {
      width: 50%;
    }
  }

  @media screen and (max-width: 540px) {
    .card-container {
      width: 100%;
      padding: 12px 0 !important;
    }
  }
`

// Config for this section
const defaultSectionData = {
  type: 'blank',
  notFullWidth: true,
}

// Render the section
const Testimonials = ({
  testimonials,
  sectionData,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  showMoreCardInformation,
}) => {
  const newProps = { ...defaultSectionData, ...sectionData }
  return (
    <SectionWrapper
      {...newProps}
      topQuote={topQuote}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
      paddingSize="s"
    >
      <TestimonialWrapper>
        {testimonials &&
          testimonials.map &&
          testimonials.map((testimonial, i) => {
            return (
              <div key={`testimonial-${i}`} className="card-container">
                <TestimonialCard
                  showMoreCardInformation={showMoreCardInformation}
                  {...testimonial}
                />
              </div>
            )
          })}
      </TestimonialWrapper>
    </SectionWrapper>
  )
}
Testimonials.defaultProps = {}
export default Testimonials
