import React from 'react'
import styled from 'styled-components'

// Core
import { TintedBlockH3, P } from '../../elements/typography'
import BuildLinkElement from '../../elements/linkBuilder'

import { PageWrapper } from '../../elements/pageContainer'

// Module styling
const BigMediaTextBlock = styled.div`
  background: #005496;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  
  @media screen and (min-width: 1000px) {
    background-attachment: fixed;
  }

  .gradientBackground1 {
    background: rgba(0, 121, 161, 0.8);
    background: linear-gradient(
      61deg,
      rgba(0, 84, 150, 0.9) 0%,
      rgba(0, 84, 150, 0.79) 35%,
      rgba(9, 86, 150, 0) 50%
    );

    .contentBlock {
      background: linear-gradient(
        126deg,
        rgba(1, 90, 120, 1) 0%,
        rgba(6, 62, 108, 0) 30%
      );

      .contentPadding {
        padding: 30vh 0;
      }

      div.content {
        max-width: 750px;

        a,
        p,
        h3 {
          color: #fff;
        }
        h3 {
          font-size: 2.5rem;
        }

        span {
          a {
            color: #fff;
            font-size: 1rem;

          }
          svg g path,
          svg g line {
            stroke: #fff;
          }
  
          :hover {
            a {
              color: #00a1d5;
            }
            svg g path,
            svg g line {
              stroke: #00a1d5;
            }
          }
        }

        }

        p {
          padding: 60px 0;
        }
      }
    }
  }

  @media screen and (max-width: 2050px) {
    .gradientBackground1 {
      .contentPadding {
        width: 100%;
        padding-left: 8vw !important;
        padding-right: 8vw !important;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .gradientBackground1 {
      .contentBlock {
        .contentPadding {
          padding-top: 220px !important;
          padding-bottom: 220px !important;
        }
      }
    }
  }

  @media screen and (max-height: 600px) {
    .gradientBackground1 {
      .contentBlock {
        .contentPadding {
          padding-top: 220px !important;
          padding-bottom: 220px !important;
        }
      }
    }
  }
`

const BigMediaTextModule = ({ title, text, link, image }) => {
  const imageBg = `${
    process.env.REACT_APP_URL || ''
  }/resize/1500/0?image=${image?.src}`

  const style = { backgroundImage: `url(${imageBg})` }

  if (image?.position) {
    style.backgroundPosition = image?.position
  }

  return (
    <BigMediaTextBlock style={style} className="mediaElement">
      <div className="gradientBackground1">
        <div className="contentBlock">
          <PageWrapper className="contentPadding">
            <div className="content">
              <TintedBlockH3> {title} </TintedBlockH3>
              <P text={text} />

              <BuildLinkElement
                link={link}
                linkStyle="ReadMoreButton"
                showArrow
              />
            </div>
          </PageWrapper>
        </div>
      </div>
    </BigMediaTextBlock>
  )
}
BigMediaTextModule.defaultProps = {}
export default BigMediaTextModule
