import React from 'react'
import styled from 'styled-components'

// Core
import { PageWrapper } from '../../elements/pageContainer'
import QuoteElement from '../../elements/quote'
import PaddingWrapper from '../../elements/paddingContainer'
import Breadcrumbs from '../../modules/breadcrumbs'

// import img from '../../../assets/media/building'

// Styling
const NineStepsWrapper = styled.div`
  .dreamhousewrapper {
    position: relative;
    min-height: 1200px;

    .innerContainer {
      position: relative;
    }

    .step {
      font-weight: bold;
      font-size: 2.4rem;
      position: absolute;
      top: 0;
      left: -42px;
      color: #005496;

      @media screen and (max-width: 1000px) {
        left: 22px;
        top: 22px;
        z-index: 99999;
      }
    }

    .block {
      width: 180px;
      height: 180px;
      border: 2px solid rgba(0, 0, 0, 0.3);
    }

    .cardItem {
      position: absolute;
      top: 0;
      left: 42px;
      background: #fff;
      z-index: 9999;

      @media screen and (max-width: 1000px) {
        position: relative !important;
        top: initial !important;
        left: initial !important;
        bottom: initial !important;
        right: initial !important;
        margin: 22px !important;

        width: calc(33% - 44px);
        display: inline-block;
        vertical-align: top;
      }

      @media screen and (max-width: 680px) {
        width: calc(50% - 44px);
        display: inline-block;
        vertical-align: top;
      }

      @media screen and (max-width: 480px) {
        width: calc(100% - 44px);
        display: inline-block;
        vertical-align: top;
      }

      &.card-1 {
        top: 0;
        left: 42px;
      }

      &.card-2 {
        top: 80px;
        left: 50%;
        margin-left: -120px;
      }

      &.card-3 {
        top: 50px;
        left: initial;
        right: 100px;
      }

      &.card-4 {
        top: 390px;
        left: initial;
        right: 0;
      }

      &.card-5 {
        top: 490px;
        left: 59%;
        margin-left: -110px;
      }

      &.card-6 {
        top: 390px;
        left: 100px;
      }

      &.card-7 {
        top: 790px;
        left: 42px;
      }

      &.card-8 {
        top: 920px;
        left: 50%;
        margin-left: -120px;
      }

      &.card-9 {
        top: 820px;
        left: initial;
        right: 0;
      }
    }

    .line {
      z-index: 999;
      position: absolute;
      left: 0;
      top: 0;
      height: 2px;
      background: #000;
      width: 48%;

      @media screen and (max-width: 1000px) {
        display: none !important;
        width: 0px !important;
        height: 0px !important;
      }

      &.line-1 {
        left: 47px;
        top: 131px;
        transform: rotate(10deg);
      }

      &.line-2 {
        left: 44%;
        top: 167px;
        width: 40%;
        transform: rotate(-10deg);
      }

      &.line-3 {
        left: initial;
        top: 267px;
        width: 300px;
        right: 17px;
        transform: rotate(67deg);
      }

      &.line-4 {
        left: initial;
        right: 0;
        top: 540px;
        transform: rotate(-10deg);
      }

      &.line-5 {
        left: 160px;
        top: 540px;
        width: 44%;
        transform: rotate(10deg);
      }

      &.line-6 {
        left: -46px;
        top: 740px;
        width: 371px;
        transform: rotate(105deg);
      }

      &.line-7 {
        left: 45px;
        top: 940px;
        transform: rotate(20deg);
      }

      &.line-8 {
        left: 52%;
        top: 980px;
        width: 40%;
        transform: rotate(349deg);
      }
    }
  }
`

//  module
const NineStepsModule = ({
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  data,
  hasBreadcrumbs,
  breadcrumbs,
  // hasBreadcrumbs = true,
  // breadcrumbs = [
  //   {
  //     linkText: 'vorm',
  //     href: '#',
  //   },
  //   {
  //     linkText: 'over vorm',
  //     href: '#',
  //   },
  //   {
  //     linkText: 'example page',
  //     href: '#',
  //   },
  // ]
}) => {
  const [items] = React.useState(data)

  return (
    <NineStepsWrapper>
      <PaddingWrapper size="l" hasBreadcrumbs={hasBreadcrumbs}>
        <PageWrapper className="contentArea">
          {breadcrumbs && hasBreadcrumbs && <Breadcrumbs data={breadcrumbs} />}
          {topQuote && topQuote?.length > 0 && (
            <QuoteElement quote={topQuote} top author={topQuoteAuthor} />
          )}

          <div className="dreamhousewrapper">
            {items &&
              items.map((item, index) => {
                return (
                  <>
                    {index !== 0 && (
                      <div className={`line line-${index}`}></div>
                    )}
                    <div
                      className={`cardItem card-${index + 1}`}
                      key={`nine-step-${index}`}
                    >
                      <div className="innerContainer">
                        <div className="step">{index + 1}</div>
                        <div className="block" id={item.id}></div>
                      </div>
                    </div>
                  </>
                )
              })}
          </div>

          {bottomQuote && bottomQuote?.length > 0 && (
            <QuoteElement
              quote={bottomQuote}
              bottom
              author={bottomQuoteAuthor}
            />
          )}
        </PageWrapper>
      </PaddingWrapper>
    </NineStepsWrapper>
  )
}

// PLaceholder props
NineStepsModule.defaultProps = {}
export default NineStepsModule
