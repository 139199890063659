import React from 'react'

import SwipableComponent from '../../modules/swipeableLogos'
import SectionWrapper from '../../modules/sectionBackgroundWrapper'
// import PaddingWrapper from '../../elements/paddingContainer'

// Config for this section
const defaultSectionData = {
  type: 'blank',
  padding: 'small',
}

// Render the section
const Vacancies = ({
  data,
  sectionData,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  hasBreadcrumbs,
  breadcrumbs,
}) => {
  const newProps = { ...defaultSectionData, ...sectionData }
  return (
    <SectionWrapper
      {...newProps}
      breadcrumbs={breadcrumbs}
      hasBreadcrumbs={hasBreadcrumbs}
      topQuote={topQuote}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
      notFullWidth
    >
      <SwipableComponent doNotRenderToggles data={data} />
    </SectionWrapper>
  )
}
Vacancies.defaultProps = {
  sectionData: {
    title: 'In samenwerking met',
    titleType: 'tintedBlockH3',
    data: [],
  },
}
export default Vacancies
