import React from 'react'
import styled from 'styled-components'

// Core
// import img from '../../../assets/media/building.png'
import Loader from '../../elements/loader'
import { PageWrapper } from '../../elements/pageContainer'
import PropertyCard from './property.card'
import Pagination from '../pagination'
import QuoteElement from '../../elements/quote'
import PaddingWrapper from '../../elements/paddingContainer'
import Breadcrumbs from '../../modules/breadcrumbs'
import FilterSidebar from "../filters/filters"

// API Driven logic
import { fetchApi } from '../../utils/api/index'

const buildApiUrl = (state, api, page, intitial) => {
  let newApi = `${api}?&page=${page}${intitial ? '&initial=true' : ''}`
  Object.entries(state).forEach((entry) => {
    const [key, value] = entry
    if (key && value && value !== 'none' && value !== null) {
      newApi = `${newApi}&${key}=${value}`
    }
  })

  return newApi
}

// Styling
const PropertyWrapper = styled.div`
  background: #f7fbfc;

  .noresults {
    padding: 80px 0;
    text-align: center;
  }

  h3 {
    height: 30px;
    overflow: hidden;
    margin-bottom: 14px;
  }

  .rightContent {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 350px);
    padding-left: 40px;
  }

  .cardContainer {
    width: 25%;
    padding: 14px 14px 4px;
    display: inline-block;
    vertical-align: top;
    padding-bottom: 40px;
    transition: all 0.5s ease;

    &:hover {
      background: #fff;
      box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.1);

      @media screen and (max-width: 650px) {
        background: none !important;
        box-shadow: none !important;
      }
    }

    p {
      font-size: 0.9rem;
      margin: 0;
      height: 40px;

      p {
        padding: 0 !important;
      }
    }

    span {
      padding-top: 12px;
    }
  }

  @media screen and (max-width: 1800px) {
  }

  @media screen and (max-width: 1600px) {
    .cardContainer {
      width: 33.3%;
    }
  }

  @media screen and (max-width: 1300px) {
    .cardContainer {
      width: 50%;
    }

    .rightContent {
      padding-left: 0px !important;
      width: calc(100% - 300px) !important;
    }
  }

  @media screen and (max-width: 850px) {
    .cardContainer {
      width: 100%;
    }
  }

  @media screen and (max-width: 650px) {
    .cardContainer,
    .rightContent {
      width: 100% !important;
      padding: 22px 0px !important;
    }
  }

  .paginationWrapper {
    padding-top: 50px;
  }

  .cardContainer {
    .podcasts {
      span {
        display: inline !important;
      }
    }
  }
`

//  module
const PropertiesModule = ({
  filterProps,
  title,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  api,
  data,
  totalPages,
  hasBreadcrumbs,
  breadcrumbs,
  // hasBreadcrumbs = true,
  // breadcrumbs = [
  //   {
  //     linkText: 'vorm',
  //     href: '#',
  //   },
  //   {
  //     linkText: 'over vorm',
  //     href: '#',
  //   },
  //   {
  //     linkText: 'example page',
  //     href: '#',
  //   },
  // ]
}) => {
  const [localState, setLocalState] = React.useState({})
  const [loading, isLoading] = React.useState(true)
  const [initialLoading, isInitialLoading] = React.useState(true)
  const [items, setItems] = React.useState(data)
  const [page, setPage] = React.useState(1)
  const [oldPage, setOldPage] = React.useState(1)
  const [newData, setData] = React.useState(data)
  const [source, setDataSource] = React.useState('original')
  const renderData = source === 'search' ? newData : items
  const [newTotalPages, setNewTotalPages] = React.useState(totalPages)

  const [filters, setFilters] = React.useState(filterProps)
  const [originalPagination, setOriginal] = React.useState(totalPages)
  const paginatedSource =
    source === 'search' ? newTotalPages : originalPagination

  const updateState = (filter, event) => {
    const value = event.target.value
    const id = filter.labelId

    setPage(1)
    setLocalState({
      ...localState,
      [id]: value,
    })
  }

  React.useEffect(() => {
    if (!data || data.length === 0 || !filterProps) {
      const url = buildApiUrl(localState, api, page, true)
      fetchApi(url).then((data) => {
        setData(data.data)
        setItems(data.data)
        setFilters(data.filterProps)
        setNewTotalPages(data.totalPages)
        setOriginal(data.totalPages)
        setDataSource('original')
        isLoading(false)
        isInitialLoading(false)
      })
    } else {
      isLoading(false)
      isInitialLoading(false)
    }
  }, [])

  React.useEffect(() => {
    const url = buildApiUrl(localState, api, page)
    isLoading(true)

    if (Object.keys(localState).length === 0 && page === oldPage) {
      setDataSource('original')
      isLoading(false)
    } else {
      setOldPage(page)
      fetchApi(url).then((data) => {
        setData(data.data)
        setNewTotalPages(data.totalPages)
        setDataSource('search')
        isLoading(false)
      })
    }
  }, [localState, page])

  return (
    <PropertyWrapper>
      <PaddingWrapper size="l" hasBreadcrumbs={hasBreadcrumbs}>
        <PageWrapper className="contentArea">
          {breadcrumbs && hasBreadcrumbs && <Breadcrumbs data={breadcrumbs} />}

          {topQuote && topQuote?.length > 0 && (
            <QuoteElement quote={topQuote} top author={topQuoteAuthor} />
          )}

          <FilterSidebar
            title={title}
            filters={filters}
            updateLocalState={updateState}
            currentState={localState}
            side={'left'}
          />
          <div className="rightContent">
            {(initialLoading || loading) && <Loader />}
            {!initialLoading && !loading && (
              <div>
                {renderData &&
                  renderData.length > 0 &&
                  renderData.map((item, i) => (
                    <div key={`properties-item-${i}`} className="cardContainer">
                      <PropertyCard {...item} />
                    </div>
                  ))}

                {renderData && renderData.length === 0 && (
                  <div className="noresults">
                    Er zijn geen items gevonden met dit criterium
                  </div>
                )}
                {renderData &&
                  renderData.length > 0 &&
                  paginatedSource &&
                  paginatedSource > 1 && (
                    <div className="paginationWrapper">
                      <Pagination
                        count={paginatedSource}
                        handleChange={(e, value) => {
                          setPage(value)
                        }}
                        page={page}
                      />
                    </div>
                  )}
              </div>
            )}
          </div>

          {bottomQuote && bottomQuote?.length > 0 && (
            <QuoteElement
              quote={bottomQuote}
              bottom
              author={bottomQuoteAuthor}
            />
          )}
        </PageWrapper>
      </PaddingWrapper>
    </PropertyWrapper>
  )
}

// PLaceholder props
PropertiesModule.defaultProps = {}
export default PropertiesModule
