import React, { useEffect } from 'react'
import styled from 'styled-components'

import { PageWrapper } from '../../elements/pageContainer'

// Core
import { P, TintedBlockH3 } from '../../elements/typography'
import useResize from '../../utils/useResize'

// Placeholder image
// import imageElement from '../../../assets/media/person.png'

// Module styling
const ExpertiseHeadlineBlockWrapper = styled.div`
  .textContentWrapper {
    width: calc(100% - 535px);
    display: inline-block !important;
    padding: 80px 80px 80px 0px;
    vertical-align: middle;
    .textWrapper {
      .titleWrapper {
        line-height: 4.2rem;
        min-height: 4.2rem;
        h3 {
          font-size: 2.5rem;
        }
      }
    }
  }

  .linkWrapper {
    display: block;
    vertical-align: top;
    padding-top: 40px;
  }

  @media screen and (max-width: 1280px) {
    .textContentWrapper {
      width: calc(100% - 335px);
      padding: 80px 0px 140px 60px;
    }
  }

  @media screen and (max-width: 1000px) {
    .textContentWrapper {
      width: 100%;
      padding: 80px 20px;
    }
  }

  .marginMobile {
    @media screen and (max-width: 900px) {
      margin: 0 -6vw !important;
    }
  }
`

const ImageBlock = styled.div`
  display: inline-block !important;
  width: calc(535px + ${(props) => props.imageMarginOffset});
  vertical-align: middle;
  max-height: 480px;
  object-fit: cover;
  object-position: center center;
  margin-right: -${(props) => props.imageMarginOffset};
  float: right;
  overflow: hidden;

  img {
    object-fit: cover;
    object-position: center center;
    width: 100%;
  }

  @media screen and (max-width: 1280px) {
    width: calc(323px + ${(props) => props.imageMarginOffset});
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
    margin: 0;
  }
`

const ImageBlockSmall = styled.div`
  display: inline-block !important;
  width: calc(400px + ${(props) => props.imageMarginOffset});
  vertical-align: middle;
  max-height: 400px;
  object-fit: cover;
  object-position: center center;
  margin-right: -${(props) => props.imageMarginOffset};
  float: right;
  overflow: hidden;

  img {
    object-fit: cover;
    object-position: center center;
    width: 100%;
  }

  @media screen and (max-width: 1280px) {
    width: calc(323px + ${(props) => props.imageMarginOffset});
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
    margin: 0;
  }
`

const map = {
  small: ImageBlockSmall,
  large: ImageBlock,
}

const ExpertiseHeadlineBlock = ({
  title,
  image,
  desc,
  imageType = 'large',
  isFullScreen,
}) => {
  const Image = map[imageType]

  // Fullscreen variables
  const [margin, setMargin] = React.useState('0px')
  const containerRef = React.useRef()
  const { width } = useResize()

  useEffect(() => {
    if (!isFullScreen) {
      setMargin('0px')
      return
    }

    if (window.innerWidth <= 1000) {
      setMargin('8vw')
      return
    }

    if (window.innerWidth <= 1280) {
      setMargin('60px')
      return
    }

    if (window.innerWidth <= 2050) {
      setMargin('120px')
      return
    }

    const { left } = containerRef.current.getBoundingClientRect()
    setMargin(`${left}px`)
  }, [width, containerRef, isFullScreen])

  return (
    <ExpertiseHeadlineBlockWrapper>
      <PageWrapper eleRef={containerRef}>
        <div style={{ marginRight: `-${margin}` }} className="marginMobile">
          <div className="textContentWrapper">
            <div className="textWrapper">
              <div className="titleWrapper">
                <TintedBlockH3> {title} </TintedBlockH3>
              </div>
              <P text={desc} />
            </div>
          </div>

          {image?.src && (
            <Image
              style={image?.position ? { objectPosition: image?.position } : {}}
              className="mediaElement"
              imageMarginOffset={margin}
            >
              <img
                src={`${
                  process.env.REACT_APP_URL || ''
                }/resize/1000/0?image=${image?.src}`}
                alt=""
                style={image?.position ? { objectPosition: image?.position } : {}}
              />
            </Image>
          )}
        </div>
      </PageWrapper>
    </ExpertiseHeadlineBlockWrapper>
  )
}
ExpertiseHeadlineBlockWrapper.defaultProps = {}
export default ExpertiseHeadlineBlock
