import React, { useRef } from 'react'
import styled from 'styled-components'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'

import { BuildLinkWrapper } from '../../elements/linkBuilder'

import { ReactComponent as LeftAngle } from '../../../assets/svg/angle-left-solid.svg'
import { ReactComponent as RightAngle } from '../../../assets/svg/angle-right-solid.svg'

// Intenral styling
const SwipableContainer = styled.div`
  white-space: nowrap;
  padding-top: 40px;
  width: 100vw;

  .swiper-slide {
    font-size: 18px;

    display: inline-block;
    vertical-align: top;
    white-space: normal;
    width: unset;
    height: 5rem;
  }

  .mySwiper {
    position: relative;
    padding-left: 0px;

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    .prev-slide,
    .next-slide {
      height: 100%;
    }
    .swiper-button-disabled {
      display: none;
    }

    .left {
      position: absolute;
      left: 0;
      width: 40px;
      top: 0;
      height: 100%;
      z-index: 999;
      svg {
        right: 0;
        width: 20px;
        height: 100%;
      }
    }
    .right {
      position: absolute;
      right: 0;
      width: 20%;
      top: 0;
      height: 100%;
      text-align: center;
      background: linear-gradient(
        270deg,
        rgba(0, 84, 150, 1) 0%,
        rgba(0, 84, 150, 1) 10%,
        rgba(0, 84, 150, 0.5942752100840336) 50%,
        rgba(0, 84, 150, 0) 100%
      );
      z-index: 999;

      svg {
        width: 20px;
        height: 100%;
      }
    }
  }
`

const Logo = styled.img`
  height: 5rem;
`

SwiperCore.use([Navigation])

// Module
const SwipeableView = ({ data, doNotRenderToggles }) => {
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  if (!data || data?.length === 0) {
    return null
  }

  return (
    <SwipableContainer>
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={20}
        className="mySwiper"
        navigation={{
          prevEl: prevRef.current ? prevRef.current : undefined,
          nextEl: nextRef.current ? nextRef.current : undefined,
        }}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current
          swiper.params.navigation.nextEl = nextRef.current
          swiper.navigation.update()
        }}
      >
        {!doNotRenderToggles && (
          <>
            <div className="left">
              <div className="prev-slide" ref={prevRef}>
                <LeftAngle />
              </div>
            </div>
            <div className="right">
              <div className="next-slide" ref={nextRef}>
                <RightAngle />
              </div>
            </div>
          </>
        )}

        {data &&
          data.map &&
          data.map((card, index) => (
            <SwiperSlide
              key={`swipable-container-logo-${index}`}
              className="slideContainer"
            >
              <BuildLinkWrapper link={card.link}>
                <Logo {...card.image} />
              </BuildLinkWrapper>
            </SwiperSlide>
          ))}
      </Swiper>
    </SwipableContainer>
  )
}
SwipeableView.defaultProps = {
  data: [],
}
export default SwipeableView
