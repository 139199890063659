import React from 'react'
import styled from 'styled-components'

const TEXTLIMIT = 1900

const quoteStandard = `
  color: #005496 ;
  a {
    color: #005496 ;

    &:hover {
      color: #00a1d5 ;
    }
  }
  font-size: 2.6rem;
  font-weight: 400;
  margin: 0;
  display: block;


  @media screen and (max-width: 750px) {
    font-size: 1.6rem;
  }
`

// Regular Elements
const Paragraph = styled.p`
  color: #000;
  font-size: 1rem;
  padding: 22px 0;
  margin: 0;
  font-weight: 200;
  line-height: 1.6rem;

  @media screen and (max-width: 600px) {
    font-size: 18px;
    line-height: 28px;
    padding: 1px 0;
  }

  a {
    color: #005496;
  }

  .quote {
    ${quoteStandard}
    margin: 12px 0 12px 40px;
  }
`
export const P = ({ text, dontCrop, classes }) => {
  const [useCropped, setCropped] = React.useState(true)
  const initialText = text && text.toString ? text.toString() : text
  const croppedTest =
    initialText && initialText.substring
      ? `${initialText.substring(0, TEXTLIMIT)}...`
      : ''

  const tooLong = !dontCrop && initialText && initialText.length > TEXTLIMIT
  return (
    <Paragraph className={classes}>
      <span
        className="allowListStyling"
        style={{ display: 'block' }}
        dangerouslySetInnerHTML={{
          __html: tooLong && useCropped ? croppedTest : initialText,
        }}
      ></span>
      {tooLong && (
        <div
          style={{ fontWeight: 'bold', padding: '12px 0', cursor: 'pointer' }}
          onClick={() => setCropped(!useCropped)}
        >
          {tooLong && (
            <> {tooLong && useCropped ? 'Lees meer' : 'Lees minder'} </>
          )}
        </div>
      )}
    </Paragraph>
  )
}

export const SmallHeader = styled.h3`
  color: #fff;
  font-size: 1rem;
  padding: 12px 0;
  margin: 0;
  font-weight: 600;
  line-height: 1.6rem;


  @media screen and (max-width: 600px) {
    font-size: 18px;
    line-height: 28px;
  }

  a {
    color: #fff;
  }
`

export const SmallArticleHeader = styled.h3`
  color: #005496;
  font-size: 1rem;
  /* padding: 12px 0; */
  margin: 0;
  font-weight: 600;
  line-height: 1.25rem;

  height: 2.5rem;
`

export const MainH1 = styled.h1`
  color: #000;
  font-size: 2.2rem;
  font-weight: 400;
  padding: 0 0 0 0;
  margin: 0;


  @media screen and (max-width: 600px) {
    font-size: 32px;
  }

  a {
    color: #000;
  }
`

export const H1 = MainH1

export const QuoteH2 = styled.h2`
  ${quoteStandard}
  padding: 0;
`

export const Quote = QuoteH2

export const AlleNieuwsH3 = styled.h3`
  color: #000;
  font-size: 2.5rem;
  font-weight: 400;
  padding: 0 0 0 0;
  margin: 0;

  a {
    color: #000;
  }
`

export const H2 = AlleNieuwsH3

export const RecentProjectsCardH3 = styled.h3`
  color: #005496;
  a {
    color: #005496;

    &:hover {
      color: #00a1d5;
    }
  }
  font-size: 1.3rem;
  font-weight: 400;
  padding: 0 0 0 0;
  margin: 0;

  @media screen and (max-width: 600px) {
    font-size: 23px;
  }
`

export const H3 = RecentProjectsCardH3

export const TintedBlockH3 = styled.h3`
  color: #005496;
  a {
    color: #005496;

    &:hover {
      color: #00a1d5;
    }
  }
  font-size: 2.1rem;
  font-weight: 400;
  padding: 0 0 0 0;
  margin: 0;

`

export const TintedSubBlockH3 = styled.h3`
  color: #005496;
  a {
    color: #005496;

    &:hover {
      color: #00a1d5;
    }
  }
  font-size: 1rem;
  font-weight: 400;
  padding: 30px 0 0;
  margin: 0;

`

export const RecentProjectTitle = styled.h3`
  color: #21a9d8;
  font-size: 1.25rem;
  font-weight: 200;
  margin: 0;

`

export const SubHeader = styled.h3`
  color: #005496;
  a {
    color: #005496;

    &:hover {
      color: #00a1d5;
    }
  }
  font-size: 1.25rem;
  font-weight: 400;
  margin: 0;

`

export const VerticalWhiteTitle = styled.h3`
  color: #fff;
  font-size: 2.5rem;
  font-weight: 400;
  margin: 0;
  writing-mode: tb-rl;
  transform: rotate(180deg);


  a {
    color: #fff;
  }
`

export const PeopleCardTitle = styled.h3`
  color: #ffffff;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  display: block;
  padding-bottom: 1.5vh;


  a {
    color: #fff;
  }
`

export const SearchFilterH4 = styled.h4`
  color: #005496;
  width: 100%;
  border-bottom: 1px solid #005496;
  text-align: left;
  font-size: 1rem;
  font-weight: 400;
  padding: 0 0 0.5rem 0;
  margin: 0;
`

// Link elements themselves
export const ReadMoreButton = styled.a`
  color: #005496;
  a {
    color: #005496;

    &:hover {
      color: #00a1d5;
    }
  }
  font-size: 1rem;
  font-weight: 400;
  margin: 0;

  &:hover {
    color: #00a1d5;
    text-decoration: underline;

    a {
      color: #00a1d5;
    }
  }
`

export const ViewAllLink = styled.a`
  cursor: pointer;
  color: #005496;
  a {
    color: #005496;

    &:hover {
      color: #00a1d5;
    }
  }
  font-size: 1rem;
  font-weight: 400;
  margin: 0;

  &:hover {
    color: #00a1d5;
    a {
      color: #00a1d5;
    }
    text-decoration: underline;
  }
`

export const Button = styled.div`
  color: #005496;
  a {
    color: #005496;

    &:hover {
      color: #00a1d5;
    }
  }
  font-size: 0.9rem;
  font-weight: 400;
  margin: 0;
  margin-top: 2rem;
  cursor: pointer;

  svg {
    stroke: #005496;
    display: inline-block;
    vertical-align: middle;
    width: 1.5rem;
    height: 1.5rem;
  }
  :hover {
    p {
      color: #00a1d5;
    }
    svg g path,
    svg g line {
      stroke: #00a1d5;
    }
  }

  &:hover {
    color: #00a1d5;
    a {
      color: #00a1d5;
    }
    text-decoration: underline;
  }
`

export const ButtonInversed = styled.div`
  color: #fff;

  a {
    color: #fff;
  }

  font-size: 0.9rem;
  font-weight: 400;
  margin: 0;
  margin-top: 2rem;
  background-color: #00a1d5;
  white-space: nowrap;
  text-align: center;
  padding: 1rem;
  cursor: pointer;

  svg {
    display: inline-block;
    vertical-align: middle;
    width: 1.5rem;
    height: 1.5rem;

    g path,
    g line {
      stroke: #fff;
      fill: none;
    }
  }

  &:hover {
    background-color: #027da5;
    color: #fff;
    a {
      color: #fff;
      text-decoration: none;
    }
    text-decoration: none;
  }
`

export const MenuTitle = styled.a`
  color: #005496;
  a {
    color: #005496;

    &:hover {
      color: #00a1d5;
    }
  }
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
  display: block;
  padding-bottom: 1.5vh;

  :hover {
    color: #00a1d5;
    text-decoration: underline;
  }

  &:hover {
    color: #00a1d5;
    a {
      color: #00a1d5;
    }
    text-decoration: underline;
  }
`

export const Characteristic = styled.p`
  color: #005496;
  a {
    color: #005496;

    &:hover {
      color: #00a1d5;
    }
  }
  font-size: 0.85rem;
  line-height: 1.5rem;

  margin: 0;
  padding: 0;
  display: block;

  strong {
    font-weight: 400;
  }
`

export const ContactAddress = styled.p`
  color: #005496;
  a {
    color: #005496;

    &:hover {
      color: #00a1d5;
    }
  }
  font-size: 1rem;
  font-weight: 200;
  margin: 0;
  padding: 0;
  display: block;
`

// Wrappers for links
export const ReadMoreButtonWrapper = styled.span`
  cursor: pointer;

  a {
    color: #005496;

    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
  }
  svg {
    display: inline-block;
    vertical-align: middle;
    width: 1.5rem;
    height: 1.5rem;
  }
  :hover {
    a {
      color: #00a1d5;
      text-decoration: underline;
    }
    svg g path,
    svg g line {
      stroke: #00a1d5;
    }
  }

  &:hover {
    color: #00a1d5;
    a {
      color: #00a1d5;
    }
    text-decoration: underline;
  }
`

export const ViewAllLinkWrapper = styled.span`
  cursor: pointer;

  a {
    color: #005496;

    display: inline-block;
    vertical-align: middle;
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
  }
  svg {
    display: inline-block;
    vertical-align: middle;
    width: 1.5rem;
    height: 1.5rem;
  }

  &:hover {
    a {
      color: #00a1d5;
      text-decoration: underline;
    }
    svg g path,
    svg g line {
      stroke: #00a1d5;
    }
  }

  &:hover {
    color: #00a1d5;
    a {
      color: #00a1d5;
    }
    text-decoration: underline;
  }
`

export const MenuTitleWrapper = styled.span`
  cursor: pointer;
  a {
    color: #005496;

    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 1.5vh;
  }
  svg {
    display: inline-block;
    vertical-align: middle;
    width: 1.5rem;
    height: 1.5rem;
  }
  :hover {
    a {
      color: #00a1d5;
      text-decoration: underline;
    }
    svg g path,
    svg g line {
      stroke: #00a1d5;
    }
  }

  &:hover {
    color: #00a1d5;
    a {
      color: #00a1d5;
    }
    text-decoration: underline;
  }
`

export const MenuLinkWrapper = styled.span`
  cursor: pointer;

  a {
    color: #005496;

    font-size: 0.95rem;
    font-weight: 400;
    margin: 0;
    padding: 4px 0;
    display: inline-block;
    vertical-align: middle;
  }
  svg {
    display: inline-block;
    vertical-align: middle;
    width: 1.5rem;
    height: 1.5rem;
  }
  :hover {
    a {
      color: #00a1d5;
    }
    svg g path,
    svg g line {
      stroke: #00a1d5;
    }
  }

  &:hover {
    color: #00a1d5;
    a {
      color: #00a1d5;
    }
    text-decoration: underline;
  }
`

export const SubFooterLinkWrapper = styled.span`
  cursor: pointer;

  a {
    color: #fff;
    font-size: 0.8rem;
    font-weight: 200;
    margin: 0;
    padding: 4px 0;
    display: inline-block;
    vertical-align: middle;
  }
  &:hover {
    a {
      color: #00a1d5;
      text-decoration: underline;
    }
  }
`

export const MenuChildLinkWrapper = styled.span`
  cursor: pointer;

  a {
    color: #005496;

    font-size: 0.8rem;
    font-weight: 400;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    padding: 8px 0;
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    width: 1.5rem;
    height: 1.5rem;
  }
  &:hover {
    a {
      color: #00a1d5;
      text-decoration: underline;
    }
    svg g path,
    svg g line {
      stroke: #00a1d5;
    }
  }
`

export const CompanyLinkWrapper = styled.span`
  cursor: pointer;

  a {
    color: #005496;

    font-size: 1.15rem;
    margin: 0;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const PunchLine = styled.p`
  color: #005496;
  a {
    color: #005496;

    &:hover {
      color: #00a1d5;
    }
  }
  font-size: 0.98rem;
  padding: 0 0 1.5rem 0;
  margin: 0;
`

export const BreadcrumbLinkWrapper = styled.span`
  cursor: pointer;

  a {
    color: #000;
    font-size: 0.8rem;
    font-weight: 400;
    margin: 0;
    text-transform: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const BreadcrumbActiveLinkWrapper = styled.span`
  cursor: pointer;

  a {
    color: #000;
    font-size: 0.8rem;
    font-weight: 400;
    margin: 0;
    text-transform: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
`
