import React from 'react'
import styled from 'styled-components'

// Core
import { P, TintedBlockH3 } from '../../elements/typography'
import { PageWrapper } from '../../elements/pageContainer'
import BuildLinkElement from '../../elements/linkBuilder'
import useResize from '../../utils/useResize'
import BuildImage from '../../elements/imageBuilder'
import Breadcrumbs from '../../modules/breadcrumbs'

// Placeholder image
// import imageElement from '../../../assets/media/person.png'

// Module styling
const ExperitseWrapper = styled.div`
  background: #eaf7fc;
  min-height: 750px;

  .textWrapper {
    background: #fff;
    min-height: 750px;
    display: inline-block;
    vertical-align: top;
    padding: 30vh 300px 30vh 80px;
    width: 100%;
    position: relative;

    h3 {
      margin-top: 60px;
    }
  }

  img {
    position: absolute;
    top: 30vh;
    right: -150px;
    width: 300px;
  }

  .content {
    box-sizing: border-box;
    padding-right: 200px;
  }

  @media screen and (max-width: 2050px) {
    .textWrapper {
      padding: 30vh 220px 30vh 120px !important;
    }
  }

  @media screen and (max-width: 1280px) {
    .textWrapper {
      padding-right: 60px;
      padding-left: 60px;
    }
  }

  @media screen and (max-width: 800px) {
    background: #fff;

    img {
      position: relative;
      top: 0;
      right: 0;
      width: 100%;
    }

    .content {
      padding-right: 0px;

      img {
        display: none;
      }
    }

    .textWrapper {
      padding: 60px 8vw ${(props) => (props.backgroundAsset ? '200px' : '60px')} !important;
    }
  }
`

const OverlayImage = styled.div`
  height: 100px;
  background: url(${(props) => props.assetImage}) top left no-repeat;
  max-width: 1336px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0;
`

const ExpertiseComponent = ({
  title,
  image = {},
  desc,
  children,
  link = {},
  backgroundAsset,
  hasBreadcrumbs,
  breadcrumbs,
  // hasBreadcrumbs = true,
  // breadcrumbs = [
  //   {
  //     linkText: 'vorm',
  //     href: '#',
  //   },
  //   {
  //     linkText: 'over vorm',
  //     href: '#',
  //   },
  //   {
  //     linkText: 'example page',
  //     href: '#',
  //   },
  // ]
}) => {
  // Fullscreen variables
  const [margin, setMargin] = React.useState('0px')
  const containerRef = React.useRef()
  const { width } = useResize()

  let timeout = null
  React.useEffect(() => {
    const props = containerRef.current.getBoundingClientRect()
    const { left } = props

    setMargin(`${left}px`)
  }, [containerRef])

  React.useEffect(() => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      const { left } = containerRef.current.getBoundingClientRect()
      setMargin(`${left}px`)
    }, 100)
  }, [width])

  return (
    <ExperitseWrapper backgroundAsset={backgroundAsset}>
      <PageWrapper className="content">
        <div
          style={{ marginLeft: `-${margin}`, paddingLeft: margin }}
          className="textWrapper"
        >
          <div>
            {breadcrumbs && hasBreadcrumbs && (
              <Breadcrumbs data={breadcrumbs} noPad />
            )}
            <TintedBlockH3> {title} </TintedBlockH3>
            <P text={desc} />
            <div className="childElement"> {children} </div>

            {link && link.href && (
              <BuildLinkElement link={link} linkStyle="ViewAllLink" showArrow />
            )}
          </div>

          <BuildImage className="mediaElement" image={image} />
        </div>
        <div ref={containerRef}></div>
        {backgroundAsset && (
          <OverlayImage
            style={{ left: `-${margin}` }}
            className="artbackground"
            assetImage={backgroundAsset}
          />
        )}
      </PageWrapper>
    </ExperitseWrapper>
  )
}
ExpertiseComponent.defaultProps = {}
export default ExpertiseComponent
