import React from 'react'
// Core
import SectionWrapper from '../../../core/modules/sectionBackgroundWrapper'
import TwoColumnText from '../../modules/twoColumnTextBlock'
import { TintedBlockH3, PunchLine } from '../../elements/typography'
import BuildLinkElement from '../../elements/linkBuilder'

const defaultSectionData = {
  type: 'blank',
  paddingType: 'small',
  notFullWidth: true,
}

const VacancyBlock = ({
  vacancy,
  sectionData,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
}) => {
  const newProps = { ...defaultSectionData, ...sectionData }
  const { title, leftColumnText, rightColumnText, endText, link } = vacancy
  return (
    <SectionWrapper
      paddingSize="m"
      {...newProps}
      topQuote={topQuote}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
    >
      <TintedBlockH3>{title}</TintedBlockH3>
      <TwoColumnText text1={leftColumnText} text2={rightColumnText} />
      <PunchLine>{endText}</PunchLine>
      <BuildLinkElement link={link} linkStyle="MenuLink" />
    </SectionWrapper>
  )
}
VacancyBlock.defaultProps = {
  sectionData: {
    type: 'clearBlueBackground',
    paddingType: 'small',
    notFullWidth: true,
  },
}
export default VacancyBlock
