import React from 'react'
import styled from 'styled-components'
import { PageWrapper } from '../../../core/elements/pageContainer'
import BigBlueTextBlock from '../../../core/modules/bigBlueTextBlock'
import PaddingWrapper from '../../elements/paddingContainer'

const Section = styled.div`
  > div {
    width: 33%;
    display: inline-block !important;
    vertical-align: top;
    height: 230px;
    line-height: 230px;
    box-sizing: border-box;

    &:nth-child(1) {
      padding-right: 10px;
    }

    &:nth-child(2) {
      padding: 0px 5px;
    }

    &:nth-child(3) {
      padding-left: 10px;
    }
  }

  @media screen and (max-width: 1200px) {
    > div {
      h3 {
        font-size: 2vw !important;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    > div {
      width: 100% !important;
      display: block;
      margin-bottom: 22px;
      padding: 0px;

      h3 {
        font-size: 4vw !important;
      }
    }
  }

  @media screen and (max-width: 1800px) {
  }
`

const SectionPreview = ({ text1, text2, text3, hasBreadcrumbs }) => {
  return (
    <PaddingWrapper size="m" hasBreadcrumbs={hasBreadcrumbs}>
      <PageWrapper className="responsiveContainer">
        <Section>
          <div>
            <BigBlueTextBlock text={text1} />
          </div>
          <div>
            <BigBlueTextBlock text={text2} />
          </div>
          <div>
            <BigBlueTextBlock text={text3} />
          </div>
        </Section>
      </PageWrapper>
    </PaddingWrapper>
  )
}
SectionPreview.defaultProps = {}
export default SectionPreview
