import React from 'react'

// Core
import SectionWrapper from '../../../core/modules/sectionBackgroundWrapper'
import ContactPerson from '../../modules/contactPersonCard'

const defaultSectionData = {
  type: 'blank',
  paddingType: 'blank',
  notFullWidth: true,
  titleType: 'tintedBlockH3',
}

const ExpertiseBlockWithIcons = ({
  contactInfo,
  sectionData,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
}) => {
  const newProps = {
    ...defaultSectionData,
    ...sectionData,
    notFullWidth: true,
  }
  return (
    <SectionWrapper
      {...newProps}
      topQuote={topQuote}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
    >
      <ContactPerson {...contactInfo} />
    </SectionWrapper>
  )
}
ExpertiseBlockWithIcons.defaultProps = {
  sectionData: {
    title: '',
  },
}

export default ExpertiseBlockWithIcons
