import React from 'react'
import styled from 'styled-components'
import { ReactComponent as ChevronDown } from '../../../assets/svg/chevron_down.svg'

const Container = styled.div`
  display: block;
  padding-bottom: 1rem;

  .queryData {
    display: inline-block;
    vertical-align: top;
    width: 60%;

    .searchTerm {
      width: 100%;
      color: #000000;
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 0.25rem;
      margin-top: 0;
    }
    .results {
      width: 100%;
      font-size: 1rem;
      margin: 0;
    }
  }
  .sort {
    display: inline-block;
    width: 40%;
    vertical-align: bottom;
    text-align: right;
    position: relative;

    span {
      font-weight: bold;
      cursor: pointer;

      svg {
        width: 12px;
        path {
          stroke: #000000;
          stroke-width: 4px;
        }
      }
    }
    .modal {
      position: absolute;
      right: 0;
      top: 2rem;
      background-color: #fff;
      z-index: 9;
      border: 1px solid #005496;

      .select {
        width: 100%;
        padding: 22px;

        span {
          width: 100%;
          display: block;
          text-align: left;
          font-weight: 200;
          padding: 0.5rem;
          &.selected {
            font-weight: bold;
          }
          :not(.selected) {
            &:hover,
            &:focus {
              background: #edf5fa 0% 0% no-repeat padding-box;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 680px) {
    display: inline-block !important;
    width: calc(100% - 20pt);
    vertical-align: top;
    .queryData {
      width: 100%;
      .results {
        font-size: 12pt;
      }
    }
    .sort {
      width: 100%;
      text-align: left;
      padding: 1rem 0;
      font-size: 12pt;
    }
  }
`

const SearchResultsOrder = ({ data, sorting, order, setOrder, searched }) => {
  // let [sort, setSort] = React.useState(sorting[0]);
  let [show, setShow] = React.useState(false)
  const containerReference = React.useRef()

  const handleClicksInOverlay = (e) => {
    if (!containerReference.current) {
      return null
    }

    const { pageX, pageY } = e
    const {
      left,
      top,
      bottom,
      right,
    } = containerReference.current.getBoundingClientRect()

    if (pageY >= top && pageY <= bottom && pageX >= left && pageX <= right) {
      return
    } else {
      setShow(false)
    }
  }

  React.useEffect(() => {
    const handleClick = (e) => {
      if (show) {
        handleClicksInOverlay(e)
      }
    }
    document.removeEventListener('click', handleClick)
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [show])

  let handleSortChange = (item) => {
    setOrder(item)
    setShow(false)
  }
  return (
    <Container>
      <div className="queryData">
        <h3 className="searchTerm">{searched}</h3>
        <p className="results">{data.totalItems || 0} resultaten</p>
      </div>
      <div className="sort">
        Sorteren op:{' '}
        <span
          onClick={() => {
            setShow(!show)
          }}
        >
          {order.label} <ChevronDown />
        </span>
        {show && (
          <div className="modal" ref={containerReference}>
            <div className="select">
              {sorting.map((item) => {
                return (
                  <span
                    onClick={() => handleSortChange(item)}
                    className={`${item.sort == order.value ? 'selected' : ''}`}
                    key={`sort-option-${item.value}`}
                  >
                    {item.label}
                  </span>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}

export default SearchResultsOrder
