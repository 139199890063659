import React from 'react'
import styled from 'styled-components'

// Core
import { P, SmallHeader } from '../../elements/typography'
import ContactInfoCard from '../emergencyContactInfo'

// Module styling
const ContactWrapper = styled.div`
  padding: 3rem;
  background-color: #005496;

  p,
  h3 {
    color: #fff;
  }

  padding: 0px 40px;

  @media screen and (max-width: 1150px) {
    padding: 0px 20px;
  }
`

const EmergencyContact = ({ title, desc, info = {} }) => {
  return (
    <ContactWrapper className="mediaElement">
      <SmallHeader>{title}</SmallHeader>
      <P text={desc} />

      {info?.phone && <ContactInfoCard type="phone" input={info?.phone} />}
      {info?.email && <ContactInfoCard type="email" input={info?.email} />}
    </ContactWrapper>
  )
}
EmergencyContact.defaultProps = {}
export default EmergencyContact
