import * as React from 'react'

export default function Iframe(props) {
  const { height, width } = props

  const style = {
    height: height || '100%',
    width: width || '100%',
  }
  return <iframe {...props} style={style} allow="fullscreen" frameBorder="0" />
}
