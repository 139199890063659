import React from 'react'
import styled from 'styled-components'
// Core
import SectionWrapper from '../../modules/sectionBackgroundWrapper'
import ExpertiseIconBlock from '../../modules/expertisesIconBlock'
import { TintedBlockH3, P } from '../../elements/typography'
// import BuildLinkElement from "../../elements/linkBuilder";
import BuildImage from '../../elements/imageBuilder'

// Placeholder image
// import imageElement from '../../../assets/media/person.png'

const HalfBlockLeft = styled.div`
  display: inline-block !important;
  width: 50%;
  vertical-align: top;

  padding: 2rem 0 0 0;

  > h3 {
    height: 4.8rem;
    padding-right: 5rem;
  }

  p {
    padding-bottom: 5rem;
    padding-right: 5rem;
    padding-top: 1rem;

    p {
      padding: 0;
    }
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
    display: block !important;

    p {
      padding-right: 0px;
    }
  }
`
const HalfBlockRight = styled.div`
  display: inline-block !important;
  width: 50%;
  height: 50%;
  vertical-align: top;

  @media screen and (max-width: 1000px) {
    width: 100%;
    display: block !important;
  }

  img {
    max-height: 450px;
  }
`

const defaultSectionData = {
  type: 'clearBlueBackground',
  paddingType: 'small',
  notFullWidth: true,
}

const HeadlineImageIcons = ({
  title,
  desc,
  image,
  icons,
  expertiseLink,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  hasBreadcrumbs,
  breadcrumbs,
  iconsDesc,
}) => {
  const newProps = { ...defaultSectionData }
  return (
    <SectionWrapper
      {...newProps}
      topQuote={topQuote}
      breadcrumbs={breadcrumbs}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
      hasBreadcrumbs={hasBreadcrumbs}
      paddingSize="l"
    >
      <HalfBlockLeft>
        <TintedBlockH3>{title}</TintedBlockH3>
        <P text={desc} />
        <ExpertiseIconBlock
          iconsDesc={iconsDesc}
          expertises={icons}
          link={expertiseLink}
        />
      </HalfBlockLeft>
      <HalfBlockRight>
        <BuildImage image={image} />
      </HalfBlockRight>
    </SectionWrapper>
  )
}
HeadlineImageIcons.defaultProps = {}
export default HeadlineImageIcons
