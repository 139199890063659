import React from 'react'
import styled from 'styled-components'

// placeholder image
// import image from '../../../assets/media/building.png'
import BuildImage from '../../elements/imageBuilder'
import { PodcastsIcons } from '../podcastDetails'
import { RecentProjectsCardH3, P } from '../../elements/typography'
import BuildLinkElement from '../../elements/linkBuilder'

const SmallImageDescWrapper = styled.div`
  // max-width: 25%;
  // min-height: 420px;
  width: 100%;
  display: inline-block !important;

  .image-wrapper {
    width: 100%;
    height: 260px;
    overflow: hidden;
    margin-bottom: 2rem;

    img {
      width: 100%;
      aspect-ratio: 1;
      min-height: 100%;
      object-fit: cover;
      object-position: center center;

      ${props => props.imagePosition ? `
        
        object-position: ${props.imagePosition};
      ` : ''}
    }
  }

  .podcasts {
    margin-bottom: 8px;
  }

  ${(props) =>
    !props.showMoreCardInformation
      ? `
    h3 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 20px;
      margin-bottom: 10px;
    }

    p {
      overflow: hidden;
      height: 63px;
      margin-bottom: 5px;
      padding: 0;
    }
  `
      : `
    h3 {
      height: 50px;
      overflow: hidden;
      white-space: initial;
      margin-bottom: 10px;
    }

    p {
      overflow: hidden;
      height: 103px;
      margin-bottom: 5px;
      padding: 0;
    }
  `}
`

// Module
const CardModule = ({ image, title, desc, link, showMoreCardInformation, imagePosition, podcastLinks }) => {
  return (
    <SmallImageDescWrapper imagePosition={imagePosition} showMoreCardInformation={showMoreCardInformation}>
      <div className="image-wrapper">
        <BuildImage image={image} />
      </div>
      <RecentProjectsCardH3> {title} </RecentProjectsCardH3>
      {podcastLinks && <PodcastsIcons showText compact links={podcastLinks}/>}
      {desc && <P text={desc} />}
      {link && link?.href && (
        <BuildLinkElement link={link} linkStyle="ReadMoreButton" showArrow />
      )}
    </SmallImageDescWrapper>
  )
}
CardModule.defaultProps = {}
export default CardModule
