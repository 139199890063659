import React from 'react'

import Section from '../../modules/expertiseHeadlineBlockRight'
import SectionWrapper from '../../../core/modules/sectionBackgroundWrapper'
// import media from '../../../assets/media/person.png'

const defaultSectionData = {
  type: 'blank',
  paddingType: 'blank',
}

const ExpertiseSection = (props) => {
  const newProps = {
    ...defaultSectionData,
    ...props.sectionData,
    notFullWidth: !props.isFullScreen,
  }
  return (
    <SectionWrapper {...newProps}>
      <Section {...props} />
    </SectionWrapper>
  )
}
ExpertiseSection.defaultProps = {}
export default ExpertiseSection
