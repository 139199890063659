import React from 'react'
import styled from 'styled-components'

// import { PageWrapper } from '../../elements/pageContainer'

// Core
import { TintedBlockH3, P, Button } from '../../elements/typography'
import InputField from '../../elements/inputField'
import { ReactComponent as Rightarrow } from '../../../assets/svg/arrow_right.svg'

// API Driven logic
import { fetchApi } from '../../utils/api/index'

const buildApiUrl = (state, api) => {
  let newApi = `${api}?`
  Object.entries(state).forEach((entry) => {
    const [key, value] = entry
    if (key && value && value !== 'none' && value !== null) {
      if (newApi.slice(-1) === '?') {
        newApi = `${newApi}${key}=${value}`
      } else {
        newApi = `${newApi}&${key}=${value}`
      }
    }
  })

  return newApi
}

// Module styling
// const BLOCK_WIDTH = WIDTH / 2
const BigMediaTextBlock = styled.div`
  background: #f7fbfc;

  .mediaCol {
    width: 50%;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    padding: 3rem 4rem;

    p {
      padding: 2rem 0 0 0;
    }

    .result {
      font-size: 3rem;
      color: #00a1d5;

      p {
        display: inline-block;
        font-size: 0.75rem;
        color: #005496;
        padding: 0 0.5rem;
        display: inline-block;
      }
    }

    &.blueBackground {
      background: #005496;

      div,
      h3,
      p {
        color: #fff;
      }
      svg g {
        path,
        line {
          stroke: #fff;
        }
      }
      div {
        :hover {
          color: #00a1d5;
          svg g {
            path,
            line {
              stroke: #00a1d5;
            }
          }
        }
      }
    }

    &.resultBlock {
      h3 {
        padding-bottom: 1rem;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .mediaCol {
      width: 100%;
      display: block;
    }
  }

  .errorField {
    padding: 30px 0 0 0;
    color: rgba(255, 255, 255, 0.5) !important;
    font-weight: bold;
  }
`

const BigMediaTextModule = ({
  title,
  input1,
  input2,
  buttonText,
  resultTitle,
  resultText,
  api,
}) => {
  const [localState, setLocalState] = React.useState({})
  const [loading, isLoading] = React.useState(false)
  const [data, setData] = React.useState(0)
  const [inputError, setInputError] = React.useState(false)

  const updateState = (payload) => {
    setLocalState({
      ...localState,
      ...payload,
    })
  }

  const handleMe = () => {
    const url = buildApiUrl(localState, api)
    isLoading(true)

    if (
      !localState.value1 ||
      !localState.value2 ||
      localState.value1 === 0 ||
      localState.value2 === 0
    ) {
      setInputError(true)
      isLoading(false)
      return
    }

    setInputError(false)
    if (url.slice(-1) === '?') {
      isLoading(false)
    } else {
      fetchApi(url).then((data) => {
        setData(parseInt(data.data))
        isLoading(false)
      })
    }
  }

  return (
    <BigMediaTextBlock>
      <div className="mediaCol blueBackground">
        <TintedBlockH3>{title}</TintedBlockH3>

        <P text={input1?.desc} />
        <InputField
          placeholder={input1?.placeholder}
          value={localState.value1}
          onChange={(e) => {
            updateState({
              value1: e.target.value,
            })
          }}
          name={input1?.number}
          type={input1?.type}
        />
        <P text={input2?.desc} />
        <InputField
          placeholder={input2?.placeholder}
          value={localState.value2}
          onChange={(e) => {
            updateState({
              value2: e.target.value,
            })
          }}
          name={input2?.name}
          type={input2?.type}
        />
        <Button onClick={handleMe}>
          {buttonText}
          <Rightarrow />
        </Button>
        {inputError && (
          <div className="errorField"> FOUT: Beide velden zijn verplicht </div>
        )}
      </div>
      <div className="mediaCol resultBlock">
        <TintedBlockH3>{resultTitle}</TintedBlockH3>
        {!loading && (
          <div className="result">
            {isNaN(data) ? (
              'Error'
            ) : (
              <>
                {' '}
                €{data} <p>Brutto</p>{' '}
              </>
            )}
          </div>
        )}
        {loading && <div className="result">Verwerken...</div>}
        <P text={resultText} />
      </div>
    </BigMediaTextBlock>
  )
}
BigMediaTextModule.defaultProps = {}
export default BigMediaTextModule
