// Initial URL
const apiURL = ''

// Handle response
const handleResponse = async (data) => {
  if (!data || !data.json) {
    return
  }

  try {
    if (data.status === 401) {
      return Promise.reject('unAuthenticated')
    }

    if (!data.ok) {
      return Promise.reject(data)
    }

    if (data.status >= 200 && data.status <= 299) {
      const contentType = data.headers.get('content-type')
      if (contentType && contentType.indexOf('application/json') !== -1) {
        return data.json()
      } else {
        return {}
      }
    }

    return Promise.reject(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

// Fetch / GET
export const fetchApi = async (url, header = {}) => {
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...header,
  }

  return fetch(`${apiURL}${url}`, {
    mode: 'cors', // no-cors, *cors, same-origin
    credentials: 'include',
    headers: defaultHeaders,
  }).then((data) => handleResponse(data))
}

// POST
export const postApi = async (url, payload = {}, header = {}) => {
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...header,
  }

  return fetch(`${apiURL}${url}`, {
    method: 'POST',
    mode: 'cors', // no-cors, *cors, same-origin
    credentials: 'include',
    headers: defaultHeaders,
    body: JSON.stringify(payload),
  }).then((data) => handleResponse(data))
}

// PUT
export const putApi = async (url, id, payload = {}, header = {}) => {
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...header,
  }

  const item_url = new URL(`${apiURL}${url}`, window.location.origin)

  if (item_url.pathname.includes && !item_url.pathname.includes(`${id}/`)) {
    item_url.pathname += `${id}/`
  }

  return fetch(item_url.href, {
    method: 'PUT',
    mode: 'cors', // no-cors, *cors, same-origin
    credentials: 'include',
    headers: defaultHeaders,
    body: JSON.stringify(payload),
  })
    .then((data) => handleResponse(data))
    .catch((error) => Promise.reject(error))
}

// DELETE
export const deleteApi = async (url, id, payload = {}, header = {}) => {
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...header,
  }

  const item_url = new URL(`${apiURL}${url}`, window.location.origin)
  if (item_url.pathname.includes && !item_url.pathname.includes(`${id}/`)) {
    item_url.pathname += `${id}/`
  }
  return fetch(item_url.href, {
    method: 'DELETE',
    mode: 'cors', // no-cors, *cors, same-origin
    credentials: 'include',
    headers: defaultHeaders,
    body: JSON.stringify(payload),
  })
    .then((data) => handleResponse(data))
    .catch((error) => Promise.reject(error))
}
