import React from 'react'
import styled from 'styled-components'
import { RecentProjectsCardH3 } from '../../elements/typography'
import { BuildLinkWrapper } from '../../elements/linkBuilder'

// Card Wrap
const Icon = styled.img`
  max-width: calc(100% - 4rem);
  max-height: 100px;
  overflow: hidden;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  max-height: 80px;
`

const Large = styled.div`
  display: inline-block !important;
  width: 33%;
  height: 100%;
  padding-top: 40px;
  // overflow: hidden;
  vertical-align: top;

  .textWrapper {
    text-align: center;
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @media screen and (max-width: 1000px) {
    width: 25%;
  }

  @media screen and (max-width: 700px) {
    width: 60%;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`
const Small = styled.div`
  display: inline-block !important;
  width: 25%;
  height: 100%;
  padding-top: 40px;
  // overflow: hidden;
  vertical-align: top;

  img {
    padding: 1rem 0 2rem;
  }

  .textWrapper {
    text-align: center;
    padding-right: 2rem;
    padding-left: 2rem;

    h3 {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 1000px) {
    width: 50%;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`

// Map type to element
const map = {
  small: Small,
  large: Large,
}

// Element
const IconElement = ({ image, desc, type = 'large', href }) => {
  const Wrapper = map[type]

  if (!Wrapper) {
    return null
  }

  return (
    <Wrapper>
      <BuildLinkWrapper link={{ href }}>
        <Icon src={image?.src || image} alt="" />
        <div className="textWrapper">
          <RecentProjectsCardH3>{desc}</RecentProjectsCardH3>
        </div>
      </BuildLinkWrapper>
    </Wrapper>
  )
}
IconElement.defaultProps = {}

export default IconElement
