import React from 'react'
import styled from 'styled-components'

// Core
import { P, RecentProjectsCardH3 } from '../../elements/typography'
import ContactInfoCard from '../contactPersonInfo'

// Placeholder image
// import imageElement from '../../../assets/media/person.png'

// Module styling
const ContactPersonWrapper = styled.div`
  padding-top: 5rem;

  .textContentWrapper {
    width: 70%;
    display: inline-block;
    padding: 0 4rem 0 7rem;
    vertical-align: top;

    h3 {
      padding-bottom: 2rem;
      font-size: 1.25rem;
    }
  }

  @media screen and (max-width: 850px) {
    .textContentWrapper {
      width: 100%;
      display: block;
      padding: 0 2rem;
      vertical-align: top;

      padding: 0;
    }
  }
`

const ImageBlock = styled.img`
  display: inline-block;
  width: 30%;
  aspect-ratio: 1;
  vertical-align: top;
  padding: 0;
  max-width: 356px;

  object-fit: cover;
  object-position: center center;

  @media screen and (max-width: 850px) {
    width: 100%;
    display: block;
    margin-bottom: 30px;
    max-height: 260px;
  }
`

const ContactPerson = ({ title, image, desc, info }) => {
  return (
    <ContactPersonWrapper>
      <ImageBlock
        style={image?.position ? { objectPosition: image?.position } : {}}
        className="mediaElement"
        src={image?.src}
        alt=""
      />
      <div className="textContentWrapper">
        <RecentProjectsCardH3> {title} </RecentProjectsCardH3>
        <P text={desc} />
        <ContactInfoCard {...info} />
      </div>
    </ContactPersonWrapper>
  )
}
ContactPerson.defaultProps = {}
export default ContactPerson
