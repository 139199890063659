import React from 'react'

import Module from '../../../core/sections/header'
import App from '../../../App'

import image from '../../../core/sections/header/assets/header.png'
import imageElement from '../../../assets/media/building.png'

export default {
  title: 'section-examples/Header',
  component: Module,
  argTypes: {},
}

const Template = (args) => (
  <App>
    <Module {...args} />
  </App>
)

let items = [
  {
    title: 'Functie',
    desc:
      'Nulla et posuere neque, sed rhoncus ligula. Cras tempus neque nec eros efficitur varius. Curabitur non dignissim orci. Pellentesque tempus sem vitae tempus iaculis. Etiam in finibus justo, et mattis orci.',
    date: '02-05-2022',
    link: {
      href: '#',
      title: 'Lees meer',
    },
    image: {
      position: 'center center',
      src: imageElement,
      alt: '',
    },
    category: 'nieuws',
  },
  {
    title: 'Functie',
    desc:
      'Nulla et posuere neque, sed rhoncus ligula. Cras tempus neque nec eros efficitur varius. Curabitur non dignissim orci. Pellentesque tempus sem vitae tempus iaculis. Etiam in finibus justo, et mattis orci. Cras mauris est, varius id odio eget, bibendum suscipit lorem. Vivamus lacinia aliquam sem quis egestas. Sed ac vestibulum sapien. Sed ac felis lectus. Sed at interdum velit, et congue ante. Duis metus quam, mattis ac pharetra et, sodales egestas diam. Fusce imperdiet, libero in aliquam molestie, eros nulla facilisis nisl, in venenatis ligula dolor sit amet lectus.',
    link: {
      href: '#',
      title: 'Lees meer',
    },
    image: {
      position: 'center center',
      src: imageElement,
      alt: '',
    },
    category: 'nieuws',
  },
  {
    title: 'Functie asd asddasdas sadasdasd ',
    desc: 'Nulla et posuere neque, sed rhoncus ligula.',
    date: '02-05-2022',
    link: {
      href: '#',
      title: 'Lees meer',
    },
    image: {
      position: 'center center',
      src: imageElement,
      alt: '',
    },
    category: 'nieuws',
  },
  {
    title: 'Functie',
    desc: '',
    link: {
      href: '#',
      title: 'Lees meer',
    },
    image: {
      position: 'center center',
      src: imageElement,
      alt: '',
    },
    category: 'nieuws',
  },
  {
    title: 'Functie',
    desc: '',
    link: {
      href: '#',
      title: 'Lees meer',
    },
    image: {
      position: 'center center',
      src: imageElement,
      alt: '',
    },
    category: 'nieuws',
  },
  {
    title: 'Functie',
    desc: '',
    link: {
      href: '#',
      title: 'Lees meer',
    },
    image: {
      position: 'center center',
      src: imageElement,
      alt: '',
    },
    category: 'nieuws',
  },
  {
    title: 'Functie',
    desc: '',
    link: {
      href: '#',
      title: 'Lees meer',
    },
    image: {
      position: 'center center',
      src: imageElement,
      alt: '',
    },
    category: 'nieuws',
  },
  {
    title: 'Functie',
    desc: '',
    link: {
      href: '#',
      title: 'Lees meer',
    },
    image: {
      position: 'center center',
      src: imageElement,
      alt: '',
    },
    category: 'nieuws',
  },
]
export let mocksearch = {
  texts: {
    readMore: 'Bekijk alle resultaten',
    title: 'Top :category',
    // In the backend, this is passed when 3 or more chars are set in the search
    titleSearch: `We hebben :count resultaten gevonden! Hier zijn top :category`,
    inputPlaceholder: `Waar ben je naar op zoek?`,
    notFound: 'Geen resultaten gevonden',
    lengthError: 'U moet een zoekterm van 5 tekens invoeren',
    categories: {
      everything: "artikelen, projecten en overige pagina's",
      recent: 'artikelen',
      projects: 'projecten',
      other: "overige pagina's",
    },
  },
  sorting: [
    {
      label: 'Relevantie',
      value: 'relevance',
    },
    {
      label: 'Datum nieuw oud',
      value: 'date',
    },
    {
      label: 'Datum oud nieuw',
      value: '-date',
    },
    {
      label: 'Van A naar Z',
      value: 'titel',
    },
  ],
  filters: {
    Projecten: [
      {
        label: 'Expertise',
        type: 'radio',
        values: [
          {
            label: 'Gebiedsontwikkeling',
            value: 'Gebiedsontwikkeling',
          },
          {
            label: 'Vastgoedontwikkeling',
            value: 'vastgoed',
          },
          {
            label: 'Transformeren',
            value: 'transformation',
          },
          {
            label: 'Verduurzamen',
            value: 'verduurzamen',
          },
          {
            label: 'Vastgoedonderhoud',
            value: 'vastgoedonderhoud',
          },
        ],
      },
      {
        label: 'Soort Bouw',
        type: 'radio',
        values: [
          {
            label: 'Laagbouw',
            value: 'laagbouw',
          },
          {
            label: 'Middelhoogbouw',
            value: 'middelhoogbouw',
          },
          {
            label: 'Hoogbouw',
            value: 'hoogbouw',
          },
          {
            label: 'Utiliteitsbouw',
            value: 'utility',
          },
        ],
      },
      {
        label: 'Plaats',
        type: 'select',
        values: [
          {
            label: 'Leeuwarden',
            value: 'Leeuwarden',
          },
          {
            label: 'Amsterdam',
            value: 'Amsterdam',
          },
          {
            label: 'Groningen',
            value: 'Groningen',
          },
          {
            label: 'Utrecht',
            value: 'Utrecht',
          },
        ],
      },
    ],
  },
  results: {
    Alles: {
      key: 'everything',
      totalItems: 120,
      totalPages: 12,
      items: items,
    },
    Actueel: {
      key: 'recent',
      totalItems: 10,
      totalPages: 1,
      items: items,
    },
    Projecten: {
      key: 'projects',
      totalItems: 20,
      totalPages: 2,
      items: items,
    },
    Overige: {
      key: 'other',
      totalItems: 30,
      totalPages: 3,
      items: items,
    },
  },
  searchTerm: '',
  api: '',
  minSearchLength: 3,
}

export const Primary = Template.bind({})
Primary.args = {
  search: mocksearch,
  title: 'VORM ontwikkelt jouw toekomst!',
  text:
    'sajdhsaid asbd hajsbd jhasvdj asvbdhjbsahdjbas hjdbash djbasdb ashdbasdhb asjdh abjhdbas hdbahjd asjhdb ashjdbaj hsbdjha sbdajhs bdjahsbd',
  overlayColor: 'rgba(7,32,50,0.15)',
  headerOptions: [
    {
      link: {
        href: '#',
        linkText: 'Slimme toekomst',
      },
    },
    {
      link: {
        href: '#',
        linkText: 'Duurzame toekomst',
      },
    },
    {
      link: {
        href: '#',
        linkText: 'Betaalbare toekomst',
      },
    },
    {
      link: {
        href: '#',
        linkText: 'Leefbare toekomst',
      },
    },
  ],
  showHeaderOptions: true,
  image: {
    position: 'center center',
    src: image,
  },
  mainMenu: [
    {
      to: '/projecten',
      text: 'projecten',
    },
    {
      to: '/aanbod',
      text: 'aanbod',
    },
    {
      to: '/actueel',
      text: 'actueel',
    },
    {
      to: '/over-vorm',
      text: 'over vorm',
    },
    {
      to: '/contact',
      text: 'contact',
    },
  ],
  leftMenuContent: [
    {
      title: 'Aanbod',
      href: '#',
      items: [
        {
          linkText: 'Aanbod particulier',
          href: '/vorm-ict/whoop',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
          children: [
            {
              linkText: 'Child link',
              href: '#',
            },
          ],
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
      ],
    },
    {
      title: 'Aanbod',
      href: '#',
      items: [
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
          children: [
            {
              linkText: 'Child link',
              href: '#',
            },
          ],
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
      ],
    },
    {
      title: 'Aanbod',
      href: '#',
      items: [
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
          children: [
            {
              linkText: 'Child link',
              href: '#',
            },
          ],
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
      ],
    },
    {
      title: 'Aanbod',
      href: '#',
      items: [
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
          children: [
            {
              linkText: 'Child link',
              href: '#',
            },
          ],
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
      ],
    },
    {
      title: 'Projecten',
      href: '#',
      showArrow: true,
      items: [
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
          children: [
            {
              linkText: 'Child link',
              href: '#',
            },
          ],
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
      ],
    },
  ],
  rightMenuContent: {
    title: 'Over VORM ',
    items: [
      {
        linkText: 'Aanbod particulier',
        href: '#',
      },
      {
        linkText: 'Aanbod particulier',
        href: '#',
      },
      {
        linkText: 'Aanbod particulier',
        href: '#',
        children: [
          {
            linkText: 'Child link',
            href: '#',
          },
          {
            linkText: 'Child link',
            href: '#',
          },
          {
            linkText: 'Child link',
            href: '#',
          },
          {
            linkText: 'Child link',
            href: '#',
          },
          {
            linkText: 'Child link',
            href: '#',
          },
        ],
      },
      {
        linkText: 'Aanbod particulier',
        href: '#',
      },
      {
        linkText: 'Aanbod particulier',
        href: '#',
      },
      {
        linkText: 'Aanbod particulier',
        href: '#',
        children: [
          {
            linkText: 'Child link',
            href: '#',
          },
          {
            linkText: 'Child link',
            href: '#',
          },
          {
            linkText: 'Child link',
            href: '#',
          },
          {
            linkText: 'Child link',
            href: '#',
          },
          {
            linkText: 'Child link',
            href: '#',
          },
          {
            linkText: 'Child link',
            href: '#',
          },
          {
            linkText: 'Child link',
            href: '#',
          },
          {
            linkText: 'Child link',
            href: '#',
          },
        ],
      },
    ],
  },
}

export const WithDescription = Template.bind({})
WithDescription.args = {
  title: 'VORM ontwikkelt jouw toekomst!',
  overlayColor: 'rgba(7,32,50,0.15)',
  text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque eget pharetra felis, ac hendrerit nulla. Vivamus iaculis nec leo eu consectetur. Cras eget fringilla diam, non fringilla ligula. `,
  link: {
    href: '#',
    linkText: 'Lorem ipsum',
  },
  search: mocksearch,
  headerOptions: [
    {
      link: {
        href: '#',
        linkText: 'Slimme toekomst',
      },
    },
    {
      link: {
        href: '#',
        linkText: 'Duurzame toekomst',
      },
    },
    {
      link: {
        href: '#',
        linkText: 'Betaalbare toekomst',
      },
    },
    {
      link: {
        href: '#',
        linkText: 'Leefbare toekomst',
      },
    },
  ],
  showHeaderOptions: true,
  image: {
    position: 'center center',
    src: image,
  },
  mainMenu: [
    {
      to: '/projecten',
      text: 'projecten',
    },
    {
      to: '/aanbod',
      text: 'aanbod',
    },
    {
      to: '/actueel',
      text: 'actueel',
    },
    {
      to: '/over-vorm',
      text: 'over vorm',
    },
    {
      to: '/contact',
      text: 'contact',
    },
  ],
  leftMenuContent: [
    {
      title: 'Aanbod',
      href: '#',
      items: [
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
          children: [
            {
              linkText: 'Child link',
              href: '#',
            },
          ],
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
      ],
    },
    {
      title: 'Aanbod',
      href: '#',
      items: [
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
          children: [
            {
              linkText: 'Child link',
              href: '#',
            },
          ],
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
      ],
    },
    {
      title: 'Aanbod',
      href: '#',
      items: [
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
          children: [
            {
              linkText: 'Child link',
              href: '#',
            },
          ],
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
      ],
    },
    {
      title: 'Aanbod',
      href: '#',
      items: [
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
          children: [
            {
              linkText: 'Child link',
              href: '#',
            },
          ],
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
      ],
    },
    {
      title: 'Aanbod',
      href: '#',
      items: [
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
          children: [
            {
              linkText: 'Child link',
              href: '#',
            },
          ],
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
        {
          linkText: 'Aanbod particulier',
          href: '#',
        },
      ],
    },
  ],
  rightMenuContent: {
    title: 'Over VORM ',
    items: [
      {
        linkText: 'Aanbod particulier',
        href: '#',
      },
      {
        linkText: 'Aanbod particulier',
        href: '#',
      },
      {
        linkText: 'Aanbod particulier',
        href: '#',
        children: [
          {
            linkText: 'Child link',
            href: '#',
          },
          {
            linkText: 'Child link',
            href: '#',
          },
          {
            linkText: 'Child link',
            href: '#',
          },
          {
            linkText: 'Child link',
            href: '#',
          },
          {
            linkText: 'Child link',
            href: '#',
          },
        ],
      },
      {
        linkText: 'Aanbod particulier',
        href: '#',
      },
      {
        linkText: 'Aanbod particulier',
        href: '#',
      },
      {
        linkText: 'Aanbod particulier',
        href: '#',
        children: [
          {
            linkText: 'Child link',
            href: '#',
          },
          {
            linkText: 'Child link',
            href: '#',
          },
          {
            linkText: 'Child link',
            href: '#',
          },
          {
            linkText: 'Child link',
            href: '#',
          },
          {
            linkText: 'Child link',
            href: '#',
          },
          {
            linkText: 'Child link',
            href: '#',
          },
          {
            linkText: 'Child link',
            href: '#',
          },
          {
            linkText: 'Child link',
            href: '#',
          },
        ],
      },
    ],
  },
}

export const WithVimeo = Template.bind({})
WithVimeo.args = {
  ...Primary.args,
  video: {
    id: 115783408,
  },
}

export const WithSearch = Template.bind({})
WithSearch.args = {
  ...Primary.args,
  isSearch: true,
}

export const NoMedia = Template.bind({})
NoMedia.args = {
  ...Primary.args,
  noMedia: true,
}
