const menuLinks = [
  {
    to: '/projecten',
    text: 'projecten',
  },
  {
    to: '/aanbod',
    text: 'aanbod',
  },
  {
    to: '/actueel',
    text: 'actueel',
  },
  {
    to: '/over-vorm',
    text: 'over vorm',
  },
  {
    to: '/contact',
    text: 'contact',
  },
]

export default menuLinks
