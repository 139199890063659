import React from 'react'
import styled from 'styled-components'
// import { useHistory } from 'react-router-dom'
import { BuildLinkWrapper } from '../elements/linkBuilder'

// Core
import Header from '../sections/header'
import Footer from '../sections/footer'
import SectionWrapper from '../modules/sectionBackgroundWrapper'
import useApi from '../../APIs/useApi'

import { ReactComponent as ArrowRight } from '../../assets/svg/arrow_right.svg'

// Styling
const PrimaryButton = styled.div`
  background: #005496;
  display: inline-block;
  padding: 0px 32px;
  font-size: 1rem;
  color: #fff;
  font-weight: 400;
  max-width: 300px;
  height: 60px;
  line-height: 63px;
  margin-top: 22px;

  svg {
    float: right;
    position: relative;
    top: 8px;
  }

  .aArrowRight {
    stroke: #fff !important;
  }

  a {
    color: #fff !important;
  }

  cursor: pointer;
`

const SecondaryButton = styled.div`
  display: inline-block;
  padding: 0;
  font-size: 1rem;
  color: #005496;
  font-weight: 400;
  max-width: 300px;
  height: 60px;
  line-height: 63px;
  margin-top: 22px;

  svg {
    float: right;
    position: relative;
    top: 8px;
  }

  cursor: pointer;
`

// 404 Page
const ErrorPage = () => {
  // Routing
  // const history = useHistory()

  // State
  const { data, isLoading } = useApi('/api/')
  const sections = data?.data?.body || []

  const headerSection = sections.filter(
    (section) => section.sectionType === 'Header'
  )[0]
  const footerSection = sections.filter(
    (section) => section.sectionType === 'Footer'
  )[0]

  if (isLoading) {
    return <div></div>
  }

  return (
    <>
      <Header {...headerSection} errorPage />
      <SectionWrapper
        notFullWidth
        type="clearBackground"
        titleType="tintedBlockH3"
        title="Helaas konden we de pagina die u zoekt niet vinden."
        paddingSize="m"
      >
        <div>
          <PrimaryButton>
            <BuildLinkWrapper link={{ href: '/aanbod' }}>
              <span> Bekijk ons aanbod </span> <ArrowRight />{' '}
            </BuildLinkWrapper>
          </PrimaryButton>
        </div>
        <div>
          <SecondaryButton>
            <BuildLinkWrapper link={{ href: '/' }}>
              <span> Terug naar homepagina </span> <ArrowRight />
            </BuildLinkWrapper>
          </SecondaryButton>
        </div>
      </SectionWrapper>
      <Footer {...footerSection} />
    </>
  )
}
export default ErrorPage
