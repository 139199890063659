import React from 'react'
import HeadlineCard from '../../modules/headlineCard/headlineCard'
import HeadlineImage from '../../modules/headlineCard/headlineImage'
import SectionWrapper from '../../../core/modules/sectionBackgroundWrapper'

const defaultSectionData = {
  type: 'blueDotsRightToLeft',
  paddingType: 'clearBlueBackground',
  notFullWidth: true,
}

const HeadlineBlockComponent = ({
  // hasBlueDots,
  headline,
  subHeadlines,
  sectionData,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
}) => {
  const newProps = { ...defaultSectionData, ...sectionData }

  return (
    <SectionWrapper
      {...newProps}
      topQuote={topQuote}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
    >
      <HeadlineImage />
      <HeadlineCard headline={headline} type="large" />

      {subHeadlines &&
        subHeadlines.map &&
        subHeadlines.map((item) => {
          return (
            <HeadlineCard
              siblings={subHeadlines?.length}
              headline={item}
              type="small"
              key={item.title}
            />
          )
        })}
    </SectionWrapper>
  )
}
HeadlineBlockComponent.defaultProps = {}

export default HeadlineBlockComponent
