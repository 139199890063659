import React from 'react'
import styled from 'styled-components'

// import { PageWrapper } from '../../elements/pageContainer'

// Core
import { P } from '../../elements/typography'

// Module styling
// const BLOCK_WIDTH = WIDTH / 2
const BigMediaTextBlock = styled.div`
  .mediaCol {
    width: 50%;
    position: relative;
    display: inline-block;
    vertical-align: top;

    p {
      padding: 2rem 8vw 2rem 0;
      white-space: pre-line;
    }

    &.goFullScreen {
      width: 100%;

      p {
        padding: 2rem 0;
      }
    }
  }

  @media screen and (max-width: 900px) {
    .mediaCol {
      width: 100%;

      p {
        padding: 2rem 0;
      }
    }
  }
`

const BigMediaTextModule = ({ text1, text2 }) => {
  const goFullScreen = !text1 || !text2

  return (
    <BigMediaTextBlock>
      {text1 && (
        <div className={`mediaCol ${goFullScreen ? 'goFullScreen' : ''}`}>
          <P text={text1}></P>
        </div>
      )}
      {text2 && (
        <div className={`mediaCol ${goFullScreen ? 'goFullScreen' : ''}`}>
          <P text={text2}></P>
        </div>
      )}
    </BigMediaTextBlock>
  )
}
BigMediaTextModule.defaultProps = {}
export default BigMediaTextModule
