import React from 'react'
import styled from 'styled-components'
// import { useHistory } from 'react-router-dom'

// Core
import { RecentProjectsCardH3, P } from '../../elements/typography'
import BuildImage from '../../elements/imageBuilder'
import { ReactComponent as Arrow } from '../../../assets/svg/ic_button_arrow.svg'
import { BuildLinkWrapper } from '../../elements/linkBuilder'

// Card Wrap
const Card = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  img {
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .information {
    position: absolute;
    top: 0;
    left: 0;
    padding: 22px;
    color: #fff !important;
    width: 100%;

    h3 {
      color: #fff !important;
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 80px);
    }

    svg {
      overflow: visible;
      position: absolute;
      right: 22px;

      g circle,
      g path,
      g line {
        stroke: #fff;
      }
    }

    a {
      color: #fff !important;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    span {
      display: block;
      font-size: 1rem;
    }

    p {
      padding: 2rem 0;
      color: #fff !important;
      opacity: 0;
    }

    p p {
      padding: 0 !important;
      margin: 0 !important;
    }
  }

  span {
    font-size: 0.8rem;
  }

  .contentArea {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    transition-delay: 0.2s;

    color: #fff;

    p {
      color: #fff;
    }
  }

  .backgroundElement {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background: rgba(0, 84, 150, 0.8);
    transition: height 0.4s ease-in-out;
  }

  .backgroundElement2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 340px;
    background: linear-gradient(
      180deg,
      rgba(0, 84, 150, 0.8) 0%,
      rgba(0, 84, 150, 0.5942752100840336) 34%,
      rgba(0, 84, 150, 0) 64%
    );
  }

  &:hover {
    .backgroundElement {
      height: 100%;
    }

    .contentArea {
      opacity: 1;

      p {
        opacity: 1;
      }
    }
  }
  p p {
    padding: 0 !important;
    margin: 0 !important;
  }
`

// Card variants
const Large = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  height: calc(700px + 20px);
  min-height: 620px;

  @media screen and (max-width: 800px) {
    min-height: 260px;
    height: 260px;
  }

  p p {
    padding: 0 !important;
    margin: 0 !important;
  }
`

const Medium = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  height: 350px;
  min-height: 260px;

  p p {
    padding: 0 !important;
    margin: 0 !important;
  }
`

const Small = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  height: 350px;
  min-height: 260px;

  > div {
    .information {
      svg {
        right: 22px;
      }
    }
  }

  img {
    max-width: 900px;
  }

  p p {
    padding: 0 !important;
    margin: 0 !important;
  }
`

// Map type to element
const map = {
  small: Small,
  medium: Medium,
  large: Large,
}

const widthMap = {
  small: '400',
  medium: '800',
  large: '800',
}

// Element
const CardElement = ({ title, subtitle, link, image, type, text }) => {
  const Wrapper = map[type]
  const width = widthMap[type]
  // const history = useHistory()

  // const handleClick = useCallback(() => {
  //   history.push(link.href)
  // }, [history, link])

  return (
    <Wrapper>
      <BuildLinkWrapper link={link}>
        <Card>
          <BuildImage image={image} width={width} />
          <div className="backgroundElement2"></div>
          <div className="backgroundElement"> </div>
          <div className="information">
            <RecentProjectsCardH3> {title} </RecentProjectsCardH3>
            <Arrow />
            <span>{subtitle}</span>

            <div className="contentArea">
              <P text={text} />
            </div>
          </div>
        </Card>
      </BuildLinkWrapper>
    </Wrapper>
  )
}
CardElement.defaultProps = {
  type: 'small',
}
export default CardElement
