import React from 'react'
import styled from 'styled-components'
import BuildLinkElement from '../../elements/linkBuilder'
import BuildImage from '../../elements/imageBuilder'
// import { useHistory } from 'react-router-dom'
import truncateString from '../../utils/truncateString'
import { PodcastsIcons } from '../podcastDetails'
// placeholder image
// import image from '../../../assets/media/building.png'

import { RecentProjectsCardH3, P } from '../../elements/typography'
import { BuildLinkWrapper } from '../../elements/linkBuilder'

// Styling
const CardWrapper = styled.div`
  width: 420px;
  min-height: 500px;

  ${(props) =>
    props.listView
      ? `display: inline-block !important; vertical-align: top; padding: 0 1vw 1vw 0;`
      : ``}

  .image-wrapper {
    width: 100%;
    height: 260px;

    ${(props) => (props.portraitImage ? 'height: 500px;' : '')}
    overflow: hidden;
    margin-bottom: 20px;
    cursor: pointer;

    img {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: center center;

      ${props => props.imagePosition ? `
        
        object-position: ${props.imagePosition};
      ` : ''}
    }
  }

  p {
    padding: 12px 0 18px 0;
  }

  ${(props) =>
    !props.showMoreCardInformation
      ? `
    h3 {
      overflow: hidden;
      height: 28px;
    }

    p {
      overflow: hidden;
      height: 60px;
      padding: 0;
      margin: 10px 0;
    }
  `
      : `
    h3 {
      height: 58px;
      overflow: hidden;
      white-space: initial;
    }

    p {
      overflow: hidden;
      height: 114px;
      padding: 0;
      margin: 10px 0;
    }
  `}

  span {
    a {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 420px) {
    width: 95%;
  }

  p p {
    padding: 0 !important;
    margin: 0 !important;
  }
`

const SmallCardWrapper = styled.div`
  width: 260px;
  min-height: 420px;

  ${(props) =>
    props.listView
      ? `display: inline-block !important; padding: 0 1vw 1vw 0;`
      : ``}

  .image-wrapper {
    width: 100%;
    height: 180px;
    ${(props) => (props.portraitImage ? 'height: 380px;' : '')}
    overflow: hidden;
    margin-bottom: 16px;
    cursor: pointer;

    img {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: center center;

      ${props => props.imagePosition ? `
        
        object-position: ${props.imagePosition};
      ` : ''}
    }
  }

  p {
    padding: 22px 0 12px 0;
  }

  ${(props) =>
    !props.showMoreCardInformation
      ? `
    h3 {
      font-size: 1.2rem;
      overflow: hidden;
      height: 26px;
    }

    p {
      overflow: hidden;
      height: 60px;
      margin-bottom: 10px;
      margin-top: 10px;
      padding-top: 0;
      padding-bottom: 0;
      box-sizing: border-box;
    }
  `
      : `
    h3 {
      font-size: 1.2rem;
      height: 50px;
      overflow: hidden;
      white-space: initial;
    }

    p {
      overflow: hidden;
      height: 114px;
      padding: 0;
      margin: 10px 0;
    }
  `}

  span {
    a {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 420px) {
    width: 95%;
  }

  p p {
    padding: 0 !important;
    margin: 0 !important;
  }
`

const sizeMap = {
  big: CardWrapper,
  large: CardWrapper,
  small: SmallCardWrapper,
}

// Module
const CardModule = ({
  image,
  title,
  desc,
  link,
  variant = 'big',
  listView,
  showMoreCardInformation,
  portraitImage,
  imagePosition,
  podcastLinks
}) => {
  // const history = useHistory()
  const Variant = sizeMap[variant]

  if (!Variant) {
    return null
  }

  return (
    <Variant
      showMoreCardInformation={showMoreCardInformation}
      listView={listView}
      portraitImage={portraitImage}
      imagePosition={imagePosition}
    >
      <div className="image-wrapper">
        <BuildLinkWrapper link={link}>
          <BuildImage
            image={image}
            width={420}
            imagePosition={imagePosition}
            height={portraitImage ? 500 : 260}
          />
        </BuildLinkWrapper>
      </div>
      <RecentProjectsCardH3>
        <BuildLinkWrapper link={link}>{title} </BuildLinkWrapper>
      </RecentProjectsCardH3>
      {podcastLinks && <PodcastsIcons showText compact links={podcastLinks}/>}
      <P text={truncateString(desc, 248)} />
      <BuildLinkElement link={link} linkStyle="ReadMoreButton" showArrow />
    </Variant>
  )
}
CardModule.defaultProps = {}
export default CardModule
