import React from 'react'
import styled from 'styled-components'
// Core
import {
  RecentProjectsCardH3,
  TintedBlockH3,
  P,
} from '../../elements/typography'

// Card Wrap
const Card = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 2rem;
  display: inline-block;
  vertical-align: top;

  h3 {
    font-size: 1.25rem;
    ${(props) => (props.type === 'large' ? 'font-size: 2.6rem;' : '')}
  }

  @media screen and (max-width: 750px) {
    padding: 2rem 0;
  }

  &:first-child {
    padding-left: 0;
  }
`

// Card variants
const Large = styled.div`
  display: inline-block !important;
  vertical-align: top;

  ${(props) => (!props.mediaType ? 'max-width: 800px;' : 'width: 50%;')}

  overflow: hidden;

  @media screen and (max-width: 1000px) {
    width: 100%;
    display: block !important;
  }
`

const Small = styled.div`
  display: inline-block !important;
  vertical-align: top;
  height: 100%;
  overflow: hidden;

  ${(props) => (props.siblingsLength === 3 ? 'width: 33%;' : '')}
  ${(props) => (props.siblingsLength === 2 ? 'width: 50%;' : '')}
  ${(props) => (props.siblingsLength === 1 ? 'width: 100%;' : '')}

  @media screen and (max-width: 750px) {
    width: 100%;
    display: block !important;
  }
`

// Map type to element
const map = {
  small: Small,
  large: Large,
}

// Element
const CardElement = ({
  headline = {},
  type = 'large',
  siblings,
  mediaType,
}) => {
  const Wrapper = map[type]
  let { title, desc } = headline

  if (!Wrapper) {
    return null
  }

  return (
    <Wrapper siblingsLength={siblings} mediaType={mediaType}>
      <Card type={type}>
        <div style={{ minHeight: '40px' }}>
          {type === 'large' ? (
            <TintedBlockH3>{title}</TintedBlockH3>
          ) : (
            <RecentProjectsCardH3> {title} </RecentProjectsCardH3>
          )}
        </div>
        <P text={desc} />
      </Card>
    </Wrapper>
  )
}

CardElement.defaultProps = {
  type: 'small',
}

export default CardElement
