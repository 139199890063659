import React from 'react'
import styled from 'styled-components'
import BuildImage from '../../../../elements/imageBuilder'
import truncateString from '../../../../utils/truncateString'

import { SmallArticleHeader, P } from '../../../../elements/typography'
import { BuildLinkWrapper } from '../../../../elements/linkBuilder'

const CardWrapper = styled.div`
  display: inline-block !important;
  vertical-align: top;

  width: 50%;

  .image-wrapper {
    display: inline-block !important;
    vertical-align: top;
    width: 30%;
    height: 112px;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      min-height: 100%;
      aspect-ratio: 1;
      object-fit: cover;
      object-position: center center;

      ${props => props.imagePosition ? `
        
        object-position: ${props.imagePosition};
      ` : ''}
    }
  }

  .contentWrapper {
    padding: 0 1rem;
    width: 70%;
    display: inline-block !important;
    vertical-align: top;
    p {
      padding: 0;
    }
    .truncate {
      p > span {
        font-size: 16px;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.25rem;
      }
    }
    .subheader {
      height: 28px;
      overflow: hidden;
      span {
        font-size: 12px;
        line-height: 1.25rem;
      }
    }

    span {
      a {
        font-size: 1rem;
      }
    }
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    .image-wrapper {
      width: 100%;
      height: unset;
      img {
        aspect-ratio: 1.5;
      }
    }
    .contentWrapper {
      width: 100%;
      padding: 1rem 0 1.5rem;
    }
  }
`

// Module
const SmallArticleCard = ({ image, title, desc, link, category, date, imagePosition }) => {
  let subheader = `${category}${date ? ` • ${date}` : ''}`.toUpperCase()
  return (
    <CardWrapper imagePosition={imagePosition}>
      <BuildLinkWrapper link={link}>
        <div className="image-wrapper">
          <BuildImage image={image} width={140} height={140} />
        </div>
        <div className="contentWrapper">
          <SmallArticleHeader>{title}</SmallArticleHeader>
          <div className="subheader">
            <P text={subheader} />
          </div>
          <span className="truncate">
            <P text={truncateString(desc, 248)} />
          </span>
        </div>
      </BuildLinkWrapper>
    </CardWrapper>
  )
}

export default SmallArticleCard
