import React from 'react'
import styled from 'styled-components'

// Core
import { PeopleCardTitle } from '../../elements/typography'
import BuildImage from '../../elements/imageBuilder'

// Card Wrap
const Card = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  max-height: 270px;
  height: 300px;
  overflow: hidden;

  ${(props) => (props.portraitImage ? 'max-height:500px; height: 500px;' : '')}

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-height: 270px;
  }

  .information {
    background-color: rgba(0, 84, 150, 0.8);
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 22px;
    color: #fff !important;
    width: 100%;
    box-sizing: border-box;
    min-height: ${(props) => (props.noline ? '65px' : '90px')};

    h3 {
      color: #fff !important;
      padding: 0;
      margin: 0;
    }

    a {
      color: #fff !important;
      text-decoration: none;
    }

    span {
      font-size: 0.8rem;
    }
  }

  @media screen and (max-width: 1000px) {
    .information {
      padding: 22px;
    }
  }
`

const Large = styled.div`
  width: 100%;
  height: 100%;
  display: inline-block !important;
  padding: 1vw;
`

const Half = styled.div`
  width: 50%;
  height: 50%;
  display: inline-block !important;
  padding: 1vw;

  @media screen and (max-width: 1000px) {
    width: 50%;
    height: 50%;
  }

  @media screen and (max-width: 680px) {
    width: 100%;
    height: 100%;
    padding: 1vw 0;
  }

  ${(props) => (props.portraitImage ? 'max-height:550px; height: 550px;' : '')}
`

const Quarter = styled.div`
  width: 25%;
  height: 25%;
  display: inline-block !important;
  padding: 1vw;

  ${(props) => (props.portraitImage ? 'max-height:400px; height: 400px;' : '')}

  @media screen and (max-width: 1000px) {
    width: 50%;
    height: 50%;
  }

  @media screen and (max-width: 680px) {
    width: 100%;
    height: 100%;
    padding: 1vw 0;
  }
`

const map = {
  quarter: Quarter,
  half: Half,
  large: Large,
}

// Element
const CardElement = ({
  person = {},
  type = 'large',
  noline,
  portraitImage,
}) => {
  const Wrapper = map[type]

  let { image, name } = person

  if (!Wrapper) {
    return null
  }

  return (
    <Wrapper portraitImage={portraitImage} noline={noline}>
      <Card portraitImage={portraitImage}>
        <BuildImage
          image={image}
          width={type === 'large' ? 800 : 400}
          height={portraitImage ? 500 : 370}
        />

        <div className="information">
          <PeopleCardTitle> {name} </PeopleCardTitle>
        </div>
      </Card>
    </Wrapper>
  )
}
CardElement.defaultProps = {}
export default CardElement
