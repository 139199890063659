import React from 'react'
import styled from 'styled-components'

// Core
import {
  P,
  RecentProjectsCardH3,
  Characteristic,
} from '../../elements/typography'
import BuildLinkElement from '../../elements/linkBuilder'

// Module styling
const ProjectCharacteristicsWrapper = styled.div`
  padding: 2rem 0;

  .leftWrapper {
    width: 30%;
    display: inline-block;
    min-width: 260px;
  }

  .rightWrapper {
    display: inline-block;
    max-width: 70%;
    p {
      padding: 0 8vw;
      min-width: 450px;
    }
  }

  .rightWrapper,
  .leftWrapper {
    vertical-align: top;
    padding: 2rem 0;
  }

  a {
    color: black;
  }
  svg g {
    path,
    line {
      stroke: black;
    }
  }

  @media screen and (max-width: 1000px) {
    .rightWrapper,
    .leftWrapper {
      width: 100%;
      max-width: 100%;

      p {
        padding: 0 0vw;
        min-width: 10px;
      }
    }
  }
`

const ProjectCharacteristicsBlock = ({
  title,
  desc,
  location,
  state,
  price,
  rooms,
  surface,
  registerLink,
}) => {
  return (
    <ProjectCharacteristicsWrapper>
      <RecentProjectsCardH3> {title} </RecentProjectsCardH3>
      <div className="leftWrapper">
        <Characteristic>{location}</Characteristic>
        <Characteristic>{state}</Characteristic>
        <Characteristic>{price}</Characteristic>
        <Characteristic>{rooms}</Characteristic>
        <Characteristic>{surface}</Characteristic>
      </div>
      <div className="rightWrapper">
        <P text={desc} />
      </div>
      <BuildLinkElement
        link={registerLink}
        linkStyle="ReadMoreButton"
        showArrow
      />
    </ProjectCharacteristicsWrapper>
  )
}
ProjectCharacteristicsBlock.defaultProps = {}
export default ProjectCharacteristicsBlock
