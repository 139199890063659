import React from 'react'
import { NavLink } from 'react-router-dom'

// Wrappers for the links
import {
  ReadMoreButtonWrapper,
  ViewAllLinkWrapper,
  MenuTitleWrapper,
  MenuLinkWrapper,
  MenuChildLinkWrapper,
  BreadcrumbLinkWrapper,
  BreadcrumbActiveLinkWrapper,
  CompanyLinkWrapper,
  SubFooterLinkWrapper,
} from './typography'
import { ReactComponent as ArrowRight } from '../../assets/svg/arrow_right.svg'

// So we only need to manage it in one place
// This is the text for inside the link, that gets stripped from the props.
const TEXTPROP = 'linkText'

// helper for internal links
export const buildCorrectLocalHref = (href) => {
  if (!href) {
    return ''
  }

  // Ensure an internal URL is not prefixed with the current host.
  let newUrl = href
  if (newUrl.charAt(0) !== '/') {
    newUrl = '/' + newUrl
  }

  return newUrl
}

export const buildCorrectExternalHref = (href) => {
  if (!href) {
    return '/'
  }

  let newUrl = href
  if (
    newUrl.startsWith &&
    typeof newUrl.startsWith === 'function' &&
    !newUrl.startsWith('http')
  ) {
    newUrl = 'https://' + newUrl
  }

  return newUrl
}

// Internal wrapper to help make none link elements clickable, such as images and titles.
export const BuildLinkWrapper = ({ link, children, key }) => {
  if (link && link.href) {
    const linkType =
      link.href.includes('http') || link.href.includes('www')
        ? 'external'
        : 'internal'

    if (linkType === 'internal') {
      const internalLinktoUse = buildCorrectLocalHref(link.href)
      return (
        <NavLink key={key} to={internalLinktoUse}>
          {children}
        </NavLink>
      )
    } else {
      return (
        <a
          key={key}
          href={buildCorrectExternalHref(link.href)}
          rel="noreferrer"
          target="_blank"
        >
          {' '}
          {children}{' '}
        </a>
      )
    }
  }

  return children
}

// Module
const BuildReactLink = ({ link = {}, linkStyle = 'default', showArrow }) => {
  // Dont render anything if there is no link being passed
  if (!link.href && !link[TEXTPROP]) {
    return null
  }

  // Get the correct link and type
  const thelink = (link && link.href) || ''
  const linkType =
    thelink.includes('http') || thelink.includes('www')
      ? 'external'
      : 'internal'

  // Begin constructing the mutable link props
  const linkProps = {
    ...link,
    href:
      linkType === 'external'
        ? buildCorrectExternalHref(link.href)
        : buildCorrectLocalHref(link.href),
  }

  delete linkProps[TEXTPROP]

  // Map internal or external link
  const mapTypes = {
    external: (
      <a target="_blank" {...linkProps}>
        {link[TEXTPROP]} {showArrow ? <ArrowRight className="scale11" /> : ''}
      </a>
    ),
    internal: (
      <NavLink to={linkProps.href}>
        {link[TEXTPROP]} {showArrow ? <ArrowRight className="scale11" /> : ''}
      </NavLink>
    ),
  }

  // Build a wrapper styling around it
  const mapWrapper = {
    default: ({ children }) => <> {children} </>,
    ReadMoreButton: ({ children }) => (
      <ReadMoreButtonWrapper>{children}</ReadMoreButtonWrapper>
    ),
    ViewAllLink: ({ children }) => (
      <ViewAllLinkWrapper>{children}</ViewAllLinkWrapper>
    ),
    MenuTitle: ({ children }) => (
      <MenuTitleWrapper>{children}</MenuTitleWrapper>
    ),
    MenuLink: ({ children }) => <MenuLinkWrapper> {children} </MenuLinkWrapper>,
    MenuChildLink: ({ children }) => (
      <MenuChildLinkWrapper> {children} </MenuChildLinkWrapper>
    ),
    CompanyLink: ({ children }) => (
      <CompanyLinkWrapper>{children}</CompanyLinkWrapper>
    ),
    Breadcrumb: ({ children }) => (
      <BreadcrumbLinkWrapper> {children} </BreadcrumbLinkWrapper>
    ),
    BreadcrumbActive: ({ children }) => (
      <BreadcrumbActiveLinkWrapper> {children} </BreadcrumbActiveLinkWrapper>
    ),
    FooterLink: ({ children }) => (
      <SubFooterLinkWrapper> {children} </SubFooterLinkWrapper>
    ),
  }

  const Wrapper = mapWrapper[linkStyle]

  if (!Wrapper) {
    return null
  }

  // Render it
  return <Wrapper>{mapTypes[linkType]}</Wrapper>
}
export default BuildReactLink
