import React from 'react'
import styled from 'styled-components'
import BuildLinkElement from '../../elements/linkBuilder'
import BuildImage from '../../elements/imageBuilder'
// import { useHistory } from 'react-router-dom'
import truncateString from '../../utils/truncateString'
import { BuildLinkWrapper } from '../../elements/linkBuilder'
import { PodcastsIcons } from '../podcastDetails'

// placeholder image
// import image from '../../../assets/media/building.png'

import { RecentProjectsCardH3, P } from '../../elements/typography'

const Card = styled.div`
  display: inline-block !important;
  width: 33%;
  max-width: 370px;
  min-width: 250px;
  min-height: 420px;
  vertical-align: top;

  padding-right: 20px;
  padding-bottom: 4.5rem;

  .image-wrapper {
    width: 100%;
    height: 270px;
    overflow: hidden;
    margin-bottom: 16px;
    cursor: pointer;

    img {
      width: 100%;
      min-height: 100%;
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: center center;

      ${props => props.imagePosition ? `
        
        object-position: ${props.imagePosition};
      ` : ''}
    }
  }

  h3 {
    font-size: 1.25rem;
    overflow: hidden;
  }

  p {
    padding: 1rem 0;
  }

  span {
    a {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 860px) {
    width: 50%;
    max-width: 50%;
    min-width: 50%;
  }

  @media screen and (max-width: 710px) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px !important;
  }

  p {
    overflow: hidden;
    height: 36px;
    margin-bottom: 14px;
  }

  ${(props) =>
    !props.showMoreCardInformation
      ? `
    h3 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      overflow: hidden;
      height: 38px;
      margin-bottom: 5px;
    }
  `
      : `
    h3 {
      height: 50px;
      overflow: hidden;
      white-space: initial;
    }

    p {
      overflow: hidden;
      height: 100px;
      margin-bottom: 5px;
    }
  `}

  .podcasts {
    position: relative;
    top: 4px;
  }
`

// Module
const CardModule = ({ image, title, desc, link, showMoreCardInformation, imagePosition, podcastLinks }) => {
  // const history = useHistory()

  return (
    <Card imagePosition={imagePosition} showMoreCardInformation={showMoreCardInformation}>
      <div className="image-wrapper">
        <BuildLinkWrapper link={link}>
          {' '}
          <BuildImage image={image} />{' '}
        </BuildLinkWrapper>
      </div>
      <RecentProjectsCardH3>
        <BuildLinkWrapper link={link}>{title}</BuildLinkWrapper>
      </RecentProjectsCardH3>
      {podcastLinks && <PodcastsIcons showText compact links={podcastLinks}/>}
      <P text={truncateString(desc, 248)} />
      <BuildLinkElement link={link} linkStyle="ReadMoreButton" showArrow />
    </Card>
  )
}
CardModule.defaultProps = {}
export default CardModule
