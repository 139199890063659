import React from 'react'

import PijlerHeadline from '../../modules/pijlerHeadline'
import background from '../../../assets/svg/pijlerbg.svg'

const PijlerHeadlineSection = (props) => {
  return <PijlerHeadline {...props} backgroundAsset={background} />
}

PijlerHeadlineSection.defaultProps = {}
export default PijlerHeadlineSection
