// Sections
import Header from './header'
import Footer from './footer'
import Blog from './blog'
import News from './news'
import RecentProjects from './recentProjects'
import RecentProjectsLongBG from './recentProjectsLongBG'
import Downloads from './downloads'
import DownloadsBlock from './downloadsBlock'
import FAQ from './faq'
import FAQWithImage from './faqWithImage'
import FAQWithBackground from './faqWithBackground'
import ExpertiseHeading from './expertisesHeading'
import Contact from './contactWithFields'
import BigMediaTextBlock from './bigMediaTextBlock'
import BigGradientMediaBlock from './bigGradientMediaBlock'
import BigBlueTextBlock from './bigBlueTextBlocks'
import BigAlternatingColorTextBlock from './bigAlternatingColorTextBlock'
import Statement from './statement'
import QuotedSlider from './quotedSlider'
import BlueBoxSlider from './blueBoxSlider'
import Properties from './properties'
import PijlerBlock from './pijlerBlock'
import PeopleBlock from './peopleBlock'
import OurPeopleBlock from './ourPeopleBlock'
import HeadlineBlock from './headlineBlock'
import FeaturedProjects from './featuredProjects'
import ExpertiseHeadline from './expertiseHeadline'
import ExpertiseHeadlineBackground from './expertiseHeadlineBackground'
import ExpertiseHeadlineRight from './expertiseHeadlineRight'
import expertiseHeadlineRightBackground from './expertiseHeadlineRightBackground'
import VimeoBlock from './vimeoBlock'
import VacancyBlock from './vacancyBlock'
import VacanciesAll from './vacanciesAll'
import Vacancies from './vacancies'
import TestimonialCardBlock from './testimonialCardBlock'
import TestimonialDetailBlock from './testimonialDetailBlock'
import ProjectDetails from './projectDetails'
import MonthlyCosts from './monthlyCosts'
import ExpertisesHeadingWithIcons from './expertisesHeadingWithIcons'
import ExpertiseBlockOnlyIcons from './expertiseBlockOnlyIcons'
import BvDetail from './bvDetail'
import AllBvs from './allBVs'
import ContactPersonBlock from './contactPersonBlock'
import ExpertiseBlockWithIcons from './expertiseBlockWithIcons'
import HeadlineWithCard from './headlineWithCard'
import HeadlineWithCards from './headlineWithCards'
import HeadlineWithCardBackground from './headlineWithCardBackground'
import HeadlineWithCardsBackground from './headlineWithCardsBackground'
import PijlerHeadline from './pijlerHeadline'
import PijlerHeadlineBackground from './pijlerHeadlineBackground'
import EmergencyContactBlock from './emergencyContactBlock'
import InAssociationWith from './inAssociationWith'
import HeadlineImageIcons from './headlineImageIcons'
import PublicationsIntro from '../sections/publicationIntro'
import PubNewsList from '../sections/pubNewsList'
import Actueel from '../sections/actueel'
import Tags from './tags'
import NineSteps from './nineSteps'
import Search from './search'
import TimelineSection from './timelineSection'
import PodcastDetails from './podcastDetails'

import SharePage from './sharePage'

// Static Sections
import CookieBot from './cookieBot'

// Complex / configurable sections
import ComplexExpertise from '../complex-sections/expertiseHeadline'
import ComplexFAQ from '../complex-sections/faq'
import ComplexCards from '../complex-sections/cards'
import ComplexHeadlineCards from '../complex-sections/headlineWithCards'
import ComplexNews from '../complex-sections/news'
import ComplexPublications from '../complex-sections/publications'
import ComplexHeadlineBlock from '../complex-sections/headlineBlock'

// Export them as an object
const Sections = {
  VimeoBlock,
  VacancyBlock,
  VacanciesAll,
  TimelineSection,
  Vacancies,
  TestimonialCardBlock,
  ExpertiseBlockOnlyIcons,
  TestimonialDetailBlock,
  ProjectDetails,
  MonthlyCosts,
  ExpertisesHeadingWithIcons,
  BvDetail,
  AllBvs,
  Header,
  Footer,
  Blog,
  News,
  Tags,
  FeaturedProjects,
  ExpertiseBlockWithIcons,
  HeadlineBlock,
  ExpertiseHeadline,
  ExpertiseHeadlineRight,
  ExpertiseHeadlineBackground,
  expertiseHeadlineRightBackground,
  PeopleBlock,
  OurPeopleBlock,
  Properties,
  RecentProjects,
  Downloads,
  DownloadsBlock,
  BigGradientMediaBlock,
  BigMediaTextBlock,
  BigBlueTextBlock,
  BigAlternatingColorTextBlock,
  Contact,
  FAQ,
  ExpertiseHeading,
  Statement,
  QuotedSlider,
  PijlerBlock,
  ContactPersonBlock,
  HeadlineWithCardBackground,
  HeadlineWithCardsBackground,
  HeadlineWithCard,
  HeadlineWithCards,
  RecentProjectsLongBG,
  FAQWithImage,
  FAQWithBackground,
  PijlerHeadline,
  PijlerHeadlineBackground,
  EmergencyContactBlock,
  BlueBoxSlider,
  InAssociationWith,
  HeadlineImageIcons,
  ComplexExpertise,
  ComplexCards,
  ComplexFAQ,
  ComplexHeadlineCards,
  ComplexNews,
  ComplexPublications,
  PublicationsIntro,
  PubNewsList,
  ComplexHeadlineBlock,
  Actueel,
  NineSteps,
  Search,
  CookieBot,
  PodcastDetails,
  SharePage,
}

export default Sections
