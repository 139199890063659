import React from 'react'
import styled from 'styled-components'
// import { useHistory } from 'react-router-dom'

// import img from '../../../assets/media/building.png'
import BuildImage from '../../elements/imageBuilder'
import BuildLinkElement from '../../elements/linkBuilder'

import { RecentProjectsCardH3, P } from '../../elements/typography'
import { BuildLinkWrapper } from '../../elements/linkBuilder'

const CardWrapper = styled.div`
  .imageContainer {
    height: 140px;
    width: 170px;
    display: inline-block;
    overflow: hidden;
    margin-bottom: 12px;
    cursor: pointer;

    img {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: center center;

      ${props => props.imagePosition ? `
        
        object-position: ${props.imagePosition};
      ` : ''}
    }
  }

  .newsCardContent,
  .imageContainer {
    display: inline-block;
    vertical-align: top;
  }

  .newsCardContent {
    width: calc(100% - 190px);
    padding-left: 20px;
  }

  p {
    padding-bottom: 12px;
  }

  .newsMeta {
    color: #005496;
    opacity: 1;
    font-size: 0.8rem;
  }

  strong {
    font-weight: 400;
  }

  @media screen and (max-width: 580px) {
    .newsCardContent {
      width: 100%;
      padding-left: 0;
      padding-top: 22px;
    }

    .imageContainer {
      width: 100%;
      height: auto;
      max-height: 400px;
    }
  }

  h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 8px !important;
  }

  p {
    overflow: hidden;
    height: 60px;
    margin-bottom: 8px;
  }
`

const Card = ({ image, title, text, link, meta }) => {
  // const history = useHistory()

  return (
    <CardWrapper>
      <div className="imageContainer">
        <BuildLinkWrapper link={link}>
          {' '}
          <BuildImage image={image} width={260} />{' '}
        </BuildLinkWrapper>
      </div>
      <div className="newsCardContent">
        <RecentProjectsCardH3>
          <BuildLinkWrapper link={link}> {title} </BuildLinkWrapper>
        </RecentProjectsCardH3>
        <div className="newsMeta"> {meta} </div>
        <P text={text} />
        <BuildLinkElement link={link} linkStyle="ReadMoreButton" showArrow />
      </div>
    </CardWrapper>
  )
}
Card.defaultProps = {}
export default Card
