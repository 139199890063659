import React, { useRef } from 'react'
import styled from 'styled-components'
import MetaTags from 'react-meta-tags'

import * as importedSections from '../sections'
import { PageWrapper } from '../elements/pageContainer'

// Styled components
const ElementCard = styled.div`
  border-left: 3px solid rgb(0, 84, 150);
  color: rgb(0, 84, 150);
  max-width: 160px;
  padding-left: 12px;
  margin: 6px 0;
  font-size: 0.8rem;
  text-transform: capitalize;
  opacity: 1;
  font-weight: 500;
  transition: opacity: 0.5s ease;
  height: 40px;
  line-height: 40px;
  cursor: pointer;

  > div {
    opacity: 0;
  }

  &:hover {
    > div {
      opacity: 1;
    }
  }

  &.onMedia {
    color: #fff;
    border-color: #fff;
  }

  &:hover {
    opacity: 0.8;
  }

  @media screen and (max-width: 1280px) {
    display: none !important;
  }
`
const ElementCardContainer = styled.div`
  position: fixed;
  left: 1.5vw;
  transition: opacity 0.5s ease;
  opacity: 0;

  @media screen and (max-width: 1280px) {
    display: none !important;
  }

  ${(props) => (props.isVisible ? 'opacity: 1;' : '')}
`

// Component - ElementCardComponent
// This is a single navigation item
// We split the items so each component can check for itself if it's over a media item or not.
const ElementCardComponent = ({ id, isActive, handleClick }) => {
  return (
    <ElementCard
      onClick={() => handleClick(id)}
      className={`${isActive ? 'onMedia' : ''}`}
    >
      <div>{id}</div>
    </ElementCard>
  )
}

// The scroll module
// here we build the items for section, based off of whats rendered in the DOM.
// we also begin doing a centeralized search for all conflicting media that may be in the sections.
const PageNavigator = ({ containerRef }) => {
  // State
  const [sectionState, setSectionState] = React.useState([])
  const [mediaElements, setMediaElements] = React.useState([])
  const [activeMenuItems, setActiveMenuItems] = React.useState([])

  // Hide items by default and fade them in
  const [isVisible, setIsVisible] = React.useState(false)

  // Link element ref
  const elementRef = useRef()

  // Generic helper function to help determine if element is even in the viewport
  const checkIfElementIsInView = (boundRect) => {
    return (
      boundRect.y <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      boundRect.bottom >= 0
    )
  }

  // handle when a menu item is clicked
  const handleClick = (id) => {
    const elements = [...containerRef.current.children]
    elements.forEach((section) => {
      if (section.id === id) {
        section.scrollIntoView({ behavior: 'smooth' })
      }
    })
  }

  // generic check and perform function
  // useScroll uses this, but also so should the onmount
  const checkUpdateDomScrollStatus = () => {
    const media = mediaElements

    // Buffers / or Buckets allow us to track navigation items across multiple sections.
    // This limits us to at most 3 sections can be rendered on screen at any one time.
    let buffer1 = []
    let buffer2 = []
    let buffer3 = []

    // cycle through each media element on scroll:
    if (media && media?.length > 0 && elementRef?.current?.children) {
      media.forEach((mediaItem) => {
        const boundRect = mediaItem.getBoundingClientRect()

        // Find out if the media element is even in view:
        // To save on calculations we only process media in view.
        const isInView = checkIfElementIsInView(boundRect)
        const hasChildButtons = [...elementRef.current.children]

        if (isInView && hasChildButtons && hasChildButtons?.length > 0) {
          const innerThreshold = boundRect.top
          const outerThreshold = boundRect.bottom

          hasChildButtons.forEach((button) => {
            const { top, bottom } = button.getBoundingClientRect()

            if (bottom >= innerThreshold && top <= outerThreshold) {
              const elementId = button.querySelector('div').innerHTML
              if (buffer1.indexOf(elementId) === -1) {
                buffer1.push(elementId)
              } else if (buffer2.indexOf(elementId) === -1) {
                buffer2.push(elementId)
              } else {
                buffer3.push(elementId)
              }
            }
          })

          // Set active buttons
          setActiveMenuItems([...buffer1, ...buffer2, ...buffer3])

          // Show items
          if (!isVisible) {
            setIsVisible(true)
          }
        }
      })
    }
  }

  // OnScroll method - update the dom as well.
  const handleOnScroll = () => {
    checkUpdateDomScrollStatus()
  }

  // Scroll Detection
  // This will process the scroll events and see if our buttons are over elements.
  let initTimeouts
  React.useEffect(() => {
    // Initial mount check
    clearTimeout(initTimeouts)
    initTimeouts = setTimeout(() => {
      checkUpdateDomScrollStatus()
    }, 300)

    // Mount add listener
    window.removeEventListener('scroll', handleOnScroll)
    window.addEventListener('scroll', handleOnScroll)

    // Unmount listener
    return () => {
      window.removeEventListener('scroll', handleOnScroll)
    }
  }, [mediaElements])

  // Cleanup
  React.useEffect(() => {
    // Unmount listener
    return () => {
      window.removeEventListener('scroll', handleOnScroll)
    }
  }, [])

  // Keep track of all the media elements that could affect our scroller
  // If the DOM updates we reprocess this update section list.
  let initTimeout = null
  React.useEffect(() => {
    clearTimeout(initTimeout)
    initTimeout = setTimeout(() => {
      // Keep track of a complete list of media elements that conflict.
      let completeMediaArray = []

      // Keep track of all the IDs for each section (for nav).
      const sectionsArray = []

      if (!containerRef?.current?.children) {
        return
      }

      // Process all sections looking for conflicting elements.
      const elements = [...containerRef.current.children]
      elements.forEach((section) => {
        // Grab ids to build the section selectors
        if (section && section.id) {
          sectionsArray.push(section.id)
        }

        // Grab the media
        const mediaImages = section.querySelectorAll('img')
        const mediaBackgrounds = section.querySelectorAll('.mediaElement')

        // merge images and media background elements into a singular DOM collection
        const tempMedia = [...mediaImages, ...mediaBackgrounds]
        // We have a 120 safe space, so if the media isn't within that space we don't need to worry about it.
        const media = tempMedia.filter((item) => {
          return item.getBoundingClientRect().x < 120
        })

        completeMediaArray = [...completeMediaArray, ...media]
      })

      // Set the state - cache it so it saves process time.
      setMediaElements(completeMediaArray)
      setSectionState(sectionsArray)
    }, 1000)
  }, [containerRef])

  return (
    <ElementCardContainer
      isVisible={isVisible}
      ref={elementRef}
      style={{
        top: `50%`,
        marginTop: `-${
          elementRef.current && elementRef.current.offsetHeight / 2
        }px`,
      }}
    >
      {sectionState &&
        sectionState.map &&
        sectionState.map((section, index) => {
          if (index === sectionState.length - 1) {
            return null
          }
          return (
            <ElementCardComponent
              id={section}
              key={`page-builder-section-${section}`}
              isActive={activeMenuItems.indexOf(section) >= 0}
              handleClick={handleClick}
            />
          )
        })}
    </ElementCardContainer>
  )
}

const RegularWrapper = ({ children }) => <> {children} </>
const SmallWrapper = ({ children }) => <PageWrapper> {children} </PageWrapper>

// Build meta data for the page
// {
//   type: 'title' || 'meta' || 'og:xxxx',
//   name: '', // only used if it's a meta type
//   content: '' // content of the meta tag
// }
const buildMetaItems = (data) => {
  return data.map((item, index) => {
    const types = {
      title: <title key={`meta-item-${index}`}> {item.content} </title>,
      meta: (
        <meta
          key={`meta-item-${index}`}
          name={item.name}
          content={item.content}
        />
      ),
      og: (
        <meta
          key={`meta-item-${index}`}
          property={item.name}
          content={item.content}
        />
      ),
    }

    return types[item.type]
  })
}
const MetaWrapper = ({ data }) => {
  return <MetaTags>{buildMetaItems(data)}</MetaTags>
}

// This wraps the page builder and the page navigator
const PageBuilderWrapper = ({
  sections,
  goSmall,
  head,
  search,
  hideNavigator,
  breadcrumbs,
  isHome,
}) => {
  // This allows us to get the correct dom back
  // Similar to an update to date querySelector
  const pageReference = useRef()
  const Wrapper = !goSmall ? RegularWrapper : SmallWrapper

  const scrollToSection = (indexToScrollToo) => {
    const ele = [...pageReference.current.children]
    if (ele) {
      ele.forEach((section, index) => {
        if (index === indexToScrollToo) {
          section.scrollIntoView({ behavior: 'smooth' })
        }
      })
    }
  }

  return (
    <Wrapper>
      {head && head?.length > 0 && <MetaWrapper data={head} />}
      <div ref={pageReference}>
        {sections &&
          sections.map &&
          sections.map((sectionData, index) => {
            const SectionElement =
              importedSections.default[sectionData.sectionType]

            return (
              <>
                <section
                  id={sectionData.sectionLabel || sectionData.id}
                  key={`page-builder-wrapper-${sectionData.id}`}
                >
                  <SectionElement
                    {...sectionData}
                    search={search}
                    breadcrumbs={
                      !isHome && index === 1 && breadcrumbs ? breadcrumbs : null
                    }
                    goToFirstSection={() => scrollToSection(1)}
                    hasBreadcrumbs={!isHome && index === 1 && breadcrumbs}
                    goToNextSection={() => {
                      scrollToSection(index)
                    }}
                  />
                </section>
              </>
            )
          })}

        {hideNavigator !== true && (
          <PageNavigator containerRef={pageReference} />
        )}
      </div>
    </Wrapper>
  )
}
PageBuilderWrapper.defaultProps = {}
export default PageBuilderWrapper
