import React from 'react'
import styled from 'styled-components'
// import { useHistory } from 'react-router-dom'

// Core
import { PeopleCardTitle } from '../../elements/typography'
import BuildImage from '../../elements/imageBuilder'
import { ReactComponent as ArrowRight } from '../../../assets/svg/arrow_right.svg'
import { BuildLinkWrapper } from '../../elements/linkBuilder'

// Card Wrap
const Card = styled.div`
  width: 100%;
  max-width: 320px;
  aspect-ratio: 1.5;
  position: relative;
  margin: 0 auto 1rem auto;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    object-position: center center;
  }

  .information {
    background-color: rgba(0, 84, 150, 0.8);
    position: absolute;
    bottom: 0;
    left: 0;
    padding-left: 1.25rem;
    color: #fff !important;
    width: 100%;
    box-sizing: border-box;
    min-height: 125px;
    ${(props) =>
      props.isMiniture
        ? `
      height: 64px;
      min-height: 64px;
      padding: 12px 12px;
    `
        : ''}

    h3 {
      color: #fff !important;
      display: inline-block;
      vertical-align: middle;
      padding-bottom: 0;
    }

    svg {
      display: inline-block;
      vertical-align: middle;
      width: 2.5rem;
      margin-top: -1px;

      g path,
      g line {
        stroke: #fff;
      }
    }

    span {
      font-size: 0.8rem;
    }
  }
`

// Element
const CardElement = ({ company, isMiniture }) => {
  let { image, link } = company

  // const history = useHistory()
  // const handleLink = useCallback(() => {
  //   const newlink = link?.href || '/'
  //   history.push(newlink)
  // }, [history, link])

  return (
    <Card isMiniture={isMiniture}>
      <BuildImage image={image} />

      <div className="information">
        <BuildLinkWrapper link={link}>
          <PeopleCardTitle>{link?.linkText}</PeopleCardTitle>
          <ArrowRight />
        </BuildLinkWrapper>
      </div>
    </Card>
  )
}
CardElement.defaultProps = {}
export default CardElement
