import React, { useEffect } from 'react'
import styled from 'styled-components'

import BuildLinkElement from '../../elements/linkBuilder'
import PaddingWrapper from '../../elements/paddingContainer'
import useResize from '../../utils/useResize'

import QuoteElement from '../../elements/quote'
import Breadcrumbs from '../../modules/breadcrumbs'

// Core
import {
  RecentProjectTitle,
  TintedBlockH3,
  SubHeader,
} from '../../elements/typography'

import { PageWrapper } from '../../elements/pageContainer'

// Media
import bg_ul from '../../../assets/svg/bluedots_upperleft.svg'
import bg_ur from '../../../assets/svg/bluedots_upperright.svg'
import bg_ll from '../../../assets/svg/bluedots_lowerleft.svg'
import bg_lowr from '../../../assets/svg/bluedots_lowerright.svg'
import bg_rl from '../../../assets/svg/bluedots_right-to-left.svg'
import bg_lr from '../../../assets/svg/bluedots_left-to-right.svg'
import bg_fw from '../../../assets/svg/bluedots-fullwidth.svg'
import bg_fw_lower from '../../../assets/svg/bluedots_full_lower.png'
import bg_ll_inv from '../../../assets/svg/bluedots_lowerleft_inv.svg'

// Styling
const Wrapper = styled.div`
  ${(props) => (props.hideOverflow ? '' : 'overflow-x: hidden;')}

  ${(props) => props.backgroundStyle}
  min-height: 260px;

  > div {
    .leftSection,
    .rightSection {
      display: inline-block;
      width: 50%;
      vertical-align: top;

      &.goFullWidth {
        width: 100%;
      }
    }

    .rightSection {
      text-align: right;
      padding-right: 50px;
      box-sizing: border-box;
    }

    .contentArea {
      &.notFullWidth {
        margin-right: 0 !important;
      }

      @media screen and (max-width: 1000px) {
        ${(props) =>
          props.notFullWidthMobile ? 'margin-right: 0 !important;}' : ''}
        
    }
  }
  

  .quoteHeader {
    text-align: center;
    padding: 30px 0;
  }

  .subtitle {
    padding-top: 10px;
  }

  @media screen and (max-width: 1000px) {
    > div {
      .leftSection {
        padding-top: 20px;
      }

      .leftSection,
      .rightSection {
        display: block;
        width: 100%;
        vertical-align: top;
        text-align: left;
      }

      .rightSection {
        padding-top: 30px;
      }
    }
  }
`
// Complex cards needs to grab unique background colors for the fade
const uniqueBackgroundSections = {
  blueDotsBlueBG: '247, 251, 252',
  blueDotsUpperRightBlueBG: '247, 251, 252',
  blueDotsLowerLeftBlueBG: '247, 251, 252',
  blueDotsLowerRightBlueBG: '247, 251, 252',
  blueDotsRightToLeftBlueBG: '247, 251, 252',
  blueDotsLeftToRightBlueBG: '247, 251, 252',
  blueDotsFullWidthBlueBG: '247, 251, 252',
  blueDotsLowerLeftInversedBlueBG: '247, 251, 252',
  clearBlueBackground: '247, 251, 252',
}

// Map backgrounds to sections
const MapBackgrounds = {
  blueDots: `
        background: #fff url(${bg_ul}) top 20px left 0px no-repeat;

        @media screen and (max-width: 1000px) {
          background-position: top 20px left -80px;
        }
    `,
  blueDotsBlueBG: `
        background: #f7fbfc;
        background-image: url(${bg_ul});
        background-position: top 20px left 0px;
        background-repeat: no-repeat;

        @media screen and (max-width: 1000px) {
          background-position: top 20px left -80px;
        }
    `,
  blueDotsUpperRight: `
        background: #fff;
        background-image: url(${bg_ur});
        background-position: top 20px right 0px;
        background-repeat: no-repeat;

        @media screen and (max-width: 1000px) {
          background-position: top 20px right -80px;
        }
    `,
  blueDotsUpperRightBlueBG: `
        background: #f7fbfc;
        background-image: url(${bg_ur});
        background-position: top 20px right 0px;
        background-repeat: no-repeat;
    `,
  blueDotsLowerLeft: `
        background: #fff;
        background-image: url(${bg_ll});
        background-position: bottom 20px left 0px;
        background-repeat: no-repeat;
        padding-bottom: 100px;

        @media screen and (max-width: 1000px) {
          background-position: bottom 20px left -80px;
        }
    `,
  blueDotsLowerLeftBlueBG: `
        background: #f7fbfc;
        background-image: url(${bg_ll});
        background-position: bottom 20px left 0px;
        background-repeat: no-repeat;
        padding-bottom: 100px;

        @media screen and (max-width: 1000px) {
          background-position: bottom 20px left -80px;
        }
    `,
  blueDotsLowerRight: `
    background: #fff;
    background-image: url(${bg_lowr});
    background-position: bottom 20px right 0px;
    background-repeat: no-repeat;
    padding-bottom: 180px;

    @media screen and (max-width: 1000px) {
      background-position: bottom 20px right -80px;
    }
`,
  blueDotsLowerRightBlueBG: `
    background: #f7fbfc;
    background-image: url(${bg_lowr});
    background-position: bottom 20px right 0px;
    background-repeat: no-repeat;
    padding-bottom: 180px;

    @media screen and (max-width: 1000px) {
      background-position: bottom 20px right -80px;
    }
`,
  blueDotsRightToLeft: `
        background: #fff;
        background-image: url(${bg_rl});
        background-position: top 20px right 0px;
        background-repeat: no-repeat;
        padding-top: 50px;

        @media screen and (max-width: 1000px) {
          background-position: top 20px right -80px;
        }
    `,
  blueDotsRightToLeftBlueBG: `
        background: #f7fbfc;
        background-image: url(${bg_rl});
        background-position: top 20px right 0px;
        background-repeat: no-repeat;
        padding-top: 60px;

        @media screen and (max-width: 1000px) {
          background-position: top 20px right -80px;
        }
    `,
  blueDotsLeftToRight: `
        background: #fff;
        background-image: url(${bg_lr});
        background-position: top 20px left 0px;
        background-repeat: no-repeat;
        padding-top: 60px;

        @media screen and (max-width: 1000px) {
          background-position: top 20px left -80px;
        }
    `,
  blueDotsLeftToRightBottom: `
        background: #fff;
        background-image: url(${bg_lr});
        background-position: bottom 20px left 0px;
        background-repeat: no-repeat;
        padding-bottom: 170px;
    
        @media screen and (max-width: 1000px) {
          background-position: bottom 20px left -80px;
        }
    `,
  blueDotsLeftToRightBlueBG: `
        background: #f7fbfc;
        background-image: url(${bg_lr});
        background-position: top 20px left 0px;
        background-repeat: no-repeat;
        padding-top: 60px;

        @media screen and (max-width: 1000px) {
          background-position: top 20px left -80px;
        }
    `,
  recentProjectsLongBGQuote: `
      background: #fff;
    `,
  blueDotsFullWidthBlueBG: `
        background: #f7fbfc;
        background-image: url(${bg_fw});
        background-position: top 20px left 0px;
        background-size: 1121px;
        background-repeat: no-repeat;
        padding-top: 80px;
        @media screen and (max-width: 1000px) {
          background-position: top 20px left -80px;
        }
    `,
  blueDotsFullWidthLower: `
        background: #fff;
        background-image: url(${bg_fw_lower});
        background-position: top 20px left 0px;
        background-size: 1121px;
        background-repeat: no-repeat;
        padding-top: 30px;
    `,
  blueDotsLowerLeftInversed: `
        background: #fff;
        background-image: url(${bg_ll_inv});
        background-position: bottom 20px left 0px;
        background-repeat: no-repeat;
        padding-bottom: 240px;

        @media screen and (max-width: 1000px) {
          background-position: bottom 20px left -80px;
        }
    `,
  blueDotsLowerLeftInversedBlueBG: `
        background: #f7fbfc;
        background-image: url(${bg_ll_inv});
        background-position: bottom 20px left 0px;
        background-repeat: no-repeat;
        padding-bottom: 240px;

        @media screen and (max-width: 1000px) {
          background-position: top 20px left -80px;
        }
    `,
  clearBlueBackground: `background: #f7fbfc;`,
  blank: 'background: #fff;',
}

const LEFT_PADDING = `120px`

const MapPaddings = {
  blueDots: `padding: 0 0 0 ${LEFT_PADDING};`,
  blueDotsRight: `padding: 0 0 0 ${LEFT_PADDING};`,
  clearBlueBackground: `padding: 0 0vw;`,
  blank: `padding: 0 0vw;`,
  small: `padding: 0 0vw;`,
}

const MapPaddingsWrapper = {
  blueDots: 'm',
  blueDotsRight: 'm',
  clearBlueBackground: 'm',
  blank: 'm',
  small: 'm',
}

const MapTitle = {
  recentProjectTitle: RecentProjectTitle,
  tintedBlockH3: TintedBlockH3,
  subHeader: SubHeader,
}

// Module
const SectionWrapperWithBackground = ({
  type,
  title,
  link,
  children,
  titleType,
  paddingType,
  subtitle,
  notFullWidth,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  paddingSize,
  applyTopPadding,
  applyBottomPadding,
  hasBreadcrumbs,
  breadcrumbs,
  hideOverflow,
  notFullWidthMobile,
  dontRenderWidthContainer,
  // hasBreadcrumbs = true,
  // breadcrumbs = [
  //   {
  //     linkText: 'vorm',
  //     href: '#',
  //   },
  //   {
  //     linkText: 'over vorm',
  //     href: '#',
  //   },
  //   {
  //     linkText: 'example page',
  //     href: '#',
  //   },
  // ]
}) => {
  // Determine the best setup for the scroll cards
  const [margin, setMargin] = React.useState('0px')
  const containerRef = React.useRef()
  const { width } = useResize()

  useEffect(() => {
    if (notFullWidth) {
      return
    }

    if (window.innerWidth <= 1000) {
      setMargin('8vw')
      return
    }

    if (window.innerWidth <= 1280) {
      setMargin('60px')
      return
    }

    if (window.innerWidth <= 2050) {
      setMargin('120px')
      return
    }

    const { left } = containerRef.current.getBoundingClientRect()
    setMargin(`${left}px`)
  }, [width, containerRef, notFullWidth])

  // Styling mapping
  const backgroundImage = MapBackgrounds[type]
  const paddingStyle = MapPaddings[paddingType || type]
  const paddingWrapperStyle =
    paddingSize || MapPaddingsWrapper[paddingType || type]
  const TitleElement = MapTitle[titleType || 'recentProjectTitle']
  const additionalPaddingTop =
    type !== 'blank' &&
    type !== 'clearBlueBackground' &&
    type !== 'blueDotsLowerLeftInversedBlueBG' &&
    type !== 'blueDotsLowerLeftInversed' &&
    type !== 'blueDotsLeftToRightBottom' &&
    type !== 'blueDotsLowerRightBlueBG' &&
    type !== 'blueDotsLowerRight' &&
    type !== 'blueDotsLowerLeftBlueBG' &&
    type !== 'blueDotsLowerLeft' &&
    backgroundImage
      ? 120
      : 0

  const additionalBottom =
    type === 'blueDotsLowerLeftInversedBlueBG' &&
    type === 'blueDotsLowerLeftInversed' &&
    type === 'blueDotsLeftToRightBottom' &&
    type === 'blueDotsLowerRightBlueBG' &&
    type === 'blueDotsLowerRight' &&
    type === 'blueDotsLowerLeftBlueBG' &&
    type === 'blueDotsLowerLeft' &&
    backgroundImage
      ? 120
      : 0

  return (
    <Wrapper
      hideOverflow={
        hideOverflow ||
        applyTopPadding === false ||
        applyBottomPadding === 'false'
      }
      backgroundStyle={backgroundImage}
      paddingStyle={paddingStyle}
      notFullWidthMobile={notFullWidthMobile}
      data-background-color={uniqueBackgroundSections[type] || '255, 255, 255'}
    >
      <div>
        <PaddingWrapper
          size={paddingWrapperStyle}
          hasBreadcrumbs={hasBreadcrumbs}
          hasQuoteTop={topQuote && topQuote?.length > 0}
          hasQuoteBottom={bottomQuote && bottomQuote?.length > 0}
          additionalTopQuotePadding={additionalPaddingTop}
          additionalBottom={additionalBottom}
          applyTopPadding={applyTopPadding}
          applyBottomPadding={applyBottomPadding}
        >
          <PageWrapper
            className="responsiveContainer"
            dontRenderWidthContainer={dontRenderWidthContainer}
          >
            {breadcrumbs && hasBreadcrumbs && (
              <>
                {dontRenderWidthContainer ? (
                  <PageWrapper>
                    <Breadcrumbs data={breadcrumbs} />
                  </PageWrapper>
                ) : (
                  <Breadcrumbs data={breadcrumbs} />
                )}
              </>
            )}

            {topQuote && topQuote?.length > 0 && (
              <QuoteElement quote={topQuote} top author={topQuoteAuthor} />
            )}

            {(title || subtitle) && (
              <div className={`leftSection ${link?.href ? '' : 'goFullWidth'}`}>
                {title && <TitleElement> {title} </TitleElement>}
                {subtitle && (
                  <div className="subtitle">
                    <RecentProjectTitle> {subtitle} </RecentProjectTitle>
                  </div>
                )}
              </div>
            )}
            <>
              {link && link?.href && (
                <div
                  className={`rightSection ${
                    !title && !subtitle ? 'goFullWidth' : ''
                  }`}
                >
                  <BuildLinkElement
                    link={link}
                    linkStyle="ViewAllLink"
                    showArrow
                  />
                </div>
              )}
            </>
            <div
              ref={containerRef}
              className={`contentArea ${notFullWidth ? 'notFullWidth' : ''}`}
              style={{ marginRight: `-${margin}` }}
            >
              {children}
            </div>
            {bottomQuote && bottomQuote?.length > 0 && (
              <QuoteElement
                quote={bottomQuote}
                bottom
                author={bottomQuoteAuthor}
              />
            )}
          </PageWrapper>
        </PaddingWrapper>
      </div>
    </Wrapper>
  )
}
SectionWrapperWithBackground.defaultProps = {
  type: 'blueDots',
}
export default SectionWrapperWithBackground
