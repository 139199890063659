import React from 'react'
import styled from 'styled-components'

import BuildImage from '../../elements/imageBuilder'
import truncateString from '../../utils/truncateString'
import { RecentProjectsCardH3, P } from '../../elements/typography'
import BuildLinkElement, { BuildLinkWrapper } from '../../elements/linkBuilder'

const SearchItemWrapper = styled.div`
  width: 100%;
  min-height: 126px;
  padding: 1vw 0;
  display: inline-flex !important;
  border-bottom: 1px solid #005496;

  .dateContainer {
    font-size: 0.8rem !important;

    p {
      font-size: 0.8rem !important;

      span {
        font-size: 0.8rem !important;
      }
    }
    padding: 2px 0;
  }

  > a {
    width: 100%;
  }
  .image-wrapper {
    display: inline-block;
    width: 190px;
    height: 140px;
    overflow: hidden;
    vertical-align: middle;

    img {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }

  .info-wrapper {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 190px);
    padding: 0 1vw;
  }
  .typeWrapper {
    float: right;
    color: #0d5081;
    font-size: 0.7rem;
  }

  h3 {
    max-height: 45px;
    display: inline-block;
  }

  p {
    padding: 0.25rem 0;

    > span {
      font-size: 14pt;
      display: -webkit-box !important;
      ${(props) =>
        props.date
          ? `
              -webkit-line-clamp: 2;
            `
          : '-webkit-line-clamp: 3;'}
      /* -webkit-line-clamp: 3; */
            -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.25rem;
    }
  }

  h3 + p {
    padding-top: 0.5rem;
  }
  p + span {
    padding-bottom: 0.5rem;
  }
  span {
    display: block;
  }

  &:last-child {
    border-bottom: 0;
  }

  @media screen and (max-width: 680px) {
    width: 100%;
    border: 0;
    .image-wrapper {
      min-width: 115pt;
      height: unset;
      width: 100%;
      margin-bottom: 18px;
    }
    .info-wrapper {
      width: 100%;
      padding: 0;
      padding-left: 0rem;
      padding-bottom: 18px;

      h3 {
        font-size: 16pt;
        height: 40pt;
        max-height: 40pt;
      }

      .typeWrapper {
        float: unset;
        color: #000000;
        display: block;
        width: 100%;
        font-size: 10pt;
        padding-bottom: 5pt;
      }
      .dateContainer {
        display: none;
      }

      p > span {
        -webkit-line-clamp: 3;
      }
    }
    .readMore {
      display: none;
    }
  }

  p {
    &.short {
      height: 45px !important;
      overflow: hidden;

      @media screen and (max-width: 680px) {
        height: 40px !important;
      }
    }

    &.long {
      height: 65px !important;
      overflow: hidden;
    }
  }
`

// Module
const CardModule = ({ image, title, date, desc, category, link }) => {
  return (
    <SearchItemWrapper className="vacancyCard" date={date}>
      <BuildLinkWrapper link={link}>
        <div className="image-wrapper">
          <BuildImage image={image} width={190} height={140} />{' '}
        </div>
        <div className="info-wrapper">
          <RecentProjectsCardH3>{title}</RecentProjectsCardH3>
          <div className="typeWrapper">{category?.toUpperCase()}</div>
          <div className="content">
            {date && (
              <div className="dateContainer">
                <P text={date} />
              </div>
            )}
            {desc && (
              <P
                classes={date ? 'short' : 'long'}
                text={truncateString(desc, 248)}
              />
            )}
            <div className="readMore">
              <BuildLinkElement
                link={{ ...link, linkText: link.title }}
                linkStyle="ReadMoreButton"
                showArrow
              />
            </div>
          </div>
        </div>
      </BuildLinkWrapper>
    </SearchItemWrapper>
  )
}
CardModule.defaultProps = {}
export default CardModule
