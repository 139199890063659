import React, { useRef } from 'react'
import styled from 'styled-components'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'

// Internal modules
import ProjectCard from '../projectCard'
import AllCard from './alleCard'

import { ReactComponent as LeftAngle } from '../../../assets/svg/angle-left-solid.svg'
import { ReactComponent as RightAngle } from '../../../assets/svg/angle-right-solid.svg'

// Intenral styling
const SwipableContainer = styled.div`
  white-space: nowrap;
  padding-top: 40px;
  position: relative;

  .mySwiper {
    position: unset;

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    .prev-slide,
    .next-slide {
      height: 100%;
    }
    .swiper-button-disabled {
      display: none;
    }

    .left {
      position: absolute;
      left: -40px;
      width: 40px;
      top: 40px;
      height: 100%;
      z-index: 999;
      svg {
        right: 0;
        width: 20px;
        height: 54%;
        position: relative;
        left: -30px;

        @media screen and (max-width: 1300px) {
          left: 0;
        }
      }
    }
    .right {
      position: absolute;
      right: 0;
      width: 20%;
      top: 40px;
      height: 100%;
      text-align: center;
      background: linear-gradient(
        270deg,
        rgb(${(props) => props.backgroundColorOfParent}) 0%,
        rgb(${(props) => props.backgroundColorOfParent}) 10%,
        rgb(${(props) => props.backgroundColorOfParent}, 0.5) 50%,
        rgba(${(props) => props.backgroundColorOfParent}, 0) 100%
      );
      z-index: 999;

      svg {
        width: 20px;
        height: 54%;
        position: relative;
        right: -30px;
      }
    }

    @media screen and (max-width: 1000px) {
      .right,
      .left {
        display: none !important;
      }
    }
  }

  .swiper-slide {
    font-size: 18px;

    display: inline-block;
    vertical-align: top;

    width: ${(props) => (props.variant === 'big' ? '420px' : '260px')};

    white-space: normal;
  }

  @media screen and (max-width: 420px) {
    .swiper-slide {
      width: 96%;
    }
  }
`

SwiperCore.use([Navigation])

// Module
const SwipeableView = ({
  data,
  variant,
  linkCard = {},
  showMoreCardInformation,
  showCardArrows,
  backgroundColorOfParent = '255, 255, 255',
  portraitImage,
  imagePosition
}) => {
  const prevRef = useRef(null)
  const nextRef = useRef(null)

  let maxIndex = data?.length + 1
  // Ensure default
  variant = variant || 'small'

  return (
    <SwipableContainer
      backgroundColorOfParent={backgroundColorOfParent}
      variant={variant}
    >
      <Swiper
        lazy
        slidesPerView={'auto'}
        spaceBetween={20}
        className="mySwiper"
        navigation={{
          prevEl: prevRef.current ? prevRef.current : undefined,
          nextEl: nextRef.current ? nextRef.current : undefined,
        }}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current
          swiper.params.navigation.nextEl = nextRef.current
          swiper.navigation.update()
        }}
      >
        {showCardArrows && (
          <>
            <div className="left">
              <div className="prev-slide" ref={prevRef}>
                <LeftAngle />
              </div>
            </div>
            <div className="right">
              <div className="next-slide" ref={nextRef}>
                <RightAngle />
              </div>
            </div>
          </>
        )}
        {data &&
          data.map &&
          data.map((card) => (
            <SwiperSlide
              key={`swipable-container-${card.title}`}
              className="slideContainer"
            >
              <ProjectCard
                showMoreCardInformation={showMoreCardInformation}
                {...card}
                variant={variant}
                imagePosition={imagePosition}
                portraitImage={portraitImage}
              />
            </SwiperSlide>
          ))}
        {linkCard && linkCard.link && linkCard.link.href && (
          <SwiperSlide
            key={`swipable-container-${maxIndex}`}
            className="slideContainer"
            style={{ verticalAlign: 'top' }}
          >
            <AllCard
              portraitImage={portraitImage}
              variant={variant}
              title={linkCard.title}
              desc={linkCard.desc}
              link={linkCard.link}
            />
          </SwiperSlide>
        )}
      </Swiper>
    </SwipableContainer>
  )
}
SwipeableView.defaultProps = {
  data: [],
  variant: 'big',
}
export default SwipeableView
