import React from 'react'
import styled from 'styled-components'
import { RecentProjectsCardH3 } from '../../elements/typography'
// import { useHistory } from 'react-router-dom'
import { BuildLinkWrapper } from '../../elements/linkBuilder'

// Card Wrap
const Icon = styled.img`
  max-width: calc(100% - 4rem);
  max-height: 100px;
  overflow: hidden;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
`

const Wrapper = styled.div`
  display: inline-block !important;
  width: 25%;
  height: 100%;
  padding-top: 40px;

  .textWrapper {
    text-align: center;
    padding-right: 2rem;
    padding-left: 2rem;
  }

  @media screen and (max-width: 1000px) {
    width: 50%;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`

// Element
const IconElement = ({ service, image, link }) => {
  // const history = useHistory()

  // const handleLink = (url) => {
  //   if (url.charAt(0) === '/') {
  //     history.push(url)
  //   } else {
  //     window.location.href = url
  //   }
  // }

  return (
    <Wrapper>
      <BuildLinkWrapper link={link}>
        <Icon src={image} alt="" />
        <div className="textWrapper">
          <RecentProjectsCardH3>{service}</RecentProjectsCardH3>
        </div>
      </BuildLinkWrapper>
    </Wrapper>
  )
}
IconElement.defaultProps = {}
export default IconElement
