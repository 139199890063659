import React from 'react'
import styled from 'styled-components'

// Core
import SectionWrapper from '../../../core/modules/sectionBackgroundWrapper'
import IconElement from '../../modules/expertiseBlockIcons/expertiseBlockIcon'

// svg images
import Image1 from '../../../assets/svg/ic_expertise_1.svg'
import Image2 from '../../../assets/svg/ic_expertise_2.svg'
import Image3 from '../../../assets/svg/ic_expertise_3.svg'
import Image4 from '../../../assets/svg/ic_expertise_4.svg'
import Image5 from '../../../assets/svg/ic_expertise_5.svg'
import Image6 from '../../../assets/svg/ic_expertise_6.svg'

const map = {
  icon1: Image1,
  icon2: Image2,
  icon3: Image3,
  icon4: Image4,
  icon5: Image5,
  icon6: Image6,
}

const defaultSectionData = {
  type: 'blank',
  titleType: 'tintedBlockH3',
  paddingType: 'blank',
}

const Block = styled.div`
  width: 100%;
  text-align: center;
  img {
    padding: 2rem;
  }
  h3 {
    font-size: 1rem;
  }
`

const ExpertiseBlockWithIcons = ({ icons, sectionData, hasBreadcrumbs }) => {
  const newProps = {
    ...defaultSectionData,
    ...sectionData,
    notFullWidth: true,
  }
  return (
    <SectionWrapper {...newProps} hasBreadcrumbs={hasBreadcrumbs}>
      <Block>
        {icons &&
          icons.map &&
          icons.map((icon) => {
            // let image = { src: map[`icon${i + 1}`] }
            return (
              <IconElement
                image={map[icon.icon]}
                desc={icon.desc}
                key={icon.desc}
                href={icon.href}
                type="small"
              />
            )
          })}
      </Block>
    </SectionWrapper>
  )
}

ExpertiseBlockWithIcons.defaultProps = {}
export default ExpertiseBlockWithIcons
