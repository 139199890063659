import styled from 'styled-components'

const HeaderWrapper = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 900px;
  position: relative;
  overflow: hidden;
  position: relative;

  .logoElement {
    cursor: pointer;
  }

  .vimeoContainer {
    position: absolute;
    min-height: 900px;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: scale(1.18);
    min-height: 100%;

    div {
      min-height: 100%;
      height: 100%;
    }

    > div {
      transform: scale(1.2);
    }

    @media screen and (max-width: 1900px) {
      > div {
        transform: scale(1.6);
      }
    }

    @media screen and (max-width: 1400px) {
      > div {
        transform: scale(2);
      }
    }

    @media screen and (max-width: 1100px) {
      > div {
        transform: scale(2.8);
      }
    }

    @media screen and (max-width: 800px) {
      > div {
        transform: scale(3.2);
      }
    }

    @media screen and (max-width: 700px) {
      > div {
        transform: scale(3.6);
      }
    }

    @media screen and (max-width: 550px) {
      > div {
        transform: scale(4.4);
      }
    }

    @media screen and (max-width: 450px) {
      > div {
        transform: scale(5.1);
      }
    }
  }

  .backgroundElement {
    background-color: #1c4680;
    ${(props) =>
      props.backgroundImage
        ? `background-image: url(${
            process.env.REACT_APP_URL || ''
          }/resize/1900/0?image=${props.backgroundImage});`
        : ''}
    background-repeat: no-repeat;
    background-size: cover;
    background-position: ${(props) =>
      props.bgPos ? props.bgPos : 'center center'};
    height: 100%;

    @media screen and (min-width: 1000px) {
      background-attachment: fixed;
    }

    @media screen and (max-width: 1100px) {
      ${(props) => (props.showHeaderOptions ? 'min-height: 960px;' : '')}
    }
  }

  .headerOverlay {
    background: ${(props) => props.overlayColor};
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 1100px) {
      ${(props) => (props.showHeaderOptions ? 'min-height: 960px;' : '')}
    }

    .pageContainer {
      > div {
        height: 100%;
      }
    }
  }

  .chevronWrapper {
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    svg {
      padding: 1rem;
      stroke: #fff !important;
    }

    .a {
      stroke: #fff !important;
    }
  }

  .menuWrapper {
    nav {
      position: absolute;
      top: 30px;
      right: 236px;
    }

    svg {
      position: absolute;
      top: 20px;
      left: 0;
      height: 68px;

      path {
        fill: #fff !important;
      }
    }
  }

  @media screen and (min-width: 2040px) {
    .menuWrapper {
      nav {
        padding-right: 0.75vw;
      }
    }
  }

  > div {
    height: 100%;
  }

  h1 {
    color: #fff !important;
  }

  &.menuOpen {
    .blurContainer {
      filter: blur(1.5rem);
    }
  }

  .listContainerHeader {
    transition: top 0.5s ease;
    top: 0;
    position: relative;
    display: block;

    a {
      color: #fff !important;

      &:hover {
        color: #fff !important;
      }
    }
  }

  .headerOptions {
    position: absolute;
    width: 340px;
    height: 100%;
    top: 0%;
    right: 0;
    background: rgba(9, 86, 150, 0.8);

    .housing {
      position: relative;
      top: 50%;
      margin-top: -100px;

      @media screen and (max-width: 1100px) {
        margin-top: -260px;
      }

      .floatingArrow {
        position: absolute;
        top: 0;
        right: 20px;
        z-index: 9999;
        height: 68px;

        @media screen and (max-width: 1100px) {
          right: 52px;
        }

        .aButtonArrowx {
          stroke: #fff !important;
        }
      }
    }

    li {
      padding: 22px 0 22px 1.5vw;
      min-height: 30px;
      line-height: 30px;
      color: #fff;
      font-size: 0.8rem;
      transition: all 0.3s ease;
      cursor: pointer;

      @media screen and (max-width: 1100px) {
        padding-left: 8vw;
      }

      svg {
        display: none;
        width: 2rem;
        height: 2rem;
        padding-left: 1rem;

        g {
          path,
          line,
          circle {
            stroke: #fff;
          }
        }
      }

      &.active {
        font-size: 1.2rem;
        font-weight: 400;

        svg {
          display: inline-block;
          vertical-align: middle;
          overflow: visible;
        }
      }
    }

    @media screen and (min-width: 2050px) {
      width: calc(340px + 0.8vw);
    }

    @media screen and (min-width: 2150px) {
      width: calc(340px + 1vw);
    }
  }
  .headerTitle {
    position: absolute;
    top: 46%;
    left: 0;
    max-width: 45vw;

    ${(props) => (props.showHeaderOptions ? '' : 'max-width: 70vw;')}

    p {
      color: #fff;
    }

    span {
      a {
        color: #fff;
      }
      svg {
        line,
        path {
          stroke: #fff;
        }
      }
    }
  }

  .pageContainer,
  .contentWrapperHeader,
  .contentPositionHeader {
    width: 100%;
    height: 100%;
  }

  .contentPositionHeader {
    position: relative;
  }

  &.errorPage {
    .pageContainer {
      background: rgba(0, 84, 150, 0.8);
      max-width: 100%;
    }

    .errorContainer {
      text-align: center;
      padding-top: 40vh;

      img {
        max-width: 500px;
        margin: 0 auto;
        display: block;
      }
    }
  }

  .toggleMenu {
    position: absolute;
    top: 40px;
    right: 30px;
    margin-top: -5px;
    cursor: pointer;

    @media screen and (max-width: 800px) {
      right: 0px;
    }

    > div {
      width: 40px;
      height: 40px;
      margin-top: 6px;
      /* background: rgba(0, 0, 0, 0.2); */
      display: inline-block;
      vertical-align: middle;

      &.divider {
        width: 2px;
        padding: 6px 32px;
      }
    }
    .searchWrapper {
      padding: 8px;
      transform: scale(0.8);
      margin-top: -3px;
      margin-left: -5px;

      svg {
        g,
        line {
          stroke: #fff;
        }
      }
    }
  }

  ${(props) =>
    props.isSearch
      ? `
            min-height: 360px;
            .vimeoContainer,
            .headerOverlay,
            .backgroundElement {
                min-height: 360px;
            }
        `
      : ''}

  @media screen and (max-width: 1100px) {
    .menuWrapper {
      nav {
        top: 60px;
      }

      svg {
        top: 60px;
      }
    }

    .toggleMenu {
      top: 80px;
      right: -20px;
    }

    nav,
    .chevronWrapper {
      display: none !important;
    }

    .headerOptions {
      width: calc(100% + 120px);
      max-width: calc(100% + 120px);
      margin-left: -60px;
      padding-left: 60px;
    }

    .listContainerHeader {
      transition: top 0.5s ease;
      top: 0 !important;
      position: relative;
      display: block;
    }

    .headerOptions,
    .headerTitle {
      position: absolute;
      top: initial;
      left: 0;
      bottom: 10%;
      width: 100%;
      max-width: 100%;
      padding: 0;
      margin-top: -25px;
    }

    .headerTitle {
      z-index: 999;
    }

    .headerOptions {
      margin-top: 12px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(9, 86, 150, 0.6) 40%
      );
      width: calc(100% + 16vw);
      max-width: calc(100% + 16vw);
      margin-left: -8vw;
      padding-left: 0;
      bottom: 0;

      li {
        font-size: 1.2rem;
        font-weight: 400;

        span {
          display: inline-block;
          min-width: 210px;
          position: relative;
          z-index: 99999;
        }

        svg {
          display: inline-block;
          vertical-align: middle;
          overflow: visible;
        }
      }
    }

    .headerTitle {
      padding-top: 300px;

      ${(props) =>
        props.showHeaderOptions ? '' : 'padding-top: 0; bottom: 15%;'}
    }
  }

  @media screen and (max-width: 700px) {
    .toggleMenu {
      right: 0px;
    }
  }

  ${(props) =>
    props.isSearch && !props.isOpen
      ? `
    height: 420px;
  `
      : ''}

  &.noMediaVariant {
    background: #fff;
    height: 125px;
    min-height: 125px;
    max-height: 125px;
    overflow: initial;

    .backgroundElement,
    .headerOverlay {
      background: #fff;
    }

    svg path {
      fill: #1c4680 !important;
    }

    .listWrapper a {
      color: #1c4680;
    }

    .toggleMenu {
      svg path {
        stroke: #1c4680 !important;
      }
    }

    svg line {
      stroke: #1c4680 !important;
    }
  }
`

export default HeaderWrapper
