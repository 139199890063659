import React from 'react'
import styled from 'styled-components'

import { PageWrapper, WIDTH } from '../../elements/pageContainer'

// Core
import { TintedBlockH3, P } from '../../elements/typography'
import BuildLinkElement from '../../elements/linkBuilder'

// Module styling
const BLOCK_WIDTH = WIDTH / 2
const BigMediaTextBlock = styled.div`
  background: #f7fbfc;

  .mediaCol {
    width: 50%;
    position: relative;
    display: inline-block;
    vertical-align: top;

    p {
      padding: 2rem 0;
    }

    &.blueBackground {
      background: #005496;

      h3,
      p {
        color: #fff;
      }

      span {
        a {
          color: #fff;
        }
        svg g {
          path,
          line {
            stroke: #fff;
          }
        }

        :hover {
          a {
            color: #00a1d5;
          }
          svg g {
            path,
            line {
              stroke: #00a1d5;
            }
          }
        }
      }
    }

    .alignRight,
    .alignLeft {
      width: 100%;
      max-width: calc(${BLOCK_WIDTH}px);
    }

    .alignRight {
      float: right;
      padding: 160px 8vw 160px 0;
    }

    .alignLeft {
      float: left;
      padding: 160px 0 160px 8vw;
    }
  }

  @media screen and (max-width: 1900px) {
    .mediaCol {
      .alignRight,
      .alignLeft {
        width: 100%;
        max-width: calc(${BLOCK_WIDTH}px);
      }

      .alignRight {
        padding: 160px 8vw;
      }

      .alignLeft {
        padding: 160px 8vw;
      }
    }
  }

  @media screen and (max-width: 940px) {
    .mediaCol {
      width: 100%;
      display: block;

      .alignRight,
      .alignLeft {
        width: 100%;
        float: left;
        max-width: calc(${BLOCK_WIDTH}px);
      }

      &:nth-child(0),
      &:nth-child(2) {
        background: #005496;

        h3,
        p {
          color: #fff;
        }

        span {
          a {
            color: #fff;
          }
          svg g {
            path,
            line {
              stroke: #fff;
            }
          }

          :hover {
            a {
              color: #00a1d5;
            }
            svg g {
              path,
              line {
                stroke: #00a1d5;
              }
            }
          }
        }
      }

      &:nth-child(1),
      &:nth-child(3) {
        background: #fff;

        h3 {
          color: #005496;
        }

        p {
          color: #000;
        }

        span {
          a {
            color: #000;
          }
          svg g {
            path,
            line {
              stroke: #000;
            }
          }

          :hover {
            a {
              color: #00a1d5;
            }
            svg g {
              path,
              line {
                stroke: #00a1d5;
              }
            }
          }
        }
      }
    }
  }
`

const FullscreenWrapper = ({ children }) => <div> {children} </div>
const SmallWrapper = ({ children }) => <PageWrapper> {children} </PageWrapper>

const BigMediaTextModule = ({ blocks, small, fullscreenAlign = true }) => {
  const Wrapper = !small ? FullscreenWrapper : SmallWrapper
  const containerReference = React.useRef()
  let handleResponsive = null

  const handleContentSizingTextBlock = () => {
    const elements = containerReference?.current?.querySelectorAll(
      '.textBlockSection'
    )

    if (elements && elements.length > 0) {
      const newDom = [...elements]
      let minHeight = 0

      newDom.forEach((element) => {
        element.removeAttribute('style')
      })

      newDom.forEach((element) => {
        if (element.offsetHeight > minHeight) {
          minHeight = element.offsetHeight
        }
      })

      newDom.forEach((element) => {
        element.setAttribute('style', `min-height: ${minHeight}px ;`)
      })
    }
  }

  React.useEffect(() => {
    handleContentSizingTextBlock()

    const handleResizeTextBlock = () => {
      clearTimeout(handleResponsive)

      handleResponsive = setTimeout(() => {
        handleContentSizingTextBlock()
      }, 35)
    }

    // Event listener
    window.removeEventListener('resize', handleResizeTextBlock)
    window.addEventListener('resize', handleResizeTextBlock)

    return () => {
      window.removeEventListener('resize', handleResizeTextBlock)
    }
  }, [])

  return (
    <Wrapper>
      <BigMediaTextBlock ref={containerReference}>
        {blocks &&
          blocks.map &&
          blocks.map((col, i) => {
            const hasBlueBackground =
              (Math.floor(i / 2) % 2 == 0 && i % 2 == 0) ||
              (Math.floor(i / 2) % 2 !== 0 && i % 2 !== 0)

            return (
              <div
                key={`big-alternating-color-item-${Date.now()}-${i}`}
                className={`mediaCol ${
                  hasBlueBackground ? 'blueBackground' : ''
                }`}
              >
                <div
                  className={`${
                    fullscreenAlign
                      ? ` ${i % 2 ? 'alignLeft' : 'alignRight'}`
                      : ''
                  }`}
                >
                  <TintedBlockH3> {col.title} </TintedBlockH3>
                  <div className="textBlockSection">
                    {' '}
                    <P dontCrop text={col.text} />{' '}
                  </div>

                  {col?.link?.href && (
                    <BuildLinkElement
                      link={col.link}
                      linkStyle="ViewAllLink"
                      showArrow
                    />
                  )}
                </div>
                <div style={{ clear: 'both' }}></div>
              </div>
            )
          })}
      </BigMediaTextBlock>
    </Wrapper>
  )
}
BigMediaTextModule.defaultProps = {}
export default BigMediaTextModule
