import React from 'react'
import styled from 'styled-components'
// import { useHistory } from 'react-router-dom'

// Core
import IconElement from '../expertisesIconCard/iconCard'
import ExpertiseLink from '../expertisesIconCard/expertisesLink'
// import { BuildLinkWrapper } from '../../elements/linkBuilder'

// svg images
import Image1 from '../../../assets/svg/ic_expertise_1.svg'
import Image2 from '../../../assets/svg/ic_expertise_2.svg'
import Image3 from '../../../assets/svg/ic_expertise_3.svg'
import Image4 from '../../../assets/svg/ic_expertise_4.svg'
import Image5 from '../../../assets/svg/ic_expertise_5.svg'
import Image6 from '../../../assets/svg/ic_expertise_6.svg'

const Block = styled.div`
  width: 100%;

  .iconsDesc {
    padding-bottom: 42px;

    p {
      padding: 0;
    }
  }
`

const map = {
  icon1: Image1,
  icon2: Image2,
  icon3: Image3,
  icon4: Image4,
  icon5: Image5,
  icon6: Image6,
}

const ExpertisesIconBlock = ({ expertises, link, iconsDesc }) => {
  // const history = useHistory()
  // const handleLink = (url) => {
  //   if (url.charAt(0) === '/') {
  //     history.push(url)
  //   } else {
  //     window.location.href = url
  //   }
  // }
  return (
    <Block>
      {iconsDesc !== undefined && (
        <div
          className="iconsDesc"
          dangerouslySetInnerHTML={{
            __html: iconsDesc || '',
          }}
        ></div>
      )}
      {expertises &&
        expertises?.length > 0 &&
        expertises.map &&
        expertises.map((expertise, i) => {
          // let icon = { src: map[`icon${i + 1}`] }
          return (
            <IconElement
              icon={map[expertise.icon]}
              desc={expertise.linkText}
              key={`${expertise.linkText}-${i}`}
              href={expertise.href}
              handleClick={() => {}}
            />
          )
        })}
      {link?.href && link?.linkText && (
        <ExpertiseLink link={link} linkStyle="ReadMoreButton" showArrow />
      )}
    </Block>
  )
}
ExpertisesIconBlock.defaultProps = {}
export default ExpertisesIconBlock
