import React from 'react'
import styled from 'styled-components'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share'

import { PageWrapper } from '../../elements/pageContainer'

// Components
import SectionWrapper from '../../modules/sectionBackgroundWrapper'
import {
  TintedBlockH3,
  TintedSubBlockH3,
  P,
  RecentProjectTitle,
} from '../../elements/typography'
import BuildImage from '../../elements/imageBuilder'
import VideoBlock from '../../modules/vimeoBlock'

import QuoteElement from '../../elements/quote'
import { BuildLinkWrapper } from '../../elements/linkBuilder'
import Breadcrumbs from '../../modules/breadcrumbs'

// Assets
import { ReactComponent as FacebookLogo } from '../../sections/footer/icons/facebook.svg'
import { ReactComponent as LinkedinLogo } from '../../sections/footer/icons/linkedin.svg'
import { ReactComponent as TwitterLogo } from '../../sections/footer/icons/twitter.svg'

const NewsWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;

  @media screen and (max-width: 900px) {
    padding: 0 20px;
  }

  @media screen and (max-width: 400px) {
    padding: 0 0px;
  }

  .tagsContainer {
    padding: 22px 0;
    height: 40px;
    line-height: 40px;

    strong,
    span {
      padding: 0 8px 0 0;
    }

    strong {
      color: #005496;
      margin-right: 20px;
    }
  }

  .shareContainer {
    padding: 22px 0;
    strong {
      height: 40px;
      color: #005496;
      line-height: 40px;
      margin-right: 20px;
    }

    img {
      margin: 0 8px;
      height: 40px;
      position: relative;
      top: 12px;
    }
  }

  .imageHolder {
    img {
      width: 100%;
    }
  }
`

// View
const VacanciesSectionWrapper = (props) => {
  // const history = useHistory()
  const {
    backgroundType,
    titleType,
    hasBreadcrumbs,
    title,
    subTitle,
    sectionData,
    topPadding,
    bottomPadding,
    text,
    mediaType,
    image,
    video,
    showShare,
    showTags,
    tags,
    topQuote,
    topQuoteAuthor,
    bottomQuote,
    bottomQuoteAuthor,
    breadcrumbs,
    dontRenderWidthContainer,
    date,
    showDate,
  } = props

  const baseSectionData = {
    type: backgroundType || 'blank',
    titleType: titleType,
    notFullWidth: true,
  }

  const newProps = { ...baseSectionData, ...sectionData }

  // const handleLink = (url) => {
  //   if (url.charAt(0) === '/') {
  //     history.push(url)
  //   } else {
  //     window.location.href = url
  //   }
  // }

  return (
    <SectionWrapper
      {...newProps}
      hasBreadcrumbs={hasBreadcrumbs}
      title={''}
      subtitle={''}
      applyTopPadding={topPadding}
      applyBottomPadding={bottomPadding}
    >
      <NewsWrapper>
        {breadcrumbs && hasBreadcrumbs && (
          <>
            {dontRenderWidthContainer ? (
              <PageWrapper>
                <Breadcrumbs data={breadcrumbs} />
              </PageWrapper>
            ) : (
              <Breadcrumbs data={breadcrumbs} />
            )}
          </>
        )}

        {topQuote && topQuote?.length > 0 && (
          <QuoteElement
            quote={topQuote}
            nopad
            givespacing
            top
            author={topQuoteAuthor}
          />
        )}

        {date && showDate && (
          <RecentProjectTitle style={{ paddingBottom: '12px' }}>
            {' '}
            {date}{' '}
          </RecentProjectTitle>
        )}
        {title && title.length > 0 && <TintedBlockH3> {title} </TintedBlockH3>}
        {subTitle && subTitle.length > 0 && (
          <TintedSubBlockH3> {subTitle} </TintedSubBlockH3>
        )}
        {text && text.length > 0 && <P dontCrop text={text} />}
        {mediaType && mediaType === 'image' && (
          <div className="imageHolder">
            <BuildImage width="1000" height="1000" image={image} />
          </div>
        )}
        {mediaType && mediaType === 'video' && (
          <div className="videoHolder">
            <VideoBlock video={video} />
          </div>
        )}

        {bottomQuote && bottomQuote?.length > 0 && (
          <QuoteElement
            quote={bottomQuote}
            bottom
            nopad
            givespacing
            author={bottomQuoteAuthor}
          />
        )}

        {showShare && (
          <div className="shareContainer">
            <strong> Share </strong>
            <FacebookShareButton url={window.location.href} title={title}>
              <FacebookLogo className="shareicon" />
            </FacebookShareButton>
            <LinkedinShareButton url={window.location.href} title={title}>
              <LinkedinLogo className="shareicon" />
            </LinkedinShareButton>
            <TwitterShareButton url={window.location.href} title={title}>
              <TwitterLogo className="shareicon" />
            </TwitterShareButton>
          </div>
        )}
        {showTags && (
          <div className="tagsContainer">
            <strong> Tags:</strong>
            {tags.map((tag) => (
              <span key={tag.linkText}>
                <BuildLinkWrapper link={tag}>{tag.linkText}</BuildLinkWrapper>
              </span>
            ))}
          </div>
        )}
      </NewsWrapper>
    </SectionWrapper>
  )
}
export default VacanciesSectionWrapper
