import React from 'react'
import styled from 'styled-components'

// Card Wrap
const Image = styled.img`
  height: 100%;
  width: -webkit-fill-available;
  object-fit: cover;
  object-position: center center;
`

const Small = styled.div`
  display: inline-block !important;
  height: 50%;
  width: 100%;
`
const Large = styled.div`
  display: inline-block !important;
  height: 100%;
  width: 100%;
`

const map = {
  small: Small,
  large: Large,
}

// Element
const ImageElement = ({ image, type = 'large' }) => {
  const Wrapper = map[type]

  if (!Wrapper) {
    return null
  }

  return (
    <Wrapper>
      <Image
        style={image?.position ? { objectPosition: image?.position } : {}}
        src={image}
        alt=""
      />
    </Wrapper>
  )
}
ImageElement.defaultProps = {}

export default ImageElement
