import React from 'react'
import styled from 'styled-components'

// Core
// import SelectBox from '../../elements/selectBox'
import Loader from '../../elements/loader'
import { PageWrapper } from '../../elements/pageContainer'
import NewsCard from './news.card'
import Pagination from '../pagination'
import QuoteElement from '../../elements/quote'
import PaddingWrapper from '../../elements/paddingContainer'
import Breadcrumbs from '../../modules/breadcrumbs'

// API Driven logic
import { fetchApi } from '../../utils/api/index'
import FilterSidebar from "../filters/filters";

const buildApiUrl = (state, api, page, intitial) => {
  const newApi = `${api}?${state.join()}${intitial ? '&initial=true' : ''}`
  const validUrl = newApi.replace(/,/g, '&')
  return validUrl + `&page=${page}`
}

// Styling
const NewsWrapper = styled.div`
  background: #f7fbfc;

  h3 {
    margin-bottom: 20px;
  }

  .noresults {
    padding: 80px 0;
    text-align: center;
  }

  .rightContent {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 280px);
    padding-left: 20px;

    ${props => !props.filters || props.filters.length === 0 ? 'padding-left: 0;' : ''}
  }

  .cardContainer {
    padding: 0px 0px 40px;
    display: block;
    vertical-align: top;
    border-bottom: 1px solid #005496;
    margin-bottom: 40px;

    p {
      padding: 0;
      margin: 0;
    }

    span {
      padding-top: 12px;
      display: block;
    }
  }

  @media screen and (max-width: 950px) {
    .cardContainer,
    .rightContent {
      width: 100% !important;
      padding: 22px 0px !important;
    }
  }

  .cardContainer {
    .podcasts {
      span {
        display: inline !important;
      }
    }
  }
`

//  module
const PubSubNewsModule = ({
  filterProps,
  title,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  api,
  data,
  subTitle,
  totalPages,
  hasBreadcrumbs,
  breadcrumbs,
  showMoreCardInformation,
}) => {
  const [localState, setLocalState] = React.useState([])
  const [loading, isLoading] = React.useState(true)
  const [initialLoading, isInitialLoading] = React.useState(true)
  const [items, setItems] = React.useState(data)
  const [page, setPage] = React.useState(1)
  const [oldPage, setOldPage] = React.useState(1)
  const [newData, setData] = React.useState(data)
  const [filters, setFilters] = React.useState(filterProps)
  const [newTotalPages, setNewTotalPages] = React.useState(totalPages)
  const [source, setDataSource] = React.useState('original')
  const renderData = source === 'search' ? newData : items
  const [originalPagination, setOriginal] = React.useState(totalPages)
  const paginatedSource =
    source === 'search' ? newTotalPages : originalPagination

  const updateLocalState = (filter) => {
    let newState = [...localState]
    const index = newState.indexOf(filter)
    if (index >= 0) {
      newState.splice(index, 1)
    } else {
      newState.push(filter)
    }

    setPage(1)
    setLocalState(newState)
  }

  React.useEffect(() => {
    if (!data || data.length === 0 || !filterProps) {
      const url = buildApiUrl(localState, api, page, true)
      fetchApi(url).then((data) => {
        setData(data.data)
        setItems(data.data)
        setFilters(data.filterProps)
        setNewTotalPages(data.totalPages)
        setOriginal(data.totalPages)
        setDataSource('original')
        isLoading(false)
        isInitialLoading(false)
      })
    } else {
      isLoading(false)
      isInitialLoading(false)
    }
  }, [])

  React.useEffect(() => {
    if (localState.length > 0 || page !== oldPage) {
      const url = buildApiUrl(localState, api, page)
      isLoading(true)
      setOldPage(page)

      fetchApi(url).then((data) => {
        setData(data.data)
        setNewTotalPages(data.totalPages)
        setDataSource('search')
        isLoading(false)
      })
    } else {
      setDataSource('original')
      isLoading(false)
    }
  }, [localState, page, oldPage])

  return (
    <NewsWrapper>
      <PaddingWrapper size="l" hasBreadcrumbs={hasBreadcrumbs}>
        <PageWrapper className="contentArea">
          {breadcrumbs && hasBreadcrumbs && <Breadcrumbs data={breadcrumbs} />}
          {topQuote && topQuote?.length > 0 && (
            <QuoteElement quote={topQuote} top author={topQuoteAuthor} />
          )}

          <FilterSidebar
            title={title}
            subTitle={subTitle}
            filters={filters}
            updateLocalState={updateLocalState}
            side={'left'}
          />
          <div className="rightContent">
            {(initialLoading || loading) && <Loader />}
            {!initialLoading && !loading && (
              <div>
                {renderData &&
                  renderData.length > 0 &&
                  renderData.map((item, index) => (
                    <div
                      key={`PubSubNews-item-${index}`}
                      className="cardContainer"
                    >
                      <NewsCard
                        {...item}
                        showMoreCardInformation={showMoreCardInformation}
                      />
                    </div>
                  ))}
                {renderData &&
                  renderData.length > 0 &&
                  paginatedSource &&
                  paginatedSource > 1 && (
                    <div className="paginationWrapper">
                      <Pagination
                        count={paginatedSource}
                        handleChange={(e, value) => setPage(value)}
                        page={page}
                      />
                    </div>
                  )}

                {(!renderData || renderData.length === 0) && (
                  <div className="noresults">
                    Er zijn geen items gevonden met dit criterium
                  </div>
                )}
              </div>
            )}
          </div>

          {bottomQuote && bottomQuote?.length > 0 && (
            <QuoteElement
              quote={bottomQuote}
              bottom
              author={bottomQuoteAuthor}
            />
          )}
        </PageWrapper>
      </PaddingWrapper>
    </NewsWrapper>
  )
}

// PLaceholder props
PubSubNewsModule.defaultProps = {}
export default PubSubNewsModule
