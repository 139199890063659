import React from 'react'
import styled from 'styled-components'

import { useFetchApiState } from '../../utils/api/apiStateContext'
import { postApi } from '../../utils/api/index'
import InputField from '../../elements/inputField'
import SelectBox from '../../elements/selectBox'
import TextArea from '../../elements/textarea'
// import CheckboxField from '../../elements/checkBox'
import { ButtonInversed } from '../../elements/typography'
import Loader from '../../elements/loader'
import ErrorComponent from '../../elements/apiError'
import { ReactComponent as ArrowRight } from '../../../assets/svg/arrow_right.svg'

const Wrapper = styled.div`
  width: 100%;
  padding: 44px 0px 0;

  > div {
    padding-bottom: 4px;
  }

  .fieldContainer {
    width: 48%;
    display: inline-block;
    vertical-align: bottom;
    &:nth-child(odd) {
      margin-right: 2%;
    }
    &:nth-child(even) {
      margin-left: 2%;
    }

    @media screen and (max-width: 800px) {
      width: 100%;

      margin: 12px 0 !important;
    }
  }
  .textAreaContainer {
    padding-top: 1.5rem;

    textarea {
      box-sizing: border-box !important;
    }
  }

  .checkboxContainer {
    width: 60%;
    display: inline-block;
    color: #74a2c6;

    .MuiFormControlLabel-label {
      font-size: 0.8rem !important;
      color: #2e72a8 !important;
    }

    span span {
      width: 15px;
      height: 15px;
      border-radius: 30px;
      border: 2px solid #74a2c6;

      svg {
        display: none;
      }
    }

    .Mui-checked {
      span {
        background: #74a2c6;
        box-shadow: inset 0px 0px 0px 2px #fff;
      }
    }
  }
  .buttonContainer {
    width: 40%;
    display: inline-block;
  }

  @media screen and (max-width: 800px) {
    .checkboxContainer,
    .buttonContainer {
      width: 100%;
      display: block;
    }

    .checkboxContainer {
      padding-top: 22px;
    }
  }

  .errorContainer {
    color: red;
    padding-top: 25px;
  }
`

const errorMessages = {
  name: 'Naam',
  company_name: 'Bedrijfsnaam',
  phone: 'Telefoon',
  department: 'Afdeling',
  email: 'E-mail',
  message: 'E-mail',
  subject: 'Onderwerp',
}

// Element
const ContactInfo = ({
  fields,
  api = '',
  errorMessage,
  successTitle,
  successMessage,
}) => {
  const buildDefaultState = ((fields) => {
    // Set default values
    let values = {};

    fields &&
    fields?.length > 0 &&
    fields.map &&
    fields.map((field) => {
      values[field.name] = field.value;
    });

    return values;
  })

  const [state, setState] = React.useState(buildDefaultState(fields))
  const [errorsState, setErrors] = React.useState([])
  const [submitting, setIsSubmitting] = React.useState(false)
  const [apiError, setApiError] = React.useState(false)
  const [success, showSuccess] = React.useState(false)
  const [errorInfo, setErrorInfo] = React.useState(errorMessage)
  const { csrfToken, CSRFTokenHeader } = useFetchApiState()

  const fieldUpdate = (field, value) => {
    setState({ ...state, [field]: value })
  }

  const handleSubmit = () => {
    setIsSubmitting(true)
    // TODO: This requires the same code as the contact form
    const defaultFields = ['name', 'phone', 'email']
    let fieldsToValidate = []
    fields.forEach((field) => {
      if (defaultFields.includes(field.name) && field?.validation?.required) {
        fieldsToValidate.push(field.name)
      }
    })

    const errors = []
    fieldsToValidate.forEach((field) => {
      if (!state[field] || state[field].length <= 2) {
        errors.push(field)
      } else if (state[field] && field === 'email') {
        const value = state[field]
        const valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          value
        )

        if (!valid) {
          errors.push('email')
        }
      }
    })

    setErrors(errors)

    if (errors.length === 0) {
      postApi(api, state, { [CSRFTokenHeader]: csrfToken })
        .then(() => {
          setIsSubmitting(false)
          setApiError(false)
          showSuccess(true)
        })
        .catch((error) => {
          console.error(error)

          if (typeof error.json === 'function') {
            error.json().then((response) => {
              if (response.errorMessage) {
                setErrorInfo(response.errorMessage)
              } else {
                // No message set, use default
                setErrorInfo(errorMessage)
              }
            })
          } else {
            // Ensure we reset just in case
            setErrorInfo(errorMessage)
          }

          setApiError(true)
          setIsSubmitting(false)
        })
    } else {
      setIsSubmitting(false)
    }
  }

  const buildField = (field) => {
    switch (field.type) {
      case 'select':
        return (
          <SelectBox
            {...field}
            value={state[field.name] || null}
            handleChange={(e) => fieldUpdate(field.name, e.target.value)}
          />
        )
      case 'text':
      case 'field':
      case 'email':
      case 'number':
      case 'password':
        return (
          <InputField
            {...field}
            value={state[field.name] || ''}
            handleChange={(e) => fieldUpdate(field.name, e.target.value)}
          />
        )
      case 'textarea':
        return (
          <TextArea
            {...field}
            value={state[field.name] || ''}
            handleChange={(e) => fieldUpdate(field.name, e.target.value)}
          />
        )
    }
  }

  return (
    <Wrapper>
      {!submitting && !success && (
        <>
          {fields &&
            fields?.length > 0 &&
            fields.map &&
            fields.map((field) => {
              return buildField(field)
            })}
          <div className="buttonContainer">
            <ButtonInversed
              onClick={() => {
                handleSubmit()
              }}
            >
              Verzend <ArrowRight />
            </ButtonInversed>
          </div>
        </>
      )}

      {success && (
        <div className="successMessage">
          <h3> {successTitle} </h3>
          <p> {successMessage} </p>
        </div>
      )}

      {submitting && <Loader />}

      {apiError && <ErrorComponent message={errorInfo} />}

      {errorsState && errorsState.length > 0 && (
        <div className="errorContainer">
          <strong> Fault: </strong> Voer geldige informatie in voor de velden
          <div>
            {errorsState.map((error) => (
              <div className="errorPoint" key={`error-${error}`}>
                {' '}
                {errorMessages[error]}{' '}
              </div>
            ))}
          </div>
        </div>
      )}
    </Wrapper>
  )
}
ContactInfo.defaultProps = {}

export default ContactInfo
