import React from 'react'
import styled from 'styled-components'

import IconElement from '../expertiseBlockIcons/expertiseBlockIcon'
import { RecentProjectsCardH3, TintedBlockH3 } from '../../elements/typography'
import BuildImage from '../../elements/imageBuilder'

// svg images
import Image1 from '../../../assets/svg/ic_expertise_1.svg'
import Image2 from '../../../assets/svg/ic_expertise_2.svg'
import Image3 from '../../../assets/svg/ic_expertise_3.svg'
import Image4 from '../../../assets/svg/ic_expertise_4.svg'
import Image5 from '../../../assets/svg/ic_expertise_5.svg'
import Image6 from '../../../assets/svg/ic_expertise_6.svg'

const map = {
  icon1: Image1,
  icon2: Image2,
  icon3: Image3,
  icon4: Image4,
  icon5: Image5,
  icon6: Image6,
}

const FAQWrapper = styled.div`
  h3 {
    margin-bottom: 0px;
  }

  > h3 {
    font-size: 2.5rem;
    margin-bottom: 4rem;
  }

  .imageContainer {
    img {
      max-width: 75%;
    }

    position: absolute;
    right: 0%;
    top: 0rem;
    text-align: right;
    width: 30%;

    @media screen and (max-width: 900px) {
      position: relative;
      top: 0;
      right: 0;
      padding: 22px 0;
      text-align: center;
      width: 100%;

      img {
        max-width: 100%;
      }
    }
  }

  .FAQCard {
    padding: 18px 60px 18px 18px;
    max-width: 60%;
    border: 1px solid #005496;

    + .FAQCard {
      border-top: 0;
    }

    @media screen and (max-width: 900px) {
      max-width: 100%;
    }

    .titleContainer {
      position: relative;
      h3 {
        margin: 0;
        font-size: 0.9rem;
      }
      span.icon {
        color: #005496;
        font-weight: 200 !important;
        font-size: 60px;
        position: absolute;
        top: -16px;
        right: -45px;
        height: 49px;
        overflow: visible;
        display: inline-block;
        vertical-align: bottom;
        width: 40px;
        transform: rotate(0deg);
        transition: all 0.25s ease;
      }
      &:hover,
      &:focus {
        cursor: pointer;
      }
    }

    &.open {
      span.icon {
        transform: rotate(44deg);
        top: -14px;
        right: -45px;
      }
    }
  }

  .iconContainer {
    padding-bottom: 124px;
  }

  .faqDescription {
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.5s ease;

    &.open {
      overflow: auto;
      max-height: 1000vh;
      transition: all 1.5s ease;
    }
  }

  .relativeContainer {
    position: relative;
    overflow: hidden;
  }
`

const FAQCard = ({ question }) => {
  const [open, setOpen] = React.useState(false)

  return (
    <div className={`FAQCard ${open ? 'open' : ''}`}>
      <div className="titleContainer" onClick={() => setOpen(!open)}>
        <RecentProjectsCardH3>{question.title}</RecentProjectsCardH3>
        <span className="icon"> + </span>
      </div>
      <div className={`faqDescription ${open ? 'open' : ''}`}>
        <div style={{ paddingTop: '22px' }}>
          <span
            style={{ display: 'block' }}
            className="allowListStyling"
            dangerouslySetInnerHTML={{
              __html: question.text,
            }}
          />
        </div>
      </div>
    </div>
  )
}

const FAQComponent = ({ questions = [], title, icons, image }) => {
  return (
    <FAQWrapper>
      <TintedBlockH3> {title} </TintedBlockH3>

      {icons && icons.length > 0 && icons.map && (
        <div className="iconContainer">
          {icons.map((icon) => {
            // let image = { src: map[`icon${(i % 6) + 1}`] }
            return (
              <IconElement
                image={map[icon.icon]}
                desc={icon.linkText}
                key={icon.linkText}
                type="small"
                href={icon.href}
              />
            )
          })}
        </div>
      )}

      <div className="relativeContainer">
        {image && image.src && image.src.length > 0 && (
          <div className="imageContainer">
            <BuildImage image={image} />
          </div>
        )}

        {questions.map((question, index) => (
          <FAQCard
            key={`faq-item-${Date.now()}-${index}`}
            question={question}
          />
        ))}
      </div>
    </FAQWrapper>
  )
}
FAQComponent.defaultProps = {}
export default FAQComponent
