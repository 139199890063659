import React from "react"

import {RecentProjectsCardH3} from "../../../elements/typography"
import CheckboxField from "../../../elements/checkBox"

const GroupedCheckboxes = ({ title, subTitle, filters, updateLocalState }) => {
  return <>
    {title && title?.length > 0 && (
      <RecentProjectsCardH3 style={{ paddingLeft: '32px', paddingBottom: '28px' }}> {title} </RecentProjectsCardH3>
    )}
    {subTitle && subTitle?.length > 0 && (
      <p> {subTitle} </p>
    )}

    {
      filters.groups.map(filterGroup => {
        // TODO: Check if it's a good idea to just use `./checkboxes.js` here instead of the same duplicate code
        return <div style={{ paddingBottom: '30px' }} key={filterGroup.title}>
          <RecentProjectsCardH3 style={{ fontSize: '17px', padding: '0 0 0 32px' }}> {filterGroup.title} </RecentProjectsCardH3>

          {filterGroup.filters &&
            filterGroup.filters.map((filter) => {
              return (
                <div
                  className="filterElement"
                  key={`filter-${filter.labelId}`}
                >
                  <CheckboxField className="compact"
                    {...filter}
                    onChange={() => {
                      updateLocalState(filter.labelId)
                    }}
                  />
                </div>
              )
          })}
        </div>
      })
    }
  </>
}

export default GroupedCheckboxes
