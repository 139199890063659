import React from 'react'

import SwipableComponent from '../../../core/modules/swipeableVacanciesBlock'
import SectionWrapper from '../../../core/modules/sectionBackgroundWrapper'

// Config for this section
const defaultSectionData = {
  type: 'blank',
}

// Render the section
const Vacancies = ({
  data,
  sectionData,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  hasBreadcrumbs,
  breadcrumbs,
}) => {
  const newProps = { ...defaultSectionData, ...sectionData }
  return (
    <SectionWrapper
      {...newProps}
      hasBreadcrumbs={hasBreadcrumbs}
      topQuote={topQuote}
      breadcrumbs={breadcrumbs}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
    >
      <SwipableComponent data={data} variant={'vacancy'} />
    </SectionWrapper>
  )
}
Vacancies.defaultProps = {}
export default Vacancies
