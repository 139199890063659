import React, { useState } from 'react'
import styled from 'styled-components'

import TextField from '@material-ui/core/TextField'
import { ReactComponent as Search } from '../../assets/svg/ic_search.svg'

// SelectBox overwrites
const SelectBoxStyling = styled.div`
  max-width: 350px;
  min-width: 300px;
  position: relative;
  border-bottom: 2px solid #005496;

  .searchbutton {
    position: absolute;
    cursor: pointer;
  }

  label[data-shrink='true'] {
    transform: translate(0, -10px) scale(1) !important;
    transform-origin: top left !important;
    font-weight: 200;
    color: #005496 !important;
  }

  label[data-shrink='false'] {
    transform: translate(0, -10px) scale(1) !important;
    transform-origin: top left !important;
    font-weight: 200;
    color: #005496 !important;
  }

  input {
    padding: 0 0 0 3rem;
    margin-bottom: 10px !important;

    ::before {
      display: none;
    }
  }

  .MuiInput-underline:before,
  .MuiInput-underline:after {
    display: none;
  }
`

// TextField
const Searchbar = (props) => {
  let [input, setInput] = useState('')
  const keypress = (event) => {
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault()
      // Trigger the button element with a click
      document.getElementById('searchbutton').click()
    }
  }

  if (props.hide) {
    return null
  }

  return (
    <SelectBoxStyling id="searchbar">
      <div
        id="searchbutton"
        className="searchbutton"
        onClick={() => props.handleSearch()}
      >
        <Search />
      </div>
      <TextField
        {...props}
        id="searchinput"
        onKeyDown={(e) => keypress(e)}
        value={input}
        onChange={(e) => setInput(e.value)}
      />
    </SelectBoxStyling>
  )
}
Searchbar.defaultProps = {
  placeholder: 'Waar ben je naar op zoek?',
  handleSearch: () => console.log('Search!'),
}
export default Searchbar
