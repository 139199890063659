import React from 'react'
import styled from 'styled-components'

// Core
import { P, TintedBlockH3 } from '../../elements/typography'

// Placeholder image
// import imageElement from '../../../assets/media/person.png'

// Module styling
const LeftImageRightBlockWrapper = styled.div`
  padding: 40px 0;

  .textContentWrapper {
    width: 70%;
    display: inline-block;
    padding: 0 4rem;
    vertical-align: middle;
    max-width: 900px;
  }

  .filler {
    display: inline-block;
    width: 30%;
    aspect-ratio: 1;
    vertical-align: middle;
    padding: 0;

    @media screen and (max-width: 1000px) {
      display: none;
    }
  }

  @media screen and (max-width: 1000px) {
    .textContentWrapper {
      width: 100%;
      padding: 0;
    }
  }
`

const ImageBlock = styled.img`
  display: inline-block;
  width: 30%;
  aspect-ratio: 1;
  vertical-align: middle;
  padding: 0;

  object-fit: cover;
  object-position: center center;

  @media screen and (max-width: 1000px) {
    width: 100%;
    max-width: 600px;
    margin-bottom: 40px;
  }
`

const Block = ({ title, image, desc }) => {
  const imageBg = image?.src
    ? `${process.env.REACT_APP_URL || ''}/resize/1500/0?image=${
        image?.src
      }`
    : ''

  return (
    <LeftImageRightBlockWrapper>
      {imageBg ? (
        <ImageBlock
          style={image?.position ? { objectPosition: image?.position } : {}}
          className="mediaElement"
          src={imageBg}
          alt={title}
        />
      ) : (
        <div className="filler"></div>
      )}
      <div className="textContentWrapper">
        <TintedBlockH3> {title} </TintedBlockH3>
        <P text={desc} />
      </div>
    </LeftImageRightBlockWrapper>
  )
}
Block.defaultProps = {}
export default Block
