import React from 'react'
import HeadlineCard from '../../modules/headlineCard/headlineCard'
import HeadlineImage from './icon'
import SectionWrapper from '../../../core/modules/sectionBackgroundWrapper'
import Breadcrumbs from '../../modules/breadcrumbs'

const ComplexHeadlineBlock = ({
  // hasBlueDots,
  headline,
  subHeadlines,
  backgroundType,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  direction = 'left',
  icon,
  mediaType,
  image,
  hasBreadcrumbs,
  breadcrumbs,
  //   hasBreadcrumbs = true,
  // breadcrumbs = [
  //   {
  //     linkText: 'vorm',
  //     href: '#',
  //   },
  //   {
  //     linkText: 'over vorm',
  //     href: '#',
  //   },
  //   {
  //     linkText: 'example page',
  //     href: '#',
  //   },
  // ]
}) => {
  const baseSectionData = {
    type: backgroundType,
    paddingType: 'small',
    notFullWidth: true,
  }
  const newProps = { ...baseSectionData }

  return (
    <SectionWrapper
      {...newProps}
      topQuote={topQuote}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
    >
      {breadcrumbs && hasBreadcrumbs && <Breadcrumbs data={breadcrumbs} />}

      {direction === 'left' && mediaType && (
        <HeadlineImage type={mediaType} icon={icon} image={image} />
      )}

      <HeadlineCard mediaType={mediaType} headline={headline} type="large" />

      {direction === 'right' && mediaType && (
        <HeadlineImage type={mediaType} icon={icon} image={image} />
      )}

      <div>
        {subHeadlines &&
          subHeadlines.map &&
          subHeadlines.map((item) => {
            return (
              <HeadlineCard
                siblings={subHeadlines?.length}
                headline={item}
                type="small"
                key={item.title}
              />
            )
          })}
      </div>
    </SectionWrapper>
  )
}
ComplexHeadlineBlock.defaultProps = {}

export default ComplexHeadlineBlock
