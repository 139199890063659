import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

// Core
import { PageWrapper } from '../../../../elements/pageContainer'
// import buildDemoData from './utils'
// import { MenuTitle } from '../../../../elements/typography'
import BuildLinkElement from '../../../../elements/linkBuilder'
import { ReactComponent as CloseIcon } from '../../../../../assets/svg/ic_x.svg'
import { ReactComponent as ArrowRight } from '../../../../../assets/svg/arrow_right.svg'
import { ReactComponent as LogoBlue } from '../../../../../assets/svg/logo_wit.svg'
import Searchbar from '../../../../elements/searchbar'

// Styling
const HeaderMenuWrapper = styled.div`
  width: 100%;
  height: 0 !important;
  overflow: hidden;
  overflow-y: auto;
  opacity: 0;
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
  z-index: 9999999;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease;
  padding-top: 20px;

  .leftContent,
  .rightContent,
  .headerCol {
    display: inline-block;
    vertical-align: top;
  }

  .leftContent {
    width: 75%;
  }

  .rightContent {
    width: 25%;

    @media screen and (max-width: 450px) {
      padding-bottom: 200px;
    }
  }

  .headerCol {
    width: 33.3%;
  }

  .childLinkitem {
    padding-left: 22px;

    span {
      a {
        font-weight: 200 !important;
        font-size: 0.9rem !important;
      }
    }
  }

  .headerCol {
    padding: 30px 0 30px;

    &.noPad {
      width: 100%;
    }
  }

  .topbar {
    display: none;
    min-height: 150px;
    position: relative;

    > svg {
      path,
      line {
        stroke: #005496;
        fill: #005496;
      }
      position: absolute;
      top: 20px;
      left: 0;
    }

    .searchBlock {
      position: absolute;
      top: 20px;
      right: 30px;

      span,
      div {
        display: inline-block;
        vertical-align: middle;
      }

      > span {
        margin-right: 2rem;
      }

      #searchbar {
        margin-right: 3rem;
      }

      @media screen and (max-width: 600px) {
        a {
          display: none;
        }
      }

      svg {
        g {
          .a,
          ellipse {
            stroke: #005496;
          }
        }
        line {
          stroke: #005496;
        }

        overflow: visible;
      }
    }
  }

  &.open {
    opacity: 1;
    transition: opacity 0.5s ease;
    height: 100vh !important;

    .topbar {
      display: block;
    }
  }

  .closeButton {
    cursor: pointer;
  }

  @media screen and (max-width: 1000px) {
    .rightContent,
    .leftContent {
      width: 100%;
      display: block;
    }
  }

  @media screen and (max-width: 880px) {
    .headerCol {
      width: 50%;
    }
  }

  @media screen and (max-width: 680px) {
    .headerCol {
      width: 100%;
    }
  }

  .logoBlue {
    position: relative;
    top: 20px;

    .aLogoWhitVorm {
      fill: #005496 !important;
    }
  }

  span.headerTitleMenu {
    position: relative;
    color: #005496;
    font-size: 1.2rem;
    font-weight: 400;
    margin: 0;
    text-transform: uppercase;
    display: block;
    padding-bottom: 8px;

    a {
      font-size: 1.2rem !important;
    }

    .projectenArrow {
      cursor: pointer;
      width: 50px;
      height: 50px;
      border: 2px solid #0d5080;
      border-radius: 100px;

      position: absolute;
      left: 140px;
      top: -14px;

      svg {
        position: relative;
        top: 0px;
      }
    }
  }

  .linkItem {
    padding-right: 20px;
  }
`

// Header Col item
const HeaderCol = ({ data, noPad }) => {
  return (
    <div className={`headerCol ${noPad ? 'noPad' : ''}`}>
      <span className="headerTitleMenu">
        <BuildLinkElement
          link={{
            href: data?.href,
            linkText: data?.title,
          }}
          linkStyle="MenuLink"
        />

        {data?.showArrow && (
          <div className="projectenArrow">
            <NavLink to={data?.href}>
              {' '}
              <ArrowRight />{' '}
            </NavLink>
          </div>
        )}
      </span>
      {data &&
        data.items &&
        data.items.map &&
        data.items.map((link, index) => (
          <div
            key={`big-menu-link-wrapper-${link.text}-${index}`}
            className="linkItem"
          >
            <BuildLinkElement link={link} linkStyle="MenuLink" />

            {link.children &&
              link.children.map &&
              link.children.map((childLink, index) => (
                <div
                  key={`big-menu-childlink-wrapper-${childLink.text}-${index}`}
                  className="childLinkitem"
                >
                  <BuildLinkElement
                    link={childLink}
                    linkStyle="MenuChildLink"
                  />
                </div>
              ))}
          </div>
        ))}
    </div>
  )
}

// Header Wrapper
const HeaderMenuModule = ({
  open,
  toggleOpen,
  leftMenuContent,
  rightMenuContent,
  noMedia = false,
}) => {
  return (
    <HeaderMenuWrapper className={`${open ? 'open' : ''}`} style={{ background: noMedia ? '#fff' : ''}}>
      <PageWrapper>
        <div className="topbar">
          <NavLink
            to={'/'}
            className="logoBlue"
            onClick={() => toggleOpen(!open)}
          >
            <LogoBlue />
          </NavLink>
          <div className="searchBlock">
            <BuildLinkElement
              link={{ href: '#', linkText: 'Mijn VORM' }}
              linkStyle="MenuLink"
            />
            <Searchbar hide />
            <div className="closeButton" onClick={() => toggleOpen(!open)}>
              <CloseIcon />
            </div>
          </div>
        </div>
        <div className="topContent">
          <div className="leftContent">
            {leftMenuContent &&
              leftMenuContent.map &&
              leftMenuContent.map((item, index) => (
                <HeaderCol
                  key={`child-col-item-${item.title}-${index}`}
                  data={item}
                />
              ))}
          </div>
          <div className="rightContent">
            <HeaderCol data={rightMenuContent} noPad />
          </div>
        </div>
      </PageWrapper>
    </HeaderMenuWrapper>
  )
}
HeaderMenuModule.defaultProps = {}
export default HeaderMenuModule
