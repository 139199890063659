import React from 'react'
import styled from 'styled-components'

import Loader from '../../elements/loader'
import SectionWrapper from '../../modules/sectionBackgroundWrapper'
import DownloadCard from '../../modules/downloadCard'
import { RecentProjectsCardH3, P } from '../../elements/typography'
import CheckboxField from '../../elements/checkBox'
import Pagination from '../../modules/pagination'
// API Driven logic
import { fetchApi } from '../../utils/api/index'
import truncateString from '../../utils/truncateString'
import FilterSidebar from "../../modules/filters/filters";

const buildApiUrl = (state, api, page, initial) => {
  const newApi = `${api}?${state.join()}${initial ? '&initial=true' : ''}`
  const validUrl = newApi.replace(/,/g, '&')
  return validUrl + `&page=${page}`
}

const FilterContainer = styled.div`
  display: block;
  margin-bottom: 4rem;

  h3 {
    font-size: 1rem;
  }

  > label {
    margin-right: 60px;
    span {
      color: #005496;
      font-size: 1.25rem;
      font-weight: bold !important;
    }
  }
`

const AllDownloadsContainer = styled.div`
  display: block;
  h3 {
    font-size: 2.5rem;
  }

  .downloadItem {
    padding-top: 1rem;
    a {
      font-size: 1.25rem;
      font-weight: bold;
      color: #005496;
      text-decoration: none;
      :hover {
        color: #00a1d5;
      }

      &:hover {
        text-decoration: underline;
      }
    }
    p {
      padding: 0.2rem 0 1rem 0;
      font-size: 1rem;
    }
  }
`

// Config for this section
const defaultSectionData = {
  type: 'clearBlueBackground',
  titleType: 'tintedBlockH3',
  paddingType: 'blueDots',
  variant: 'small',
  notFullWidth: true,
}

// Render the section
const DownloadsSection = ({
  data,
  allDownloads,
  filterProps,
  sectionData,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  hasBreadcrumbs,
  breadcrumbs,
  totalPages,
  title,
  desc,
  api,
  showMoreCardInformation,
}) => {
  const newProps = { ...defaultSectionData, ...sectionData }
  const [localState, setLocalState] = React.useState([])
  const [loading, isLoading] = React.useState(true)
  const [initialLoading, isInitialLoading] = React.useState(true)
  const [items, setItems] = React.useState(data)
  const [page, setPage] = React.useState(1)
  const [oldPage, setOldPage] = React.useState(1)
  const [newData, setData] = React.useState(data)
  const [filters, setFilters] = React.useState(filterProps)
  const [source, setDataSource] = React.useState('original')
  const renderData = source === 'search' ? newData : items
  const [newTotalPages, setNewTotalPages] = React.useState(totalPages)
  const [originalPagination, setOriginal] = React.useState(totalPages)
  const paginatedSource =
    source === 'search' ? newTotalPages : originalPagination

  const updateLocalState = (filter) => {
    let newState = [...localState]
    const index = newState.indexOf(filter)
    if (index >= 0) {
      newState.splice(index, 1)
    } else {
      newState.push(filter)
    }

    setPage(1)
    setLocalState(newState)
  }

  React.useEffect(() => {
    if (!items || items.length === 0) {
      const url = buildApiUrl(localState, api, page, true)
      setOldPage(page)
      isLoading(true)
      fetchApi(url).then((data) => {
        setFilters(data.filterProps)
        setData(data.data || [])
        setItems(data.data)
        setNewTotalPages(data.totalPages)
        setOriginal(data.totalPages)
        setDataSource('original')
        isLoading(false)
        isInitialLoading(false)
      })
    } else {
      isLoading(false)
      isInitialLoading(false)
    }
  }, [])

  React.useEffect(() => {
    const url = buildApiUrl(localState, api, page)

    if (localState.length > 0 || page !== oldPage) {
      setOldPage(page)
      isLoading(true)
      fetchApi(url).then((data) => {
        setData(data.data || [])
        setNewTotalPages(data.totalPages)
        setDataSource('search')
        isLoading(false)
      })
    } else {
      setDataSource('original')
      isLoading(false)
    }
  }, [localState, page])

  return (
    <SectionWrapper
      {...newProps}
      hasBreadcrumbs={hasBreadcrumbs}
      topQuote={topQuote}
      breadcrumbs={breadcrumbs}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
      paddingSize="l"
    >
      <FilterContainer>
        <FilterSidebar
          title={title}
          subTitle={desc}
          filters={filters}
          updateLocalState={updateLocalState}
          side={'top'}
        />
      </FilterContainer>

      {(initialLoading || loading) && <Loader />}
      {!initialLoading &&
        !loading &&
        renderData &&
        renderData.map &&
        renderData.map((download, i) => (
          <DownloadCard
            showMoreCardInformation={showMoreCardInformation}
            key={`download-${download.title}-${i}`}
            {...download}
          />
        ))}

      <div>
        {renderData &&
          renderData.length > 0 &&
          paginatedSource &&
          paginatedSource > 1 && (
            <div className="paginationWrapper">
              <Pagination
                count={paginatedSource}
                handleChange={(e, value) => setPage(value)}
                page={page}
              />
              <br /> <br />
            </div>
          )}
      </div>

      {allDownloads && allDownloads.data && allDownloads.data.length > 0 && (
        <AllDownloadsContainer>
          {allDownloads.title && (
            <RecentProjectsCardH3>{allDownloads.title}</RecentProjectsCardH3>
          )}
          {allDownloads &&
            allDownloads.data &&
            allDownloads.data.map((item, i) => (
              <div
                className="downloadItem"
                key={`downloaditem-${item.title}-${i}`}
              >
                <a href={item.href} target="_blank" rel="noreferrer">
                  {item.title}
                </a>
                <P text={truncateString(item.desc, 248)}></P>
              </div>
            ))}
        </AllDownloadsContainer>
      )}
    </SectionWrapper>
  )
}
DownloadsSection.defaultProps = {}
export default DownloadsSection
