import React from 'react'
import styled from 'styled-components'
import useResize from '../../utils/useResize'

import SwipableComponent from '../../modules/swipableProjectCards/v2'
import SectionWrapper from '../../modules/sectionBackgroundWrapper'

import backgroundAsset from '../../../assets/svg/recentprojects_bg.svg'

const Wrapper = styled.div`
  .leftSection {
    h3 {
      z-index: 99;
    }
  }
  padding: 120px 0 40px;
`

const OverlayImage = styled.div`
  height: 200px;
  background: url(${(props) => props.assetImage}) top left no-repeat;
  max-width: 1336px;
  width: 100%;
  position: absolute;
  top: 20px;
  left: 0;
`

// Render the section
const RecentProjects = ({
  data,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  hasBreadcrumbs,
  sectionData,
  breadcrumbs,
}) => {
  const newProps = {
    ...sectionData,
    type: `${topQuote ? 'recentProjectsLongBGQuote' : 'blank'}`,
  }

  // Fullscreen variables
  const [margin, setMargin] = React.useState('0px')
  const containerRef = React.useRef()
  const { width } = useResize()

  let timeout = null
  React.useEffect(() => {
    const { left } = containerRef.current.getBoundingClientRect()
    setMargin(`${left}px`)
  }, [containerRef])

  React.useEffect(() => {
    clearTimeout(timeout)
    if (window.innerWidth <= 1800) {
      setMargin('0px')
      return
    }

    timeout = setTimeout(() => {
      const { left } = containerRef.current.getBoundingClientRect()
      setMargin(`${left}px`)
    }, 100)
  }, [width])

  return (
    <Wrapper>
      <SectionWrapper
        {...newProps}
        hasBreadcrumbs={hasBreadcrumbs}
        topQuote={topQuote}
        topQuoteAuthor={topQuoteAuthor}
        bottomQuote={bottomQuote}
        bottomQuoteAuthor={bottomQuoteAuthor}
        breadcrumbs={breadcrumbs}
      >
        <SwipableComponent data={data} />
      </SectionWrapper>
      <div ref={containerRef}></div>
      {backgroundAsset && (
        <OverlayImage
          style={{ left: `-${margin}` }}
          className="artbackground"
          assetImage={backgroundAsset}
        />
      )}
    </Wrapper>
  )
}
RecentProjects.defaultProps = {
  sectionData: {
    title: '',
    link: {
      linkText: '',
      href: '#',
    },
  },
}
export default RecentProjects
