import React from 'react'
import { withRouter } from 'react-router'
import PageBuilderModule from '../core/page-builder'
import useApi from '../APIs/useApi'

import ErrorPage from '../core/pages/404'

// Helpers
const buildBackupApi = (location) => {
  return `/api${location.pathname}${
    location.search && location.search.length > 0 ? location.search : ''
  }`
}

// Module Wrapper
const PageBuilder = ({ location, api, route }) => {
  const backupApi = buildBackupApi(location)
  const { data, isLoading, isError } = useApi(api || backupApi)
  const { data: response } = data || {}

  const sections = response?.body || []
  const hideNavigator = response?.hideNavigator || undefined
  const breadcrumbs = response?.breadcrumbs || []
  const head = response?.head || []
  const search = response?.search || {}

  if (isLoading) {
    return null
  }

  if (isError) {
    return <ErrorPage />
  }

  return (
    <PageBuilderModule
      sections={sections}
      hideNavigator={hideNavigator}
      head={head}
      search={search}
      breadcrumbs={breadcrumbs}
      isHome={route === 'home'}
    />
  )
}

export default withRouter(PageBuilder)
