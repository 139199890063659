import React from 'react'
import styled from 'styled-components'
// Core
import SectionWrapper from '../../modules/sectionBackgroundWrapper'
import PersonCard from '../../modules/ourPeopleBlock/peopleCard'
import { TintedBlockH3, P } from '../../elements/typography'
import BuildLinkElement from '../../elements/linkBuilder'

// Placeholder image
// import imageElement from '../../../assets/media/person.png'

const HalfBlockLeft = styled.div`
  display: inline-block !important;
  width: 50%;
  vertical-align: ${(props) => (props.centerAlignText ? 'middle' : 'top')};

  padding: ${(props) => (props.contentPadding ? '8rem' : '2rem')} 0 0 0rem;

  h3 {
    height: 4.8rem;
  }

  p {
    padding-bottom: 5rem;
    padding-right: 5rem;

    p {
      padding: 0;
    }
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
    display: block !important;

    p {
      padding-right: 0px;
    }

    margin-bottom: 40px;
    padding: 2rem 0;
  }
`
const HalfBlockRight = styled.div`
  display: inline-block !important;
  width: 50%;
  height: 50%;
  height: ${(props) => (props.contentPadding ? '900px' : '50%')};
  vertical-align: ${(props) => (props.centerAlignText ? 'middle' : 'top')};

  @media screen and (max-width: 1000px) {
    width: 100%;
    display: block !important;
  }
`

const HeadlineWithCards = ({
  title,
  text,
  link,
  cards,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  hasBreadcrumbs,
  breadcrumbs,
  backgroundType,
  cardType,
  centerAlignText,
  contentPadding,
  portraitImage,
}) => {
  const baseSectionData = {
    type: backgroundType,
    paddingType: 'small',
    notFullWidth: true,
  }
  const newProps = { ...baseSectionData }

  return (
    <SectionWrapper
      {...newProps}
      topQuote={topQuote}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
      hasBreadcrumbs={hasBreadcrumbs}
      paddingSize="l"
      breadcrumbs={breadcrumbs}
    >
      <HalfBlockLeft
        centerAlignText={centerAlignText}
        contentPadding={contentPadding}
      >
        <TintedBlockH3>{title}</TintedBlockH3>
        <P text={text} />
        {link?.href && (
          <BuildLinkElement link={link} linkStyle="ReadMoreButton" showArrow />
        )}
      </HalfBlockLeft>
      <HalfBlockRight
        centerAlignText={centerAlignText}
        contentPadding={contentPadding}
      >
        {cards &&
          cards.map &&
          cards.map((person) => {
            return (
              <PersonCard
                person={person}
                noline
                portraitImage={portraitImage}
                type={cardType ? cardType : cards.length > 1 ? 'half' : 'large'}
                key={person.name}
              />
            )
          })}
      </HalfBlockRight>
    </SectionWrapper>
  )
}
HeadlineWithCards.defaultProps = {}
export default HeadlineWithCards
