import React from 'react'
import styled from 'styled-components'

const NieuwsWrapper = styled.div`
  div {
    box-sizing: border-box;
  }

  .mainContainer {
    > div {
      width: 50%;
      display: inline-block;
      vertical-align: top;
      overflow: hidden;

      &:first-child {
        padding-right: 10px;
      }

      &:last-child {
        padding-left: 10px;

        > div {
          width: 100%;
          height: 350px;
          min-height: 260px;

          &:first-child {
            margin-bottom: 20px;
          }

          &:last-child {
            > div {
              width: 50%;
              display: inline-block;
              vertical-align: top;

              &:first-child {
                padding-right: 10px;
              }

              &:last-child {
                padding-left: 10px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1320px) {
    .mainContainer {
      > div {
        width: 100%;
        margin-right: 0;
        padding-right: 0 !important;

        &:last-child {
          padding-top: 20px;
          padding-left: 0px;

          > div {
            width: 100%;
            height: auto;

            &:last-child {
              > div {
                width: 50%;

                &:first-child {
                  padding-right: 0px;
                }

                &:last-child {
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 730px) {
    .mainContainer {
      > div {
        width: 100%;

        &:last-child {
          padding-top: 20px;
          padding-left: 0px;

          > div {
            width: 100%;

            &:last-child {
              > div {
                width: 100%;

                &:first-child {
                  padding: 0;
                  margin: 0;
                  margin-bottom: 20px;
                }

                &:last-child {
                  padding: 0;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
`

const NiewsModule = ({ card1, card2, card3, card4 }) => {
  return (
    <NieuwsWrapper>
      <div className="mainContainer">
        <div> {card1} </div>
        <div>
          <div> {card2} </div>
          <div>
            <div> {card3} </div>
            <div> {card4} </div>
          </div>
        </div>
      </div>
    </NieuwsWrapper>
  )
}

export default NiewsModule
