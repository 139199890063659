import React from 'react'
import styled from 'styled-components'
import BuildLinkElement from '../../elements/linkBuilder'

// Card Wrap

const Wrapper = styled.div`
  display: inline-block !important;
  vertical-align: middle;
  width: 50%;
  height: 100%;

  span {
    padding: 1rem;
    a {
      text-decoration: none;
      font-size: 1rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`

// Element
const IconElement = ({ link }) => {
  return (
    <Wrapper>
      <BuildLinkElement link={link} linkStyle="ReadMoreButton" showArrow />
    </Wrapper>
  )
}
IconElement.defaultProps = {}

export default IconElement
