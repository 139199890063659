import React from 'react'

import SectionWrapper from '../../../core/modules/sectionBackgroundWrapper'
import FeaturedBlock from '../../../core/modules/featuredBlock'
// Config for this section
const defaultSectionData = {
  type: 'clearBlueBackground',
  notFullWidth: true,
}

// Render the section
const RecentProjects = ({
  data,
  sectionData,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  hasBreadcrumbs,
  breadcrumbs,
}) => {
  const newProps = { ...defaultSectionData, ...sectionData }
  return (
    <SectionWrapper
      {...newProps}
      breadcrumbs={breadcrumbs}
      hasBreadcrumbs={hasBreadcrumbs}
      topQuote={topQuote}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
      paddingSize="m"
    >
      <FeaturedBlock {...data} />
    </SectionWrapper>
  )
}
RecentProjects.defaultProps = {}
export default RecentProjects
