import React from 'react'
import styled from 'styled-components'
import ProjectImageCard from '../projectImageCard'

const Wrapper = styled.div`
  padding: 2rem 0;
  width: 100%;
  aspect-ratio: 2;

  .leftWrapper {
    width: 66%;
    height: 100%;
    display: inline-block;
    vertical-align: top;

    div {
      img {
        padding-right: 0.5rem;
      }
    }
  }
  .rightWrapper {
    width: 33%;
    height: 100%;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    line-height: 0;

    div {
      img {
        padding-left: 0.5rem;
      }
      &:first-child {
        padding-bottom: 0.5rem;
      }
      &:last-child {
        padding-top: 0.5rem;
      }
    }
  }
`

// Element
const ServicesBlock = ({ images = [] }) => {
  return (
    <Wrapper>
      <div className="leftWrapper">
        <ProjectImageCard
          key={`image-${images[0]}`}
          image={images[0]}
          type="large"
        />
      </div>
      <div className="rightWrapper">
        <ProjectImageCard
          key={`image-${images[1]}`}
          image={images[1]}
          type="small"
        />
        <ProjectImageCard
          key={`image-${images[2]}`}
          image={images[2]}
          type="small"
        />
      </div>
    </Wrapper>
  )
}
ServicesBlock.defaultProps = {}

export default ServicesBlock
