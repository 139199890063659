import React, { useEffect } from 'react'
import styled from 'styled-components'
import InnerHTML from 'dangerously-set-html-content'

import { SmallHeader, P } from '../../elements/typography'
import ContactForm from './contactForm'
import ErrorComponent from '../../elements/apiError'

// import { ReactComponent as ArrowRight } from "../../../assets/svg/arrow_right.svg";

const Wrapper = styled.div`
  width: 100%;
  padding: 2rem;

  h3 {
    color: #005496;
  }

  @media screen and (max-width: 1550px) {
    padding: 1rem;
  }

  @media screen and (max-width: 1350px) {
    padding: 0rem;
  }
`
const InnerHTMLWrapper = styled.div`
	#repair_form {
		a {
			color: #2E72A8 !important;
		}
		h3 {
			color: #2E72A8 !important;
			font-size: 16px;
			font-family: 'Ekster', sans-serif;
			font-weight: 400;
		}
		.single-text, p, default {
			font-size: 16px;
			font-family: 'Ekster', sans-serif;
			font-weight: 200;
			opacity: .85;
		}
		.form-group {
			position: relative;

		}
		.form-group label {
			line-height: 1;
			font-family: 'Ekster', sans-serif;
			font-size: 14px;
			margin-botton: 4px;
		}
		label.checkbox {
			font-size: 14px;
			font-family: 'Ekster',sans-serif !important;
			-webkit-font-smoothing: antialiased;
			font-weight: 200 !important;
			text-transform: inherit;
			opacity: .85;
			margin-right: 10px;
		}
		td {
			font-size: 16px;
			font-family: 'Ekster', sans-serif;
			font-weight: 200;
			opacity: .85;
			width: 1%;
			white-space: nowrap;
			padding: 1rek;
		}
		.inputwrap, .textareainputwrap {
			color: #2E72A8 !important;
			font-size: 12px;
			font-family: 'Ekster', sans-serif;
			font-weight: 200;
			margin-top: 30px;
			input[type=text], select, input#email, input#email_confirmation {
				border-top: 0px !important;
				border-left: 0px !important;
				border-right: 0px !important;
				border-bottom: 1px solid #6a9ac2 !important;
				color: #6a9ac2;
				padding-left: 0;
				text-indent: 0px;
				width: 100%;
				outline: 0;
				border-radius: 0;
				background: #fff !important;
				-webkit-appearance:none;
			}
			textarea {
				margin-top: 22px;
				border: 1px solid #6a9ac2 !important;
				outline: 0;
				width: 100%;
				min-width: 100%;
				max-width: 100%;
			}
			input {
				border: none;
				outline: 0;
				font-size: 14px;
				box-sizing: content-box;
			}
			input[type=radio] {
				display: none;
			}
		}
		input[type=submit] {
			padding: 22px 42px !important;
			background-color: #00A1D5 !important;
			width: 200px !important;
			text-align: center !important;
			text-indent: 0 !important;
		}
		input[type=submit]:hover {
			background-color: #027da5 !important;
			color: #fff !important;
			-webkit-text-decoration: none !important;
			text-decoration: none !important;
		}
		p.error {
		    display: flex;
		    padding: 0 !important;
		    width: 100%;
		}
`

// Element
const ContactInfo = ({
  title,
  desc,
  html,
  data,
  api,
  errorMessage,
  successTitle,
  successMessage,
  status,
}) => {
  useEffect(() => {
    if (html) {
      let checkbox = document.getElementsByClassName('checkbox')

      for (let index = 0; index < checkbox.length; index++) {
        checkbox[index].style.fontSize = '16px'
        checkbox[index].querySelector('input').style.marginTop = '-1px'
        checkbox[index].querySelector('input').style.verticalAlign = 'middle'
        checkbox[index].querySelector('input').style.width = '20px'
        checkbox[index].querySelector('input').style.height = '20px'
      }

      let radioinputwrap = document.getElementsByClassName('radioinputwrap')
      radioinputwrap[1].style.display = 'flex'
      // for (let index = 0; index < radioinputwrap.length; index++) {

      // }
    }
  }, [])

  return (
    <Wrapper>
      {html ? (
        <>
          {status && status === 'success' && (
            <div className="successMessage">
              <h3> {successTitle} </h3>
              <p> {successMessage} </p>
            </div>
          )}
          {status && status === 'error' && (
            <ErrorComponent message={errorMessage} />
          )}
          <InnerHTMLWrapper>
            <InnerHTML html={html} />
          </InnerHTMLWrapper>
        </>
      ) : (
        <>
          {title && <SmallHeader>{title}</SmallHeader>}
          {desc && <P text={desc} />}
          <ContactForm
            {...data}
            api={api}
            errorMessage={errorMessage}
            successTitle={successTitle}
            successMessage={successMessage}
            status={status}
          />
        </>
      )}
    </Wrapper>
  )
}
ContactInfo.defaultProps = {}

export default ContactInfo
