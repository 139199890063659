import React from 'react'

const BuildImage = ({ image, width, height, imagePosition }) => {
  const newImage = {
    ...image,
  }

  if(imagePosition) {
    newImage.position = imagePosition
  }

  let thesrc = newImage?.src

  delete newImage.position

  if (thesrc && !thesrc.includes('static/')) {
    thesrc = `${process.env.REACT_APP_URL || ''}/resize/${width || '600'}/${
      height || '0'
    }?image=${thesrc}`
  }

  return (
    <img
      {...newImage}
      src={thesrc}
      style={newImage?.position ? { objectPosition: newImage?.position } : {}}
    />
  )
}
export default BuildImage
