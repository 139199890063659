import React from 'react'
// Core
import SectionWrapper from '../../../core/modules/sectionBackgroundWrapper'
import PersonCard from '../../modules/ourPeopleBlock/peopleCard'

// Placeholder image
// import imageElement from '../../../assets/media/person.png'

const defaultSectionData = {
  type: 'clearBlueBackground',
  paddingType: 'clearBlueBackground',
  notFullWidth: true,
}

const OurPeopleBlock = ({
  people,
  sectionData,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  hasBreadcrumbs,
  breadcrumbs,
  portraitImage,
}) => {
  const newProps = { ...defaultSectionData, ...sectionData }
  return (
    <SectionWrapper
      {...newProps}
      topQuote={topQuote}
      breadcrumbs={breadcrumbs}
      topQuoteAuthor={topQuoteAuthor}
      hasBreadcrumbs={hasBreadcrumbs}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
      paddingSize="l"
    >
      {people &&
        people.map &&
        people.map((person) => {
          return (
            <PersonCard
              portraitImage={portraitImage}
              person={person}
              type="quarter"
              key={person.name}
            />
          )
        })}
    </SectionWrapper>
  )
}
OurPeopleBlock.defaultProps = {
  sectionData: {
    type: 'clearBlueBackground',
    paddingType: 'small',
    notFullWidth: true,
  },
}
export default OurPeopleBlock
