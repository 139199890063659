import React from 'react'
import styled from 'styled-components'
import Select from '../../../core/elements/selectBox'

import { RecentProjectsCardH3, SearchFilterH4 } from '../../elements/typography'

const SearchFiltersWrapper = styled.div`
  .filterOptions {
    .optionList {
      padding-top: 2rem;
      .options {
        padding-top: 0.5rem;
        span {
          width: 100%;
          display: block;
          line-height: 1.5rem;
          cursor: pointer;
        }
        .selected {
          font-weight: bold;
        }
      }
    }
  }

  .customselect {
    margin-top: 16px;
    padding: 4px 8px;
    border: 1px solid #005496;

    .MuiSelect-root {
      margin: 0 !important;
      line-height: 23px;
      height: 30px;
      padding-left: 10px;
    }

    .MuiInput-underline:before {
      display: none !important;
    }
  }
`

const SearchFilters = ({
  search,
  filters,
  selectedFilters,
  setSelectedFilters,
}) => {
  const handleSelectedFilters = (option) => {
    let newState = { ...selectedFilters }
    if (newState['category'] === option) {
      return
    }

    if (option && newState['category'] !== option) {
      newState['category'] = option
      setSelectedFilters(newState)
    }

    const currentCategory = option || newState['category']
    let sub = filters.find((item) => {
      let key = Object.keys(item)[0]
      return key == currentCategory
    })

    // Because in the case that there's no extra filters for the currently selected category
    if (sub instanceof Object && currentCategory in sub) {
      sub = sub[currentCategory]
    } else {
      sub = []
    }

    return sub || []
  }
  let [suboptions, setSuboptions] = React.useState(handleSelectedFilters(null))

  // Filter options are the main filters and should always be present (category as of now)
  let filterOptions = []
  Object.keys(search.results).forEach((item) => {
    filterOptions.push(item)
  })
  return (
    <SearchFiltersWrapper>
      <RecentProjectsCardH3>Filters</RecentProjectsCardH3>
      <div className="filterOptions">
        <div className="optionList">
          <SearchFilterH4>Categorieën</SearchFilterH4>
          <div className="options">
            {filterOptions.map((option) => {
              return (
                <span
                  key={`filter-option-category-${option}`}
                  className={`${
                    selectedFilters['category'] == option && 'selected'
                  }`}
                  onClick={() => {
                    const newOption = handleSelectedFilters(option)
                    if (newOption) {
                      setSuboptions(newOption)
                    }
                  }}
                >
                  {option}
                </span>
              )
            })}
          </div>
        </div>
        {suboptions?.length > 0 &&
          suboptions.map((suboption) => {
            return (
              <div className="optionList" key={`option-list-${suboption.key}`}>
                <SearchFilterH4>{suboption.label}</SearchFilterH4>

                {suboption.type === 'select' && (
                  <div className="customselect">
                    <Select
                      options={suboption.values}
                      placeholder="Kies een plaats"
                      value={selectedFilters[suboption.key] || 'none'}
                      handleChange={(e) => {
                        let newState = {
                          ...selectedFilters,
                        }

                        if (newState[suboption.key] === e.target.value) {
                          newState[suboption.key] = null
                          setSelectedFilters(newState)

                          return
                        }
                        newState[suboption.key] = e.target.value
                        setSelectedFilters(newState)
                      }}
                    />
                  </div>
                )}

                {suboption.type !== 'select' &&
                  suboption.values.map((item) => {
                    return (
                      <div
                        className="options"
                        key={`suboption-${suboption.key}-${item.value}`}
                      >
                        <span
                          key={`filter-option-${suboption.key}-${item.value}`}
                          className={`${
                            selectedFilters[suboption.key] == item.value &&
                            'selected'
                          }`}
                          onClick={() => {
                            let newState = {
                              ...selectedFilters,
                            }

                            if (newState[suboption.key] === item.value) {
                              newState[suboption.key] = null
                              setSelectedFilters(newState)

                              return
                            }

                            newState[suboption.key] = item.value
                            setSelectedFilters(newState)
                          }}
                        >
                          {item.label}
                        </span>
                      </div>
                    )
                  })}
              </div>
            )
          })}
      </div>
    </SearchFiltersWrapper>
  )
}

export default SearchFilters
