import React from 'react'
import styled from 'styled-components'

// import SwipableComponent from '../../../core/modules/swipeableVacanciesBlock'
import SectionWrapper from '../../../core/modules/sectionBackgroundWrapper'
import VacancyCard from '../../modules/vacancyCard/vacancyCard'

const Wrapper = styled.div`
  padding: 22px 0;
`

// Config for this section
const defaultSectionData = {
  type: 'blank',
  titleType: 'subHeader',
  notFullWidth: true,
}

// Render the section
const Vacancies = ({
  data,
  sectionData,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
}) => {
  const newProps = { ...defaultSectionData, ...sectionData }
  return (
    <SectionWrapper
      {...newProps}
      topQuote={topQuote}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
      paddingSize="l"
    >
      <Wrapper className="cardContainer">
        {data &&
          data.map &&
          data.map((vacancy, i) => {
            return (
              <VacancyCard
                key={`vacancy-${i}-${vacancy.title}`}
                {...vacancy}
                type="small"
              />
            )
          })}
      </Wrapper>
    </SectionWrapper>
  )
}
Vacancies.defaultProps = {
  sectionData: {},
}
export default Vacancies
