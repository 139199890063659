import React from 'react'
import { TintedBlockH3, P } from '../../elements/typography'
// Core
import SectionWrapper from '../../../core/modules/sectionBackgroundWrapper'
import IconElement from '../../modules/expertiseBlockIcons/expertiseBlockIcon'
// import { useHistory } from 'react-router-dom'
import { BuildLinkWrapper } from '../../elements/linkBuilder'

// svg images
import Image1 from '../../../assets/svg/ic_expertise_1.svg'
import Image2 from '../../../assets/svg/ic_expertise_2.svg'
import Image3 from '../../../assets/svg/ic_expertise_3.svg'
import Image4 from '../../../assets/svg/ic_expertise_4.svg'
import Image5 from '../../../assets/svg/ic_expertise_5.svg'
import Image6 from '../../../assets/svg/ic_expertise_6.svg'

const map = {
  icon1: Image1,
  icon2: Image2,
  icon3: Image3,
  icon4: Image4,
  icon5: Image5,
  icon6: Image6,
}

const defaultSectionData = {
  type: 'blank',
  titleType: 'tintedBlockH3',
  paddingType: 'blank',
}

const ExpertiseBlockWithIcons = ({ icons, title, desc, sectionData }) => {
  const newProps = {
    ...defaultSectionData,
    ...sectionData,
    notFullWidth: true,
  }
  // const history = useHistory()
  // const handleLink = (url) => {
  //   if (url.charAt(0) === '/') {
  //     history.push(url)
  //   } else {
  //     window.location.href = url
  //   }
  // }

  return (
    <SectionWrapper {...newProps}>
      {title && <TintedBlockH3>{title}</TintedBlockH3>}
      {desc && <P text={desc} />}

      {icons &&
        icons.map &&
        icons.map((icon) => {
          return (
            <BuildLinkWrapper link={{ href: icon.href }} key={icon.linkText}>
              <IconElement
                image={map[icon.icon]}
                desc={icon.linkText}
                type="small"
              />
            </BuildLinkWrapper>
          )
        })}
    </SectionWrapper>
  )
}
ExpertiseBlockWithIcons.defaultProps = {}
export default ExpertiseBlockWithIcons
