import React from "react"
import styled from "styled-components";

import Checkbox from "./checkbox/checkbox"
import GroupedCheckboxes from "./checkbox/groupedCheckboxes"
import Select from "./select/select";

const ControlDiv = styled.div`
  // Styling for left sidebar
  &.leftControls {
    display: inline-block;
    vertical-align: top;
    width: 280px;
    padding: 0 20px 0 0;

    @media screen and (max-width: 950px) {
      width: 100%;
      padding: 22px 0 0;
    }

    &.selectWrapper {
      width: 350px;

      @media screen and (max-width: 1300px) {
        width: 300px;
      }
    }

    // Checkbox wrapper styling
    .filterElement {
      display: block;
      padding: 0;
      color: #005496;

      span {
        font-family: 'Ekster', sans-serif !important;
        font-weight: 300 !important;
        font-size: 17px;
        padding-top: 4px;
      }

      .compact {
        span {
          line-height: 1.3;
          padding-top: 0;
          padding-bottom: 0;

          @media screen and (max-width: 1000px) {
            padding-top: 2px;
            padding-bottom: 2px;
          }

          &:not(:first-child) {
            padding-top: 1px;

            @media screen and (max-width: 1000px) {
              padding-top: 4px;
            }
          }
        }
      }

      @media screen and (max-width: 950px) {
        display: inline-block;
        padding-right: 30px;
      }

      @media screen and (max-width: 550px) {
        display: block;
        padding-right: 0;
      }
    }
  }

  // Styling for top bar
  &.topControls {
    .filterElement {
      width: 320px;
      display: inline-block;
      vertical-align: top;

      @media screen and (max-width: 800px) {
        width: 50%;
      }
      @media screen and (max-width: 500px) {
        width: 100%;
      }
    }
  }

  // Select styling
  div.fullControl {
    width: 100%;
    display: block;
    padding: 6px 0;
  }

  div.halfControl {
    width: 50%;
    padding: 6px 0;
    display: inline-block;
    vertical-align: top;

    // TODO: This is SPECIFICALLY styled for the second row according to design. Change in layout requires new styling
    &:nth-of-type(2) {
      padding-right: 4px;
    }

    &:nth-of-type(3) {
      padding-left: 4px;
    }
  }
`

const FilterSidebar = ({ title, subTitle, filters, updateLocalState, currentState, side = 'left' }) => {
  let supported = ['left', 'top'];
  side = supported.includes(side) ? side : 'left';

  if (! filters || filters.length === 0) {
      return null
  }

  // Currently supported:
  // - `filters.grouped` undefined / false: checkbox
  // - `filters.grouped` undefined / false && `filters[0].layoutType` set: select
  // - `filters.grouped` true: grouped checkbox

  if (!filters.grouped) {
    if (filters[0]?.layoutType) {
      return <ControlDiv className={`${side}Controls selectWrapper`}>
        <Select
          title={title}
          subTitle={subTitle}
          filters={filters}
          updateLocalState={updateLocalState}
          currentState={currentState}
        />
      </ControlDiv>
    } else {
      return <ControlDiv className={`${side}Controls`}>
        <Checkbox
          title={title}
          subTitle={subTitle}
          filters={filters}
          updateLocalState={updateLocalState}
        />
      </ControlDiv>
    }
  }

  if(filters.grouped && filters.groups) {
    return <ControlDiv className={`${side}Controls`}>
      <GroupedCheckboxes
        title={title}
        subTitle={subTitle}
        filters={filters}
        updateLocalState={updateLocalState}
      />
    </ControlDiv>
  }

  return null;
}

export default FilterSidebar
