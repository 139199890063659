import React from 'react'

import SwipableComponent from '../../../core/modules/swipableProjectCards/v2'
import SectionWrapper from '../../../core/modules/sectionBackgroundWrapper'

// Config for this section
const defaultSectionData = {
  type: 'clearBlueBackground',
  titleType: 'tintedBlockH3',
  paddingType: 'blueDots',
  variant: 'small',
}

// Render the section
const DownloadsSection = ({
  data,
  sectionData,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  hasBreadcrumbs,
  breadcrumbs,
  showMoreCardInformation,
}) => {
  const newProps = { ...defaultSectionData, ...sectionData }
  return (
    <SectionWrapper
      {...newProps}
      hasBreadcrumbs={hasBreadcrumbs}
      topQuote={topQuote}
      breadcrumbs={breadcrumbs}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
    >
      <SwipableComponent
        showMoreCardInformation={showMoreCardInformation}
        variant={sectionData.variant}
        data={data}
      />
    </SectionWrapper>
  )
}
DownloadsSection.defaultProps = {}
export default DownloadsSection
