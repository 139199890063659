import React from 'react'
import styled from 'styled-components'

// Core
import { P, TintedBlockH3 } from '../../elements/typography'
import { PageWrapper } from '../../elements/pageContainer'
import BuildLinkElement from '../../elements/linkBuilder'
import useResize from '../../utils/useResize'

// Placeholder image
// import imageElement from '../../../assets/media/person.png'

// Module styling
const ExpertiseWrapper = styled.div`
  background: #f7fbfc;
  min-height: 750px;
  background-size: auto 100%;

  .view {
    display: flex;

    > div {
      object-fit: cover;
    }
  }

  .expertiseImage {
    width: 900px;
    overflow: hidden;
    display: inline-block;
    vertical-align: top;

    background-image: url(${(props) =>
      `${`${process.env.REACT_APP_URL || ''}/resize/900/0?image=${
        props.bgArt
      }`}`});
    background-size: cover;
    background-position: ${(props) =>
      props.bgPos ? props.bgPos : 'center center'};
  }

  .textWrapper {
    width: 100%;
    background: #f7fbfc;
    min-height: 750px;
    display: inline-block;
    vertical-align: top;
    max-width: 900px;
    padding: 90px;
    padding-right: 0;

    h3 {
      font-size: 2.5rem;
    }

    .textWrapperIcon {
      width: calc(100% - 150px);
      h3 {
        font-size: 1rem;
      }
    }
  }

  .childElement {
    padding: 50px 0;
  }

  .content {
    box-sizing: border-box;
  }

  @media screen and (max-width: 900px) {
    .view {
      display: block;

      > div {
        object-fit: initial;
      }
    }

    .expertiseImage {
      height: 500px;
      width: 100%;
      margin: 0px !important;
    }

    .textWrapper {
      padding: 50px 0;
    }
  }
`

const ExpertiseComponent = ({ title, image, desc, children, link }) => {
  const [margin, setMargin] = React.useState('0px')
  const containerRef = React.useRef()
  const { width } = useResize()

  React.useEffect(() => {
    if (window.innerWidth <= 1000) {
      setMargin('8vw')
      return
    }

    if (window.innerWidth <= 1280) {
      setMargin('60px')
      return
    }

    if (window.innerWidth <= 2050) {
      setMargin('120px')
      return
    }

    const { left } = containerRef.current.getBoundingClientRect()

    setMargin(`${left}px`)
  }, [width, containerRef])

  return (
    <ExpertiseWrapper
      className="mediaElement"
      bgArt={image?.src}
      bgPos={image?.position}
    >
      <PageWrapper className="content" eleRef={containerRef}>
        <div className="view">
          <div
            className="expertiseImage"
            style={{ marginLeft: `-${margin}` }}
          ></div>

          <div className="textWrapper">
            <div>
              <TintedBlockH3> {title} </TintedBlockH3>
              <P text={desc} />
              <div className="childElement"> {children} </div>

              <BuildLinkElement link={link} linkStyle="ViewAllLink" showArrow />
            </div>
          </div>
        </div>
      </PageWrapper>
    </ExpertiseWrapper>
  )
}
ExpertiseComponent.defaultProps = {}
export default ExpertiseComponent
