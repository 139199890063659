import React from 'react'

import Statement from '../../../core/modules/statement'
import TabBlock from '../../../core/modules/tabBlock'
import SectionWrapper from '../../../core/modules/sectionBackgroundWrapper'

// Config for this section
const defaultSectionData = {
  notFullWidth: true,
  type: 'blank',
  paddingType: 'small',
}

// Render the section
const RecentProjects = ({
  statements,
  sectionData,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  hasBreadcrumbs,
  breadcrumbs,
  defaultTab,
  attachments,
}) => {
  const newProps = { ...defaultSectionData, ...sectionData }

  return (
    <SectionWrapper
      {...newProps}
      hasBreadcrumbs={hasBreadcrumbs}
      topQuote={topQuote}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
      paddingSize="l"
      breadcrumbs={breadcrumbs}
    >
      <TabBlock
        tabs={statements}
        InnerComponent={Statement}
        defaultTab={defaultTab}
      />
    </SectionWrapper>
  )
}
RecentProjects.defaultProps = {
  sectionData: {
    notFullWidth: true,
    type: 'blank',
    paddingType: 'small',
  },
}
export default RecentProjects
