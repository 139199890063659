import React from 'react'
import styled from 'styled-components'
// Core
import SectionWrapper from '../../modules/sectionBackgroundWrapper'
import PersonCard from '../../modules/ourPeopleBlock/peopleCard'
import { TintedBlockH3, P } from '../../elements/typography'
import BuildLinkElement from '../../elements/linkBuilder'

// Placeholder image
// import imageElement from '../../../assets/media/person.png'

const HalfBlockLeft = styled.div`
  display: inline-block !important;
  width: 50%;
  vertical-align: top;

  padding: 2rem 0 0 0rem;

  h3 {
    height: 4.8rem;
  }

  p {
    padding-bottom: 5rem;
    padding-right: 5rem;

    p {
      padding: 0;
    }
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
    display: block !important;

    p {
      padding-right: 0px;
    }

    margin-bottom: 40px;
    padding: 2rem 0;
  }
`
const HalfBlockRight = styled.div`
  display: inline-block !important;
  width: 50%;
  height: 50%;
  vertical-align: top;

  @media screen and (max-width: 1000px) {
    width: 100%;
    display: block !important;
  }
`

const defaultSectionData = {
  type: 'blueDotsLeftToRightBottom',
  paddingType: 'small',
  notFullWidth: true,
}

const HeadlineWithCards = ({
  title,
  text,
  link,
  cards,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  hasBreadcrumbs,
  breadcrumbs,
  portraitImage,
}) => {
  const newProps = { ...defaultSectionData }
  return (
    <SectionWrapper
      {...newProps}
      breadcrumbs={breadcrumbs}
      topQuote={topQuote}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
      hasBreadcrumbs={hasBreadcrumbs}
      hideOverflow
    >
      <HalfBlockLeft>
        <TintedBlockH3>{title}</TintedBlockH3>
        <P text={text} />
        {link?.href && (
          <BuildLinkElement link={link} linkStyle="ReadMoreButton" showArrow />
        )}
      </HalfBlockLeft>
      <HalfBlockRight>
        {cards &&
          cards.map &&
          cards.map((person) => {
            return (
              <PersonCard
                portraitImage={portraitImage}
                person={person}
                type="half"
                key={person.name}
              />
            )
          })}
      </HalfBlockRight>
    </SectionWrapper>
  )
}
HeadlineWithCards.defaultProps = {
  sectionData: {
    type: 'blank',
    paddingType: 'small',
  },
}
export default HeadlineWithCards
