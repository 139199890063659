import React from 'react'

import styled from 'styled-components'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'

const GlobalSelect = styled.div`
  .MuiSelect-selectMenu {
    font-size: 0.75rem;
    color: #6a9ac2;
  }
`

// SelectBox overwrites
const SelectBoxStyling = styled.div`
  label[data-shrink='true'] {
    transform: translate(0, -10px) scale(1) !important;
    transform-origin: top left !important;
    font-weight: 200;
    color: #005496 !important;
  }

  label[data-shrink='false'] {
    transform: translate(0, -10px) scale(1) !important;
    transform-origin: top left !important;
    font-weight: 200;
    color: #005496 !important;
  }
`

const SelectBoxStylingContent = styled.div`
  padding: 28px 0 6px;

  label[data-shrink='true'] {
    transform: translate(0, -22px) scale(1) !important;
    transform-origin: top left !important;
    font-size: 0.8rem;
    font-weight: 400;
    color: #005496 !important;
  }

  label[data-shrink='false'] {
    transform: translate(0, -22px) scale(1) !important;
    transform-origin: top left !important;
    font-size: 0.8rem;
    font-weight: 400;
    color: #005496 !important;
  }

  .variantWrapper {
    border: 1px solid #015797;

    div {
      margin: 0;
      line-height: 30px;
      height: 37px;
    }
  }

  div {
    &:before {
      border-bottom: 0px solid #000 !important;
    }
  }

  [role='button'] {
    padding-left: 8px;
  }
`

// SelectBox
const SelectBox = ({
  options,
  label,
  labelId,
  value,
  handleChange,
  styleVariant,
  placeholder,
  required,
}) => {
  const Wrapper = styleVariant ? SelectBoxStylingContent : SelectBoxStyling

  return (
    <GlobalSelect>
      <Wrapper>
        <FormControl>
          {label && (
            <InputLabel id={labelId}>
              {' '}
              {label} {required ? '*' : ''}{' '}
            </InputLabel>
          )}
          <div className="variantWrapper">
            <Select
              MenuProps={{ disableScrollLock: true }}
              value={value}
              labelId={labelId}
              onChange={handleChange}
            >
              {placeholder && <MenuItem value="none"> {placeholder} </MenuItem>}
              {options &&
                options.map &&
                options.map((item) => (
                  <MenuItem key={item.label} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          </div>
        </FormControl>
      </Wrapper>
    </GlobalSelect>
  )
}
export default SelectBox
