import React from 'react'
import styled from 'styled-components'

import { TintedBlockH3 } from '../../elements/typography'

const ContactSectionWrapper = styled.div`
  .contactSectionBlocks {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;

    .contactSectionWhiteBlock {
      min-height: 550px;
      background: #fff;
      padding: 70px 20px 70px 70px;
      margin-left: 40%;
      box-shadow: 0 0px 70px rgba(0, 0, 0, 0.15);

      ${(props) => props.contactFormStyling}
    }

    .contactSectionBlueBlock {
      position: absolute;
      top: 60px;
      left: 0;
      width: calc(40% + 65px);
      min-height: calc(550px - 140px);
      background: #005496;
      color: #fff;
      padding: 66px 40px;

      ${(props) => props.contactInformationStyling}
    }
  }

  .titleBlock {
    padding-bottom: 30px;
    h3 {
      font-size: 2.5rem;
    }
  }

  @media screen and (max-width: 1300px) {
    .contactSectionBlocks {
      .contactSectionWhiteBlock {
        margin-left: 380px;
        padding: 70px 20px 70px 70px;
      }

      .contactSectionBlueBlock {
        width: 420px;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .contactSectionBlocks {
      .contactSectionBlueBlock,
      .contactSectionWhiteBlock {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        margin: 0;
        min-height: auto;
      }

      .contactSectionWhiteBlock {
        padding: 40px;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .contactSectionBlocks {
      .contactSectionWhiteBlock,
      .contactSectionBlueBlock {
        padding: 40px 20px;
      }
    }
  }
`

const ContactSection = ({
  contactInformation,
  contactForm,
  title,
  contactInformationStyling = '',
  contactFormStyling = '',
}) => {
  return (
    <ContactSectionWrapper
      contactInformationStyling={contactInformationStyling}
      contactFormStyling={contactFormStyling}
    >
      <div className="titleBlock">
        <TintedBlockH3> {title} </TintedBlockH3>
      </div>
      <div className="contactSectionBlocks">
        <div className="contactSectionWhiteBlock">{contactForm}</div>
        <div className="contactSectionBlueBlock">{contactInformation}</div>
      </div>
    </ContactSectionWrapper>
  )
}
ContactSection.defaultProps = {}
export default ContactSection
