import React from 'react'
import styled from 'styled-components'

// Core
// import SelectBox from '../../elements/selectBox'
import Loader from '../../elements/loader'
import { PageWrapper } from '../../elements/pageContainer'
import NewsCard from './news.card'
// import { RecentProjectsCardH3 } from '../../elements/typography'
import Pagination from '../pagination'
import QuoteElement from '../../elements/quote'
import PaddingWrapper from '../../elements/paddingContainer'
// import CheckboxField from '../../elements/checkBox'
import Breadcrumbs from '../../modules/breadcrumbs'

// API Driven logic
import { fetchApi } from '../../utils/api/index'

const buildApiUrl = (state, api, page, initial) => {
  const newApi = `${api}?tags=${state}${initial ? '&initial=true' : ''}`
  const validUrl = newApi.replace(/,/g, '&')
  return validUrl + `&page=${page}`
}

// Styling
const NewsWrapper = styled.div`
  background: #f7fbfc;

  h3 {
    margin-bottom: 20px;
  }

  .noresults {
    padding: 80px 0;
    text-align: center;
  }

  .leftControls,
  .rightContent,
  .halfControl {
    display: inline-block;
    vertical-align: top;
  }

  .newsFilterContainer {
    display: block;
    padding: 0px 0;
    color: #005496;

    span {
      font-family: 'Ekster', sans-serif !important;
      font-weight: 400 !important;
    }
  }

  .rightContent {
    width: calc(100% - 350px);
    padding-left: 0px;
  }

  .fullControl {
    width: 100%;
    display: block;
  }

  .halfControl {
    width: 50%;

    &:nth-child(2) {
      padding-right: 4px;
    }

    &:nth-child(3) {
      padding-left: 4px;
    }
  }

  .cardContainer {
    padding: 0px 0px 40px;
    display: block;
    vertical-align: top;
    border-bottom: 1px solid #005496;
    margin-bottom: 40px;

    p {
      padding: 0;
      margin: 0;
    }

    span {
      padding-top: 12px;
      display: block;
    }

    &:last-child {
      border: 0;
    }
  }

  @media screen and (max-width: 950px) {
    .cardContainer,
    .leftControls,
    .rightContent {
      width: 100% !important;
      padding: 22px 0px !important;
    }

    .newsFilterContainer {
      display: inline-block;
      padding-right: 30px;
    }
  }

  .tagsContainer {
    padding: 22px 0;
    min-height: 40px;
    line-height: 40px;
    margin-bottom: 30px;

    strong,
    span {
      padding: 0 8px 0 0;
    }

    span {
      display: inline-block;
      padding: 12px 6px;

      &:hover {
        color: #005496;
        text-decoration: underline;
      }

      &.active {
        color: #005496;
        font-weight: bold;
      }
    }

    strong {
      color: #005496;
      margin-right: 20px;
    }
  }

  .titleBlock {
    color: #005496;
    padding-bottom: 22px;
  }

  .cardContainer {
    .podcasts {
      span {
        display: inline !important;
      }
    }
  }
`

//  module
const PubSubNewsModule = ({
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  api,
  data,
  totalPages,
  hasBreadcrumbs,
  breadcrumbs,
  tags,
  // hasBreadcrumbs = true,
  // breadcrumbs = [
  //   {
  //     linkText: 'vorm',
  //     href: '#',
  //   },
  //   {
  //     linkText: 'over vorm',
  //     href: '#',
  //   },
  //   {
  //     linkText: 'example page',
  //     href: '#',
  //   },
  // ]
}) => {
  // The initial tag should have a selected value if it needs to be any other tag than the first.
  // This value is used to set up the initial item set, which will be used to reset to.
  const initialTag = tags.filter((tag) => tag.selected)[0] || tags[0]
  const [localState, setLocalState] = React.useState(initialTag)
  const [loading, isLoading] = React.useState(false)
  // The items define the initial data set. However, if data is empty, we will set it from the initial
  // API call.
  const [items, setItems] = React.useState(data)
  const [page, setPage] = React.useState(1)
  const [oldPage, setOldPage] = React.useState(1)
  const [newData, setData] = React.useState(data)
  const [newTotalPages, setNewTotalPages] = React.useState(totalPages)
  const [source, setDataSource] = React.useState('original')
  const renderData = source === 'search' ? newData : items
  const [originalPagination, setOriginal] = React.useState(totalPages)
  const paginatedSource =
    source === 'search' ? newTotalPages : originalPagination

  React.useEffect(() => {
    // Apply an "initial" API call if no data is present
    if (!data || data.length === 0) {
      const url = buildApiUrl(localState.value, api, page, true)
      isLoading(true)
      setOldPage(page)

      fetchApi(url).then((data) => {
        // Perhaps redundant due to the fact that this data set won't be triggered from this data source
        setData(data.data)
        setItems(data.data)
        setNewTotalPages(data.totalPages)
        setOriginal(data.totalPages)
        setDataSource('original')
        isLoading(false)
      })
    }
  }, [])

  React.useEffect(() => {
    if (localState !== initialTag || page !== oldPage) {
      const url = buildApiUrl(localState.value, api, page)
      isLoading(true)
      setOldPage(page)

      fetchApi(url).then((data) => {
        setData(data.data)
        setNewTotalPages(data.totalPages)
        setDataSource('search')
        isLoading(false)
      })
    } else {
      setDataSource('original')
      isLoading(false)
    }
  }, [localState, page, oldPage])

  return (
    <NewsWrapper>
      <PaddingWrapper size="l" hasBreadcrumbs={hasBreadcrumbs}>
        <PageWrapper className="contentArea">
          {breadcrumbs && hasBreadcrumbs && <Breadcrumbs data={breadcrumbs} />}
          {topQuote && topQuote?.length > 0 && (
            <QuoteElement quote={topQuote} top author={topQuoteAuthor} />
          )}

          <div className="rightContent">
            {loading && <Loader />}
            {!loading && (
              <div>
                <div className="tagsContainer">
                  <strong> Tags:</strong>
                  {tags.map((tag) => (
                    <span
                      className={localState === tag ? 'active' : ''}
                      onClick={() => setLocalState(tag)}
                      key={tag.label}
                    >
                      {tag.label}
                    </span>
                  ))}
                </div>

                {renderData &&
                  renderData.length > 0 &&
                  renderData.map((item, index) => {
                    return (
                      <div key={`PubSubNews-item-${item.title}-${index}`}>
                        <div className="titleBlock">
                          {' '}
                          <strong>
                            {' '}
                            {item.title} met {localState.label}{' '}
                          </strong>{' '}
                        </div>
                        {item.data &&
                          item.data.map((pitem, index2) => (
                            <div
                              className="cardContainer"
                              key={`PubSubNews-item-${index2}-${pitem.title}-${index}`}
                            >
                              <NewsCard {...pitem} />
                            </div>
                          ))}
                      </div>
                    )
                  })}
                {renderData &&
                  renderData.length > 0 &&
                  paginatedSource &&
                  paginatedSource > 1 && (
                    <div className="paginationWrapper">
                      <Pagination
                        count={paginatedSource}
                        handleChange={(e, value) => setPage(value)}
                        page={page}
                      />
                    </div>
                  )}

                {(!renderData || renderData.length === 0) && (
                  <div className="noresults">
                    Er zijn geen items gevonden met dit criterium
                  </div>
                )}
              </div>
            )}
          </div>

          {bottomQuote && bottomQuote?.length > 0 && (
            <QuoteElement
              quote={bottomQuote}
              bottom
              author={bottomQuoteAuthor}
            />
          )}
        </PageWrapper>
      </PaddingWrapper>
    </NewsWrapper>
  )
}

// PLaceholder props
PubSubNewsModule.defaultProps = {}
export default PubSubNewsModule
