import React from 'react'
import styled from 'styled-components'

const TextArea = styled.div`
  label,
  textarea {
    display: block;
  }

  label {
    color: #005496;
    font-size: 0.8rem;
    font-weight: 200;
    padding-bottom: 8px;
  }

  textarea {
    outline: 0;
    border: 1px solid #5c90bb;
    padding: 6px;
    min-height: 100px;
    width: 100%;
    margin-bottom: 22px;
    box-sizing: border-box;
  }

  box-sizing: border-box;
`

const TextAreaComponent = ({ label, value, handleChange, id }) => {
  return (
    <TextArea>
      <label htmlFor={id}> {label} </label>
      <textarea id="id" value={value} onChange={handleChange}></textarea>
    </TextArea>
  )
}
TextAreaComponent.defaultProps = {
  id: Date.now(),
}
export default TextAreaComponent
