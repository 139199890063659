import React from 'react'
// Core
import PijlerCard from '../../modules/pijlerCard/pijlerCard'
// Placeholder image
// import imageElement from '../../../assets/media/building.png'
import PaddingWrapper from '../../elements/paddingContainer'
import QuoteElement from '../../elements/quote'
import Breadcrumbs from '../../modules/breadcrumbs'

const PijlerBlock = ({
  pijlers,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  hasBreadcrumbs,
  breadcrumbs,
}) => {
  return (
    <PaddingWrapper
      size="l"
      hasQuoteTop={topQuote && topQuote?.length > 0}
      hasQuoteBottom={bottomQuote && bottomQuote?.length > 0}
      hasBreadcrumbs={hasBreadcrumbs}
    >
      {breadcrumbs && hasBreadcrumbs && <Breadcrumbs data={breadcrumbs} />}

      {topQuote && topQuote?.length > 0 && (
        <QuoteElement quote={topQuote} top author={topQuoteAuthor} />
      )}

      {pijlers &&
        pijlers.map &&
        pijlers.map((pijler) => {
          return <PijlerCard pijler={pijler} key={pijler.title} />
        })}

      {bottomQuote && bottomQuote?.length > 0 && (
        <QuoteElement quote={bottomQuote} bottom author={bottomQuoteAuthor} />
      )}
    </PaddingWrapper>
  )
}
PijlerBlock.defaultProps = {
  pijlers: [],
}
export default PijlerBlock
