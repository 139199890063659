import React from 'react'
import styled from 'styled-components'

// Core
// import SelectBox from '../../elements/selectBox'
import Loader from '../../elements/loader'
import { PageWrapper } from '../../elements/pageContainer'
import NewsCard from './news.card'
import {ButtonInversed, QuoteH2} from '../../elements/typography'
import QuoteElement from '../../elements/quote'
import PaddingWrapper from '../../elements/paddingContainer'
import BuildLinkElement from '../../elements/linkBuilder'
import Breadcrumbs from '../../modules/breadcrumbs'
import FilterSidebar from "../filters/filters"

// API Driven logic
import { fetchApi } from '../../utils/api/index'

const buildApiUrl = (state, api, page, initial) => {
  const newApi = `${api}?${state.join()}${initial ? '&initial=true' : ''}`
  const validUrl = newApi.replace(/,/g, '&')
  return validUrl + `&page=${page}`
}

// Styling
const NewsWrapper = styled.div`
  background: #f7fbfc;

  @media screen and (min-width: 1000px) {
    h2 {
      margin-bottom: 20px;
    }
  }

  .noresults {
    padding: 80px 0;
    text-align: center;
  }

  .rightContent {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 280px);
    padding-left: 20px;

    ${props => !props.filters || props.filters.length === 0 ? 'padding-left: 0;' : ''}
  }

  .cardContainer {
    padding: 0 0 40px 0;
    display: block;
    vertical-align: top;

    &:first-child {
      padding-top: 0;
    }

    span {
      display: block;

      &.podcast-icon {
        display: inline;

        span {
          display: inline;
        }
      }
    }
  }

  @media screen and (max-width: 950px) {
    .cardContainer,
    .rightContent {
      width: 100%;
      padding: 0;
    }
  }

  .linkActueelWrapper {
    padding-top: 20px 0 !important;
  }

  .pinnedCardItem {
    width: calc(100% / 3);
    padding: 0 20px 0 0;
    display: inline-block !important;
    vertical-align: top;

    .linkActueelWrapper {
      padding-top: 20px !important;
    }

    @media screen and (max-width: 1000px) {
      width: 100%;
      padding: 10px 0;
    }
  }

  .contentBlockActueel {
    > div {
      padding-bottom: 30px;

      @media screen and (max-width: 1000px) {
        padding: 10px 0;
      }
    }
  }

  .breadcrumbWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

      div:first-child {
        margin-right: 1rem;
      }

      .buttonContainer {
          /* Align with image */
          padding-right: 20px;

          @media screen and (max-width: 550px) {
            margin: 0.5rem 0;
          }
      }
  }
`

//  module
const ActueelModule = ({
  filterProps,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  api,
  data,
  hasBreadcrumbs,
  breadcrumbs,
  title,
  subTitle,
  showMoreCardInformation,
  pinned
}) => {
  const [localState, setLocalState] = React.useState([])
  const [loading, isLoading] = React.useState(true)
  const [initialLoading, isInitialLoading] = React.useState(true)
  const [items, setItems] = React.useState(data)
  const [page, setPage] = React.useState(1)
  const [filters, setFilters] = React.useState(filterProps)
  const [oldPage, setOldPage] = React.useState(1)
  const [newData, setData] = React.useState(data)

  const [source, setDataSource] = React.useState('original')
  const renderData = source === 'search' ? newData : items

  const updateLocalState = (filter) => {
    let newState = [...localState]
    const index = newState.indexOf(filter)
    if (index >= 0) {
      newState.splice(index, 1)
    } else {
      newState.push(filter)
    }

    setPage(1)
    setLocalState(newState)
  }

  React.useEffect(() => {
    if (!items || items.length === 0) {
      const url = buildApiUrl(localState, api, page, true)
      isLoading(true)
      setOldPage(page)

      fetchApi(url).then((data) => {
        setData(data.data)
        setItems(data.data)
        setFilters(data.filterProps)
        setDataSource('original')
        isLoading(false)
        isInitialLoading(false)
      })
    } else {
      isLoading(false)
      isInitialLoading(false)
    }
  }, [])

  React.useEffect(() => {
    if (localState.length > 0 || page !== oldPage) {
      const url = buildApiUrl(localState, api, page)
      isLoading(true)
      setOldPage(page)

      fetchApi(url).then((data) => {
        setData(data.data)
        setDataSource('search')
        isLoading(false)
      })
    } else {
      setDataSource('original')
      isLoading(false)
    }
  }, [localState, page, oldPage])

  return (
    <NewsWrapper showMoreCardInformation={showMoreCardInformation}>
      <PaddingWrapper size="l" hasBreadcrumbs={hasBreadcrumbs}>
        <PageWrapper className="contentArea">
          <div className="breadcrumbWrapper">
            {breadcrumbs && hasBreadcrumbs && <Breadcrumbs data={breadcrumbs} />}

            <div className="buttonContainer">
              <ButtonInversed style={{backgroundColor: '#005496', marginTop: 0, marginRight: 0}}
                onClick={() => {
                  window.open(
                    'https://c.spotler.com/ct/m11/k1/MnutjUjCxgBkwZZI0lUY7ACMy7tvnEQm_QuRRdK-zMehWbc96z13V1z7xTdDSjcj/m3SjU3IUXscq8Gy',
                    '_blank'
                  )
                }}
              >
                Nieuws ontvangen? Klik hier!
              </ButtonInversed>
            </div>
          </div>

          {topQuote && topQuote?.length > 0 && (
            <QuoteElement quote={topQuote} top author={topQuoteAuthor} />
          )}

          <FilterSidebar
            title={title}
            subTitle={subTitle}
            filters={filters}
            updateLocalState={updateLocalState}
            side={'left'}
          />
          <div className="rightContent">

            {
              pinned && pinned.data && <div className='cardContainer'>
                <QuoteH2> {pinned.title} </QuoteH2>
                {
                  pinned.data.map((item, index) => {
                    return <div className='pinnedCardItem' key={'pinned-item' + index}>
                      <NewsCard
                        fullWidth
                        dontGoToDetailPage={pinned.dontGoToDetailPage}
                        {...item}
                      />

                      <div className="linkActueelWrapper">
                          {item?.globalLink?.href && (
                            <BuildLinkElement
                              link={item.globalLink}
                              linkStyle="ViewAllLink"
                              showArrow
                            />
                          )}
                        </div>
                    </div>
                  })
                }
              </div>
            }

            {(initialLoading || loading) && <Loader />}

            {!initialLoading && !loading && (
              <div>
                {renderData &&
                  renderData.length > 0 &&
                  renderData.map((sectionDataE, index) => (
                    <div
                      key={`Actueel-sectionDataE-${index}`}
                      className="cardContainer"
                    >
                      <QuoteH2> {sectionDataE.title} </QuoteH2>
                      <div className="contentBlockActueel">
                        {sectionDataE.data &&
                          sectionDataE.data.map((item, index) => (
                            <NewsCard
                              key={`actueel-${sectionDataE.title}-card-${index}`}
                              dontGoToDetailPage={sectionDataE.dontGoToDetailPage}
                              {...item}
                            />
                          ))}

                        <div className="linkActueelWrapper">
                          {!sectionDataE.dontGoToDetailPage && sectionDataE.link && sectionDataE.link?.href && (
                            <BuildLinkElement
                              link={sectionDataE.link}
                              linkStyle="ViewAllLink"
                              showArrow
                            />
                          )}
                        </div>

                        {(!sectionDataE || sectionDataE.length === 0) && (
                          <div className="noresults">
                            Er zijn geen items gevonden met dit criterium
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>

          {bottomQuote && bottomQuote?.length > 0 && (
            <QuoteElement
              quote={bottomQuote}
              bottom
              author={bottomQuoteAuthor}
            />
          )}
        </PageWrapper>
      </PaddingWrapper>
    </NewsWrapper>
  )
}

// PLaceholder props
ActueelModule.defaultProps = {}
export default ActueelModule
