import React from 'react'
import styled from 'styled-components'

import { useFetchApiState } from '../../utils/api/apiStateContext'
import InputField from '../../elements/inputField'
import SelectBox from '../../elements/selectBox'
import TextArea from '../../elements/textarea'
import CheckboxField from '../../elements/checkBox'
import { ButtonInversed } from '../../elements/typography'
import { postApi } from '../../utils/api/index'
import { ReactComponent as ArrowRight } from '../../../assets/svg/arrow_right.svg'
import Loader from '../../elements/loader'
import ErrorContainer from '../../elements/apiError'

const Wrapper = styled.div`
  width: 100%;
  padding: 0px 40px;

  @media screen and (max-width: 800px) {
    padding: 20px;
  }

  .fieldContainer {
    width: 48%;
    display: inline-block;
    vertical-align: bottom;
    &:nth-child(odd) {
      margin-right: 2%;
    }
    &:nth-child(even) {
      margin-left: 2%;
    }

    @media screen and (max-width: 800px) {
      width: 100%;

      margin: 12px 0 !important;
    }
  }
  .textAreaContainer {
    padding-top: 1.5rem;

    textarea {
      box-sizing: border-box !important;
    }
  }

  .checkboxContainer {
    width: 60%;
    display: inline-block;
    color: #74a2c6;

    .MuiFormControlLabel-label {
      font-size: 0.8rem !important;
      color: #2e72a8 !important;
    }

    span span {
      width: 15px;
      height: 15px;
      border-radius: 30px;
      border: 2px solid #74a2c6;

      svg {
        display: none;
      }
    }

    .Mui-checked {
      span {
        background: #74a2c6;
        box-shadow: inset 0px 0px 0px 2px #fff;
      }
    }
  }
  .buttonContainer {
    width: 40%;
    display: inline-block;
  }

  @media screen and (max-width: 800px) {
    .checkboxContainer,
    .buttonContainer {
      width: 100%;
      display: block;
    }

    .checkboxContainer {
      padding-top: 22px;
    }
  }

  .errorContainer {
    color: red;
    padding-top: 25px;
  }
`

const RenderField = ({ field, state, handleChange }) => {
  const fields = {
    field: InputField,
    select: SelectBox,
    textarea: TextArea,
    email: InputField,
  }

  const inputProps = { ...field }
  delete inputProps.validation
  delete inputProps.type

  const Element = fields[field.type]

  return (
    <Element
      {...inputProps}
      value={state[field.name] || null}
      required={field?.validation?.required}
      handleChange={handleChange}
    />
  )
}

// Element
const ContactInfo = ({
  fields = [],
  newsletter = {},
  api = '',
  errorMessage,
  successTitle,
  successMessage,
}) => {
  const [state, setState] = React.useState({
    [newsletter.name]: newsletter.value,
  })
  const [errorsState, setErrors] = React.useState([])
  const [submitting, setIsSubmitting] = React.useState(false)
  const [apiError, setApiError] = React.useState(false)
  const [success, showSuccess] = React.useState(false)
  const [errorInfo, setErrorInfo] = React.useState(errorMessage)
  const { csrfToken, CSRFTokenHeader } = useFetchApiState()

  const fieldUpdate = (field, value) => {
    setState({ ...state, [field.name]: value })
  }

  const handleSubmit = () => {
    setIsSubmitting(true)

    const errors = []
    fields.forEach((field) => {
      const isRequired = field?.validation?.required
      const isLength = field?.validation?.length > 0

      // Numeric validation?

      if (
        isRequired &&
        isLength &&
        (!state[field.name] ||
          state[field.name].length < field?.validation?.length)
      ) {
        // Won't validate if field isn't required
        errors.push({
          field: field.name,
          label: field.label,
          error: `moet ${field?.validation?.length} tekens of meer hebben`,
        })
      } else if (isRequired && field?.validation?.type === 'email') {
        // Won't validate if email isn't required
        const value = state[field.name]
        const valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          value
        )

        if (!valid) {
          errors.push({
            field: field.name,
            label: field.label,
            error: `moet een geldig e-mailadres zijn`,
          })
        }
      } else if (isRequired && !state[field.name]) {
        errors.push({
          field: field.name,
          label: field.label,
          error: `is een verplicht veld`,
        })
      }
    })

    setErrors(errors)

    if (errors.length === 0) {
      postApi(api, state, { [CSRFTokenHeader]: csrfToken })
        .then(() => {
          setIsSubmitting(false)
          setApiError(false)
          showSuccess(true)
        })
        .catch((error) => {
          console.error(error)

          if (typeof error.json === 'function') {
            error.json().then((response) => {
              if (response.errorMessage) {
                setErrorInfo(response.errorMessage)
              } else {
                // No message set, use default
                setErrorInfo(errorMessage)
              }
            })
          } else {
            // Ensure we reset just in case
            setErrorInfo(errorMessage)
          }

          setApiError(true)
          setIsSubmitting(false)
        })
    } else {
      setIsSubmitting(false)
    }
  }

  return (
    <Wrapper>
      {!submitting && !success && (
        <>
          {fields &&
            fields?.length > 0 &&
            fields.map &&
            fields.map((field) => {
              return (
                <div
                  key={field.name}
                  className={
                    field.type !== 'textarea'
                      ? 'fieldContainer'
                      : 'textAreaContainer'
                  }
                >
                  <RenderField
                    field={field}
                    state={state}
                    handleChange={(e) => fieldUpdate(field, e.target.value)}
                  />
                </div>
              )
            })}
          <div className="checkboxContainer">
            {newsletter && (
              <CheckboxField
                {...newsletter}
                checked={state[newsletter.name] || false}
                handleChange={() =>
                  fieldUpdate(newsletter, !state[newsletter.name])
                }
              />
            )}
          </div>

          <div className="buttonContainer">
            <ButtonInversed
              onClick={() => {
                handleSubmit()
              }}
            >
              Verzend <ArrowRight />
            </ButtonInversed>
          </div>
        </>
      )}

      {success && (
        <div className="successMessage">
          <h3> {successTitle} </h3>
          <p> {successMessage} </p>
        </div>
      )}

      {submitting && <Loader />}

      {apiError && <ErrorContainer message={errorInfo} />}
      {errorsState && errorsState.length > 0 && (
        <div className="errorContainer">
          <strong> Fout: </strong> Voer geldige informatie in voor de velden:
          <div>
            {errorsState.map((error) => (
              <div key={error.field} className="errorPoint">
                <strong> {error.label} </strong> {error.error}
              </div>
            ))}
          </div>
        </div>
      )}
    </Wrapper>
  )
}
ContactInfo.defaultProps = {}

export default ContactInfo
