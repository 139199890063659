import useFetchAppState from '../core/utils/api/useFetchAppState'
// import { useHistory } from "react-router-dom";

const url = process.env.REACT_APP_URL || ''

// Hook
const useApi = (api) => {
  // Payload is the API State response, it has the data as well as
  // functions to perform GET, PUT, PUSH, DELETE requests for this endpoint
  const payload = useFetchAppState(api, `${url}${api}`)
  // console.log('api', api, payload)
  return payload
}
export default useApi
