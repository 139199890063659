import React from 'react'

// Components
import SectionWrapper from '../../modules/sectionBackgroundWrapper'
import Right from '../../modules/expertiseHeadlineBlockRight'
import Left from '../../../core/modules/expertiseHeadlineBlock'

// View
const ExpertiseHeadlineSectionWrapper = (props) => {
  const {
    backgroundType,
    paddingType,
    notFullWidth,
    titleType,
    topQuote,
    topQuoteAuthor,
    bottomQuote,
    bottomQuoteAuthor,
    hasBreadcrumbs,
    breadcrumbs,
    direction = 'left',
  } = props

  const baseSectionData = {
    type: backgroundType,
    titleType: titleType,
    paddingType,
  }

  const newProps = { ...baseSectionData }
  const Section = direction === 'right' ? Right : Left

  return (
    <SectionWrapper
      {...newProps}
      notFullWidth
      hasBreadcrumbs={hasBreadcrumbs}
      topQuote={topQuote}
      breadcrumbs={breadcrumbs}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
      notFullWidthMobile
      dontRenderWidthContainer
    >
      {Section && <Section {...props} isFullScreen={!notFullWidth} />}
    </SectionWrapper>
  )
}
export default ExpertiseHeadlineSectionWrapper
