import React from 'react'

import SwipableComponent from '../../../core/modules/swipableProjectCards/v2'
import SectionWrapper from '../../../core/modules/sectionBackgroundWrapper'

// Config for this section
// TODO -Where is this section in storybook?
const defaultSectionData = {
  type: 'blank',
}

// Render the section
const RecentProjects = ({
  data,
  sectionData,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  hasBreadcrumbs,
  breadcrumbs,
  showMoreCardInformation,
}) => {
  const newProps = { ...defaultSectionData, ...sectionData }
  return (
    <SectionWrapper
      {...newProps}
      hasBreadcrumbs={hasBreadcrumbs}
      topQuote={topQuote}
      breadcrumbs={breadcrumbs}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
    >
      <SwipableComponent
        showMoreCardInformation={showMoreCardInformation}
        data={data}
        variant={'small'}
      />
    </SectionWrapper>
  )
}
RecentProjects.defaultProps = {
  sectionData: {
    quote: 'Recente projecten',
    data: [],
  },
}
export default RecentProjects
