import React from 'react'

import { PageWrapper } from '../../../core/elements/pageContainer'
import Contact from '../../../core/modules/contactBlock'
import ContactInfo from '../../../core/modules/contactBlock/contactInfo'
import ContactForm from '../../../core/modules/contactBlock/contactForm'
import PaddingWrapper from '../../elements/paddingContainer'
import QuoteElement from '../../elements/quote'
import Breadcrumbs from '../../modules/breadcrumbs'

const ContactSection = (props) => {
  const {
    topQuote,
    topQuoteAuthor,
    bottomQuote,
    bottomQuoteAuthor,
    hasBreadcrumbs,
    breadcrumbs,
    api,
    errorMessage,
    successTitle,
    successMessage,
  } = props

  return (
    <PaddingWrapper
      size="m"
      hasQuoteTop={topQuote && topQuote?.length > 0}
      hasQuoteBottom={bottomQuote && bottomQuote?.length > 0}
      hasBreadcrumbs={hasBreadcrumbs}
    >
      <PageWrapper>
        {breadcrumbs && hasBreadcrumbs && <Breadcrumbs data={breadcrumbs} />}

        {topQuote && topQuote?.length > 0 && (
          <QuoteElement quote={topQuote} top author={topQuoteAuthor} />
        )}
        <Contact
          {...props}
          contactInformation={
            <ContactInfo contact={props.contactInformation} />
          }
          contactForm={
            <ContactForm
              {...props.contactForm}
              api={api}
              errorMessage={errorMessage}
              successTitle={successTitle}
              successMessage={successMessage}
            />
          }
        />
        {bottomQuote && bottomQuote?.length > 0 && (
          <QuoteElement quote={bottomQuote} bottom author={bottomQuoteAuthor} />
        )}
      </PageWrapper>
    </PaddingWrapper>
  )
}
ContactSection.defaultProps = {
  title: '',
  contactInformation: {
    contact: { phone: 'phone', fax: 'fax', email: 'email' },
  },
}
export default ContactSection
