import React from 'react'
import styled from 'styled-components'

// import BuildImage from '../../elements/imageBuilder'

// svg images
import Image1 from '../../../assets/svg/ic_expertise_1.svg'
import Image2 from '../../../assets/svg/ic_expertise_2.svg'
import Image3 from '../../../assets/svg/ic_expertise_3.svg'
import Image4 from '../../../assets/svg/ic_expertise_4.svg'
import Image5 from '../../../assets/svg/ic_expertise_5.svg'
import Image6 from '../../../assets/svg/ic_expertise_6.svg'

const map = {
  icon1: Image1,
  icon2: Image2,
  icon3: Image3,
  icon4: Image4,
  icon5: Image5,
  icon6: Image6,
}

// Card Wrap
const Card = styled.img`
  max-width: calc(100% - 4rem);
  max-height: 100%;
  overflow: hidden;
  padding: 0 4rem;
  width: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
`

const Wrapper = styled.div`
  display: inline-block !important;
  vertical-align: top;
  width: 33%;
  height: 100%;
  overflow: hidden;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  img {
    width: calc(100% - 4rem);

    @media screen and (max-width: 1300px) {
      padding: 0 40px;
      width: 100%;
    }

    @media screen and (max-width: 1300px) {
      display: block;
      width: 100%;
      max-width: 380px;
      max-height: 400px !important;
    }
  }
`

// Element
const CardImageElement = ({ type, icon = 'icon1', image = {} }) => {
  return (
    <Wrapper>
      {type === 'icon' && <Card src={map[icon]} alt="" />}
      {type === 'image' && <Card {...image} />}
    </Wrapper>
  )
}
CardImageElement.defaultProps = {}

export default CardImageElement
