import React from 'react'
import styled from 'styled-components'
import { RecentProjectsCardH3 } from '../../elements/typography'
import { BuildLinkWrapper } from '../../elements/linkBuilder'

// Card Wrap
const Icon = styled.img`
  max-height: 50px;
  display: inline-block;
  vertical-align: middle;
  padding: 0 0px 0 0;
  width: 95px;
`

const Wrapper = styled.div`
  display: inline-block !important;
  width: 50%;
  height: 100%;
  min-width: 150px;
  padding-bottom: 4rem;

  .textWrapperIcon {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    width: calc(100% - 95px);

    h3 {
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 1300px) {
    width: 100%;
    display: block !important;
  }
`

// Element
const IconElement = ({ icon, desc, href }) => {
  return (
    <Wrapper>
      <BuildLinkWrapper link={{ href }}>
        <Icon src={icon?.src || icon} alt="" />
        <div className="textWrapperIcon">
          <RecentProjectsCardH3>{desc}</RecentProjectsCardH3>
        </div>
      </BuildLinkWrapper>
    </Wrapper>
  )
}
IconElement.defaultProps = {}

export default IconElement
