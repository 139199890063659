import React from 'react'
import styled from 'styled-components'

// import { PageWrapper } from '../../../core/elements/pageContainer'
import FAQ from '../../../core/modules/faqBlock'
import SectionWrapper from '../../modules/sectionBackgroundWrapper'

import QuoteElement from '../../elements/quote'

const FAQWrapper = styled.div``

const defaultSectionData = {
  type: 'blank',
  notFullWidth: true,
}

const FaqSection = (props) => {
  const {
    topQuote,
    topQuoteAuthor,
    bottomQuote,
    bottomQuoteAuthor,
    hasBreadcrumbs,
    breadcrumbs,
  } = props
  const newProps = { ...defaultSectionData, ...props.sectionData }
  return (
    <SectionWrapper
      size="m"
      breadcrumbs={breadcrumbs}
      hasQuoteTop={topQuote && topQuote?.length > 0}
      hasQuoteBottom={bottomQuote && bottomQuote?.length > 0}
      hasBreadcrumbs={hasBreadcrumbs}
      {...newProps}
    >
      <FAQWrapper>
        {topQuote && topQuote?.length > 0 && (
          <QuoteElement quote={topQuote} top author={topQuoteAuthor} />
        )}
        <FAQ {...props} />
        {bottomQuote && bottomQuote?.length > 0 && (
          <QuoteElement quote={bottomQuote} bottom author={bottomQuoteAuthor} />
        )}
      </FAQWrapper>
    </SectionWrapper>
  )
}
FaqSection.defaultProps = {}
export default FaqSection
