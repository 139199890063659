import React from 'react'
import styled from 'styled-components'
import { RecentProjectsCardH3 } from '../../elements/typography'
import ProjectServiceCard from '../projectServiceCard'

// svg images
import Image1 from '../../../assets/svg/ic_expertise_1.svg'
import Image2 from '../../../assets/svg/ic_expertise_2.svg'
import Image3 from '../../../assets/svg/ic_expertise_3.svg'
import Image4 from '../../../assets/svg/ic_expertise_4.svg'
import Image5 from '../../../assets/svg/ic_expertise_5.svg'
import Image6 from '../../../assets/svg/ic_expertise_6.svg'

const map = {
  icon1: Image1,
  icon2: Image2,
  icon3: Image3,
  icon4: Image4,
  icon5: Image5,
  icon6: Image6,
}

const Wrapper = styled.div`
  padding: 2rem 0;
  width: 100%;
  height: 100%;
`

// Element
const ServicesBlock = ({ services = [], title }) => {
  return (
    <Wrapper>
      <RecentProjectsCardH3>{title}</RecentProjectsCardH3>
      {services &&
        services?.length > 0 &&
        services.map &&
        services.map((service) => {
          // let image = map[`icon${(i % 6) + 1}`]
          return (
            <ProjectServiceCard
              key={service.linkText}
              image={map[service.icon]}
              service={service.linkText}
              link={service.href}
            />
          )
        })}
    </Wrapper>
  )
}
ServicesBlock.defaultProps = {}

export default ServicesBlock
