import React from 'react'
import styled from 'styled-components'

import InnerHTML from 'dangerously-set-html-content'

import { PageWrapper } from '../../elements/pageContainer'

// Core
import { P, TintedBlockH3 } from '../../elements/typography'

// ICONS
import apple from './icons/apple.png'
import google from './icons/google.png'
import spotify from './icons/spotify.png'
import podcast from './icons/podcast.png'

// Module styling
const PodcastsDetails = styled.div`
  .textContentWrapper {
    display: block !important;
    padding: 0;
    vertical-align: middle;

    @media screen and (min-width: 1000px) {
      padding: 80px 0;
    }

    .textWrapper {
      .titleWrapper {
        line-height: 1.3;
        min-height: 3rem;

        @media screen and (min-width: 1000px) {
          line-height: 4.2rem;
        }

        h3 {
          font-size: 1.8rem;

          @media screen and (min-width: 1000px) {
            line-height: 2.5rem;
          }
        }
      }
    }
  }

  .linkWrapper {
    display: block;
    vertical-align: top;
    padding-top: 40px;
  }

  .introWrapper {
    * {
      color: #005496;
      font-weight: bold;
    }
  }

  .listenPostcastOn {
    > div {
      display: inline-block;
      margin-right: 20px;

      color: #005496;
      font-weight: bold;
      font-size: 1.2em;

      padding-bottom: 30px;

      @media screen and (max-width: 728px) {
        padding-bottom: 0;
      }

      &:nth-child(2) {
        position: relative;
        top: 8px;

        @media screen and (max-width: 728px) {
          padding-bottom: 30px;
        }
      }
    }
  }
`

const PodcastIcons = styled.div`
  img {
    margin-right: 8px;
    cursor: pointer;

    ${props => props.compact ? 'margin-top: 6px; width: 20px;' : 'width: 30px;'}
  }

  .listenToText {
    font-size: 0.9em;
    padding-right: 8px;
    font-weight: bold;
    position: relative;
    top: -5px;
    color: #005496;
  }
`

const iconMap = {
  spotify: <img src={spotify} alt='spotify' />,
  apple: <img src={apple} alt='apple' />,
  google: <img src={google} alt='google' />,
}

const undefinedIcon = <img src={podcast} alt='podcast' />

export const PodcastsIcons = ({ links, compact, showText }) => {
  if(!links || !links.length) {
    return <div></div>
  }

  return <PodcastIcons compact={compact} className='podcasts'>
    {showText ? <span className='listenToText'> Beluister: </span> : ''}
    {
      links.map(podcastLink => {
        return <span key={podcastLink.name} className='podcast-icon' onClick={() => {
          window.open(podcastLink.href, '_blank')
        }}>
          <span> {iconMap[podcastLink.name] || undefinedIcon} </span>
        </span>
      })
    }
  </PodcastIcons>
}

const PodcastsDetailsModule = ({
  title,
  intro,
  desc,
  embed,
  podcastLinks
}) => {
  return (
    <PodcastsDetails>
      <PageWrapper>
        <div>
          <div className="textContentWrapper">
            <div className="textWrapper">
              <div className="titleWrapper">
                <TintedBlockH3>
                  {title}
                </TintedBlockH3>
              </div>
              {podcastLinks && podcastLinks.length && <div className='listenPostcastOn'>
                <div>
                  Beluister de podcast hier:
                </div>
                <div>
                  <PodcastsIcons links={podcastLinks} />
                </div>
              </div>}
              <div className='introWrapper'>
                <P text={intro} />
              </div>
              <div className='embedWrapper'>
                {embed && <InnerHTML
                  html={embed}
                />}
              </div>
              <P text={desc} />
            </div>
          </div>

          {podcastLinks && podcastLinks.length && <div className='listenPostcastOn'>
            <div>
              Beluister de podcast hier:
            </div>
            <div>
              <PodcastsIcons links={podcastLinks} />
            </div>
          </div>}
        </div>
      </PageWrapper>
    </PodcastsDetails>
  )
}
PodcastsDetails.defaultProps = {}
export default PodcastsDetailsModule
