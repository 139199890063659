import React from 'react'
import styled from 'styled-components'

import { NavLink } from 'react-router-dom'

const MenuWrapper = styled.nav`
  ul,
  li {
    list-style: none;
  }

  li {
    display: inline-block;
    vertical-align: top;
  }

  a {
    color: #fff;
    text-decoration: none;
    display: block;
    padding: 10px 22px;
    font-weight: 400 !important;
    text-transform: capitalize;
    font-size: 0.84rem;

    &:hover {
      text-decoration: underline;
    }

    &:hover {
      color: #02a1d5;
    }
  }

  .listWrapper {
    margin: 10px 0;
  }
`

// Child component
const NavItem = ({ text, exact = true, to }) => {
  return (
    <li>
      <NavLink activeClassName="activeMenu" exact={exact} to={to}>
        {text}
      </NavLink>
    </li>
  )
}

// Main component
const MenuComponent = ({ links }) => {
  return (
    <MenuWrapper>
      <ul className="listWrapper">
        {links &&
          links.map &&
          links.map((link) => {
            const linkItem = {
              ...link,
              to: `${link.to.charAt(0) == '/' ? '' : '/'}${link.to}`,
            }
            return <NavItem key={`main-menu-link-${link.text}`} {...linkItem} />
          })}
      </ul>
    </MenuWrapper>
  )
}
export default MenuComponent
