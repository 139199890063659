import React from 'react'

// Core
import SectionWrapper from '../../modules/sectionBackgroundWrapper'
import LeftImageBlock from '../../modules/leftImageRightDesc'

// Placeholder image
// import imageElement from '../../../assets/media/person.png'

const defaultSectionData = {
  type: 'blank',
  paddingType: 'blank',
  notFullWidth: true,
  titleType: 'tintedBlockH3',
}

const ExpertiseBlockWithIcons = ({
  testimonial,
  sectionData,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
}) => {
  const newProps = {
    ...defaultSectionData,
    ...sectionData,
    notFullWidth: true,
  }
  return (
    <SectionWrapper
      {...newProps}
      topQuote={topQuote}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
    >
      <LeftImageBlock {...testimonial} />
    </SectionWrapper>
  )
}
ExpertiseBlockWithIcons.defaultProps = {}

export default ExpertiseBlockWithIcons
