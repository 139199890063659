// External libraries
import React, { useRef } from 'react'

import { TransitionGroup, CSSTransition } from 'react-transition-group'

import { Switch, Route, useLocation } from 'react-router-dom'

// Routes
import PageBuilder from './pages/pageBuilder'

// Mock Api Call
// import mockMetaApiCall from './mocks/_meta'
import useApi from './APIs/useApi'

// Router
let timeout
const ApplicationRouter = () => {
  const pageReference = useRef()
  let location = useLocation()
  const [newLocation, setLocation] = React.useState(location)
  const { isLoading } = useApi('/api/_meta')

  React.useEffect(() => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setLocation(location)
    }, 30)
  }, [location])

  React.useEffect(() => {
    if (pageReference.current && pageReference.current.scrollIntoView) {
      pageReference.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [pageReference, newLocation])

  if (isLoading) {
    return null
  }

  return (
    <div ref={pageReference}>
      <TransitionGroup>
        <CSSTransition key={newLocation.key} classNames="page" timeout={700}>
          <Switch location={newLocation}>
            {/* Slash route - home */}
            <Route path="/" exact>
              <div className="page">
                <PageBuilder route={'home'} api={'/api/'} />
              </div>
            </Route>

            {/* Fallback routes for all routes not in exacts */}
            <Route key={`router-route-backup`}>
              <div className="page">
                <PageBuilder route={null} api={null} />
              </div>
            </Route>
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  )
}
export default ApplicationRouter
