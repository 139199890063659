import React from 'react'

import BigAlternatingColorTextBlock from '../../modules/bigAlternatingColorTextBlock'
// import SectionWrapper from '../../modules/sectionBackgroundWrapper'

// const defaultSectionData = {
//   type: 'blank',
//   notFullWidth: true,
// }

const Section = (props) => {
  // const newProps = { ...defaultSectionData, ...props.sectionData }
  return <BigAlternatingColorTextBlock {...props} />
}
Section.defaultProps = {}
export default Section
