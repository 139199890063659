import React from 'react'
import styled from 'styled-components'

// import { PageWrapper } from '../../../core/elements/pageContainer'
import FAQ from '../../../core/modules/faqBlock'
import SectionWrapper from '../../modules/sectionBackgroundWrapper'

const FAQWrapper = styled.div``

const FaqSection = (props) => {
  const {
    topQuote,
    topQuoteAuthor,
    bottomQuote,
    bottomQuoteAuthor,
    hasBreadcrumbs,
    breadcrumbs,
    backgroundType,
    image,
  } = props
  const baseSectionData = {
    type: backgroundType,
    notFullWidth: true,
  }

  const newProps = { ...baseSectionData }
  return (
    <SectionWrapper
      {...newProps}
      size="m"
      breadcrumbs={breadcrumbs}
      hasQuoteTop={topQuote && topQuote?.length > 0}
      hasQuoteBottom={bottomQuote && bottomQuote?.length > 0}
      hasBreadcrumbs={hasBreadcrumbs}
      topQuote={topQuote}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
    >
      <FAQWrapper>
        <FAQ {...props} image={image} />
      </FAQWrapper>
    </SectionWrapper>
  )
}
FaqSection.defaultProps = {}
export default FaqSection
