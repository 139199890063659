import React from 'react'
import styled from 'styled-components'

import { TintedBlockH3 } from '../../elements/typography'

const BlockWrapper = styled.div`
  background: #005496;
  color: #fff;
  width: 100%;
  height: 230px;
  display: table !important;
  box-sizing: border-box;
  padding: 0px 25px;

  > div {
    display: table-cell;
    vertical-align: middle;
  }

  h3 {
    color: #fff;
    text-align: center;
    font-size: 2.5rem;
    line-height: 2.875rem;
  }
`

const BlockComponent = ({ text }) => {
  return (
    <BlockWrapper>
      <div>
        <TintedBlockH3> {text} </TintedBlockH3>
      </div>
    </BlockWrapper>
  )
}
export default BlockComponent
