import React from 'react'

// Core
import SectionWrapper from '../../modules/sectionBackgroundWrapper'
import ContactBlock from '../../modules/contactBlock'
import EmergencyContact from '../../modules/emergencyContactCard'
import EmergencyContactForm from '../../modules/emergencyContactForm'

// Styling
const contactInformationStyling = `

`

const contactFormStyling = `
  min-height: 960px;
`

// Section
const defaultSectionData = {
  type: 'blank',
  paddingType: 'blank',
  notFullWidth: true,
  titleType: 'tintedBlockH3',
}

const ExpertiseBlockWithIcons = ({
  blockInfo,
  sectionData,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  errorMessage,
  api,
  successTitle,
  successMessage,
  status,
}) => {
  const newProps = {
    ...defaultSectionData,
    ...sectionData,
    notFullWidth: true,
  }
  return (
    <SectionWrapper
      {...newProps}
      topQuote={topQuote}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
    >
      <ContactBlock
        contactInformation={<EmergencyContact {...blockInfo.contact} />}
        contactInformationStyling={contactInformationStyling}
        contactForm={
          <EmergencyContactForm
            {...blockInfo.form}
            api={api}
            errorMessage={errorMessage}
            successTitle={successTitle}
            successMessage={successMessage}
            status={status}
          />
        }
        contactFormStyling={contactFormStyling}
      />
    </SectionWrapper>
  )
}
ExpertiseBlockWithIcons.defaultProps = {}

export default ExpertiseBlockWithIcons
