import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

// Components
import SectionWrapper from '../../modules/sectionBackgroundWrapper'
import { P, TintedBlockH3 } from '../../elements/typography'
import useResize from '../../utils/useResize'

// Placeholder image
// import imageElement from '../../../assets/media/person.png'

// Module styling
const PublicationsWrapper = styled.div`
  .textContentWrapper {
    width: calc(100% - 535px);
    display: inline-block;
    padding: 80px 80px 80px 0px;
    vertical-align: middle;
    .textWrapper {
      .titleWrapper {
        h3 {
          font-size: 2.5rem;
          padding-bottom: 32px;
        }
      }
    }
  }

  .readmoreHolder {
    color: #005496;
    font-weight: 400;
    padding-top: 40px;

    img {
      height: 38px;
      position: relative;
      top: 12px;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  strong {
    color: #005496;
    padding: 22px 0;
  }

  .linkWrapper {
    display: block;
    vertical-align: top;
    padding-top: 40px;
  }

  @media screen and (max-width: 1280px) {
    .textContentWrapper {
      width: calc(100% - 335px);
      padding: 80px 0px 140px 60px;
    }
  }

  @media screen and (max-width: 1000px) {
    .textContentWrapper {
      width: 100%;
      padding: 80px 0;
    }
  }
`

const ImageBlock = styled.img`
  display: inline-block;
  width: calc(535px + ${(props) => props.imageMarginOffset});
  vertical-align: middle;
  max-height: 480px;
  object-fit: cover;
  object-position: center center;
  margin-right: -${(props) => props.imageMarginOffset};

  @media screen and (max-width: 1280px) {
    width: calc(323px + ${(props) => props.imageMarginOffset});
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
    margin: 0;
  }
`

const ImageBlockSmall = styled.img`
  display: inline-block;
  width: calc(400px + ${(props) => props.imageMarginOffset});
  vertical-align: middle;
  max-height: 400px;
  object-fit: cover;
  object-position: center center;
  margin-right: -${(props) => props.imageMarginOffset};
  float: right;

  @media screen and (max-width: 1280px) {
    width: calc(323px + ${(props) => props.imageMarginOffset});
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
    margin: 0;
  }
`

const map = {
  small: ImageBlockSmall,
  large: ImageBlock,
}

const Right = ({
  title,
  image,
  desc,
  imageType = 'large',
  isFullScreen,
  meta,
}) => {
  const Image = map[imageType]

  // Fullscreen variables
  const [margin, setMargin] = React.useState('0px')
  const containerRef = useRef()
  const { width } = useResize()

  useEffect(() => {
    if (!isFullScreen) {
      return
    }

    if (window.innerWidth <= 1000) {
      setMargin('8vw')
      return
    }

    if (window.innerWidth <= 1280) {
      setMargin('60px')
      return
    }

    if (window.innerWidth <= 2050) {
      setMargin('120px')
      return
    }

    const { left } = containerRef.current.getBoundingClientRect()
    setMargin(`${left}px`)
  }, [width, containerRef, isFullScreen])

  return (
    <PublicationsWrapper ref={containerRef}>
      <div className="textContentWrapper">
        <div className="textWrapper">
          <div className="titleWrapper">
            <TintedBlockH3> {title} </TintedBlockH3>
          </div>
          <strong> {meta} </strong>
          <P dontCrop text={desc} />
        </div>
      </div>

      {image?.src && (
        <Image
          className="mediaElement"
          imageMarginOffset={margin}
          src={`${process.env.REACT_APP_URL || ''}/resize/680/0/?image=${
            image.src
          }`}
          alt=""
          style={image?.position ? { objectPosition: image?.position } : {}}
        />
      )}
    </PublicationsWrapper>
  )
}

// View
const PublicationIntro = (props) => {
  const {
    paddingType,
    topQuote,
    topQuoteAuthor,
    bottomQuote,
    bottomQuoteAuthor,
    hasBreadcrumbs,
    breadcrumbs,
    goToNextSection,
  } = props

  const baseSectionData = {
    type: 'clearBlueBackground',
    notFullWidth: false,
    paddingType,
  }

  const newProps = { ...baseSectionData }
  const Section = Right

  return (
    <SectionWrapper
      {...newProps}
      breadcrumbs={breadcrumbs}
      hasBreadcrumbs={hasBreadcrumbs}
      topQuote={topQuote}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
      notFullWidthMobile
    >
      {Section && (
        <Section {...props} isFullScreen goToNextSection={goToNextSection} />
      )}
    </SectionWrapper>
  )
}
export default PublicationIntro
