import React from 'react'
import styled from 'styled-components'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share'

import { BuildLinkWrapper } from '../../elements/linkBuilder'

// Components
import SectionWrapper from '../../modules/sectionBackgroundWrapper'
import { P } from '../../elements/typography'
import BuildImage from '../../elements/imageBuilder'
import VideoBlock from '../../modules/vimeoBlock'
import QuoteElement from '../../elements/quote'

// Assets
import { ReactComponent as FacebookLogo } from '../../sections/footer/icons/facebook.svg'
import { ReactComponent as LinkedinLogo } from '../../sections/footer/icons/linkedin.svg'
import { ReactComponent as TwitterLogo } from '../../sections/footer/icons/twitter.svg'

import downloadIcon from './assets/download.png'

const PublicationsWrapper = styled.div`
  .textContainer,
  .halfBlock {
    width: 50%;
    display: inline-block;
    vertical-align: top;

    strong {
      color: #005496;
      margin-right: 20px;
    }
  }

  .textContainer {
    padding-right: 40px;
  }

  @media screen and (max-width: 1000px) {
    .textContainer,
    .halfBlock {
      width: 100%;
      display: block;
      padding: 30px 0;
    }
  }

  .fullBlock {
    padding: 30px 0;
    display: block;
    width: 100%;
  }

  .tagsContainer {
    padding: 22px 0;
    height: 40px;
    line-height: 40px;

    strong,
    span {
      padding: 0 8px 0 0;
    }

    strong {
      color: #005496;
      margin-right: 20px;
    }
  }

  .shareContainer {
    padding: 22px 0;
    strong {
      height: 40px;
      color: #005496;
      line-height: 40px;
      margin-right: 20px;
    }

    img {
      margin: 0 8px;
      height: 40px;
      position: relative;
      top: 12px;
      cursor: pointer;
    }
  }

  .downloadsContainer {
    padding: 22px 0;
    strong {
      height: 40px;
      color: #005496;
      line-height: 40px;
      margin-right: 20px;
    }

    > span {
      display: inline-block;
      padding: 0 8px 6px;
      line-height: 20px;
      margin: 12px;
      border: 1px solid rgba(0, 85, 150, 0.17);

      &:hover {
        border: 1px solid #005496;
      }

      > span {
        display: inline-block;
        padding: 0px 12px;
        font-size: 0.8rem;
      }

      span {
        position: relative;
        top: -4px;
      }

      img {
        position: relative;
        top: 6px;
      }
    }

    img {
      margin: 0 8px;
      height: 30px;
    }
  }

  > div:nth-child(2) {
    img {
      width: 80%;
      margin: 0 0 0 20%;

      @media screen and (max-width: 1020px) {
        width: 100%;
        margin: 0;
      }
    }
  }
`

// View
const VacanciesSectionWrapper = (props) => {
  // const history = useHistory()
  const {
    backgroundType,
    titleType,
    hasBreadcrumbs,
    sectionData,
    breadcrumbs,
    mediaType,
    image,
    video,
    showShare,
    showTags,
    tags,
    topQuote,
    topQuoteAuthor,
    bottomQuote,
    bottomQuoteAuthor,
    textBlocks,
    showDownloads,
    downloads,
  } = props

  const baseSectionData = {
    type: backgroundType || 'blank',
    titleType: titleType,
    notFullWidth: true,
  }

  const multiBlocks = textBlocks.length > 1
  const newProps = { ...baseSectionData, ...sectionData }

  let title = textBlocks[0].title || 'VORM publicatie'

  return (
    <SectionWrapper
      {...newProps}
      hasBreadcrumbs={hasBreadcrumbs}
      title={''}
      subtitle={''}
      topQuote={topQuote}
      topQuoteAuthor={topQuoteAuthor}
      breadcrumbs={breadcrumbs}
    >
      <PublicationsWrapper>
        {textBlocks &&
          textBlocks.length > 0 &&
          textBlocks.map((text, index) => (
            <div key={`text-block-${index}`} className="textContainer">
              <strong> {text.title} </strong>
              <P dontCrop text={text.text} />
            </div>
          ))}
        {mediaType && mediaType === 'image' && (
          <div
            className={`imageHolder ${multiBlocks ? 'fullBlock' : 'halfBlock'}`}
          >
            <BuildImage image={image} />
          </div>
        )}
        {mediaType && mediaType === 'video' && (
          <div
            className={`videoHolder ${multiBlocks ? 'fullBlock' : 'halfBlock'}`}
          >
            <VideoBlock video={video} />
          </div>
        )}

        {bottomQuote && bottomQuote?.length > 0 && (
          <QuoteElement
            quote={bottomQuote}
            bottom
            nopad
            givespacing
            author={bottomQuoteAuthor}
          />
        )}

        {showShare && (
          <div className="shareContainer">
            <strong> Share </strong>
            <FacebookShareButton url={window.location.href} title={title}>
              <FacebookLogo className="shareicon" />
            </FacebookShareButton>
            <LinkedinShareButton url={window.location.href} title={title}>
              <LinkedinLogo className="shareicon" />
            </LinkedinShareButton>
            <TwitterShareButton url={window.location.href} title={title}>
              <TwitterLogo className="shareicon" />
            </TwitterShareButton>
          </div>
        )}

        {showTags && (
          <div className="tagsContainer">
            <strong> Tags:</strong>
            {tags.map((tag) => (
              <span key={tag.linkText}>
                <BuildLinkWrapper link={tag}>{tag.linkText}</BuildLinkWrapper>
              </span>
            ))}
          </div>
        )}

        {showDownloads && (
          <div className="downloadsContainer">
            <strong> Downloads:</strong>
            {downloads.map((download) => (
              <span key={download.linkText}>
                <BuildLinkWrapper link={download}>
                  <span> {download.linkText} </span>{' '}
                  <img src={downloadIcon} alt="download" />
                </BuildLinkWrapper>
              </span>
            ))}
          </div>
        )}
      </PublicationsWrapper>
    </SectionWrapper>
  )
}
export default VacanciesSectionWrapper
