import React from 'react'
import styled from 'styled-components'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'

const PaginationWrapper = styled.div`
  button {
    border-radius: 0px !important;
    padding: 0px !important;
    width: 40px !important;
    height: 40px !important;
    line-height: 22px !important;
    text-align: center !important;
    font-family: 'Ekster', sans-serif;
    -webkit-font-smoothing: antialiased;
    border: 1px solid #005496;
    color: #005496 !important;
    font-weight: 400;
    display: block !important;
    line-height: 41px !important;
    margin: 0 6px !important;
  }

  button:hover,
  [aria-current='true'] {
    background: #005496 !important;
    color: #fff !important;
  }
`

const PaginationRounded = ({ count, handleChange, page }) => {
  return (
    <PaginationWrapper>
      <Stack spacing={2}>
        <Pagination
          onChange={handleChange}
          count={count}
          variant="outlined"
          hideNextButton
          hidePrevButton
          shape="rounded"
          page={page}
        />
      </Stack>
    </PaginationWrapper>
  )
}
PaginationRounded.defaultProps = {
  count: 1,
  handleChange: () => {},
}
export default PaginationRounded
