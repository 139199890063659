import React from 'react'

import SectionWrapper from '../../../core/modules/sectionBackgroundWrapper'
import ProjectImages from '../../modules/projectImages'
import ProjectCharacteristicsBlock from '../../modules/projectCharacteristics'
import ProjectServices from '../../modules/projectServices'

// Config for this section
const defaultSectionData = {
  type: 'blank',
  paddingType: 'small',
  notFullWidth: true,
}

// Render the section
const RecentProjects = ({
  data,
  serviceIcons,
  sectionData,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  hasBreadcrumbs,
  breadcrumbs,
}) => {
  const serviceData = data.services || {
    services: serviceIcons,
    title: data.title,
  }
  const newProps = { ...defaultSectionData, ...sectionData }
  return (
    <SectionWrapper
      {...newProps}
      topQuote={topQuote}
      topQuoteAuthor={topQuoteAuthor}
      hasBreadcrumbs={hasBreadcrumbs}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
      paddingSize="l"
      breadcrumbs={breadcrumbs}
    >
      {serviceData && <ProjectServices {...serviceData} />}
      {data.characteristics && (
        <ProjectCharacteristicsBlock {...data.characteristics} />
      )}
      {data.images && <ProjectImages images={data.images} />}
    </SectionWrapper>
  )
}
RecentProjects.defaultProps = {
  sectionData: {
    title: '',
    link: {
      href: '',
      linkText: '',
    },
  },
}
export default RecentProjects
