import React from 'react'
import styled from 'styled-components'

import { P, TintedBlockH3 } from '../../elements/typography'
import BuildLinkElement from '../../elements/linkBuilder'

const AllCard = styled.div`
  width: 100%;
  background-color: #00a1d5;
  overflow: hidden;
  width: 420px;
  min-height: 500px;

  ${(props) =>
    props.variant === 'small' ? `width: 260px; min-height: 420px;` : ``}

  .bg-wrapper {
    position: relative;
    height: 100%;
    min-height: 500px;
    ${(props) =>
      props.variant === 'small' ? `width: 260px; min-height: 420px;` : ``}
  }

  h3 {
    color: #fff !important;
    padding: 1rem;
  }

  span {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 1rem;

    a {
      color: #fff !important;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
    svg g {
      path,
      line {
        stroke: #fff;
      }
    }
  }

  @media screen and (max-width: 800px) {
    width: 33%;
  }

  @media screen and (max-width: 650px) {
    width: 50%;
  }

  @media screen and (max-width: 400px) {
    width: 100%;
  }
`

// Module
const CardModule = ({ title, desc, link = {}, variant }) => {
  return (
    <AllCard variant={variant}>
      <div className="bg-wrapper">
        <TintedBlockH3> {title} </TintedBlockH3>
        {desc && <P text={desc} />}
        <BuildLinkElement link={link} linkStyle="ReadMoreButton" showArrow />
      </div>
    </AllCard>
  )
}
CardModule.defaultProps = {}
export default CardModule
