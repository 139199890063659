import React from 'react'
import styled from 'styled-components'

import { PageWrapper } from '../../../core/elements/pageContainer'
import NewsWrapper from '../../../core/modules/niuewsBlock'
import Card from '../../../core/modules/newsCard/newsCard'
import CardAlle from '../../../core/modules/newsCard/newsCardAlle'
import PaddingWrapper from '../../elements/paddingContainer'
import QuoteElement from '../../elements/quote'
import Breadcrumbs from '../../modules/breadcrumbs'
import { TintedBlockH3 } from '../../elements/typography'

const NewsWrapperEle = styled.div`
  .titleArea {
    padding: 0 0 22px 0;
  }
`

const NewsSection = ({
  cards,
  title = 'Nieuws',
  alleCard,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
  hasBreadcrumbs,
  breadcrumbs,
  // hasBreadcrumbs = true,
  // breadcrumbs = [
  //   {
  //     linkText: 'vorm',
  //     href: '#',
  //   },
  //   {
  //     linkText: 'over vorm',
  //     href: '#',
  //   },
  //   {
  //     linkText: 'example page',
  //     href: '#',
  //   },
  // ]
}) => {
  return (
    <PaddingWrapper
      size="l"
      hasQuoteTop={topQuote && topQuote?.length > 0}
      hasQuoteBottom={bottomQuote && bottomQuote?.length > 0}
      hasBreadcrumbs={hasBreadcrumbs}
    >
      <NewsWrapperEle>
        <PageWrapper className="contentWrapper">
          {breadcrumbs && hasBreadcrumbs && <Breadcrumbs data={breadcrumbs} />}

          {topQuote && topQuote?.length > 0 && (
            <QuoteElement quote={topQuote} top author={topQuoteAuthor} />
          )}

          <div className="titleArea">
            {title && <TintedBlockH3> {title} </TintedBlockH3>}
          </div>

          <NewsWrapper
            card1={<Card {...cards[0]} type="large" />}
            card2={<Card {...cards[1]} type="medium" />}
            card3={<Card {...cards[2]} type="small" />}
            card4={<CardAlle {...alleCard} />}
          />
          {bottomQuote && bottomQuote?.length > 0 && (
            <QuoteElement
              quote={bottomQuote}
              bottom
              author={bottomQuoteAuthor}
            />
          )}
        </PageWrapper>
      </NewsWrapperEle>
    </PaddingWrapper>
  )
}
export default NewsSection
