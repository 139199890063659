import React from 'react'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

// TextField
const CheckboxField = (props) => {
  return (
    <FormControlLabel
      control={<Checkbox color={'primary'} onChange={props.handleChange} />}
      {...props}
      labelPlacement="end"
    />
  )
}
export default CheckboxField
