import React from 'react'
import styled from 'styled-components'

// For large sections
// Sectios with a lot of content need less padding
const XL = styled.div`
  padding: calc(80px + ${(props) => props.extraPaddingTop}px) 0
    calc(80px + ${(props) => props.extraPaddingBottom}px);

  ${(props) =>
    props.applyTopPadding === false ? 'padding-top: 0 !important;' : ''}
  ${(props) =>
    props.applyBottomPadding === false ? 'padding-bottom: 0 !important;' : ''}
`

// For large sections
// Sectios with a lot of content need less padding
const L = styled.div`
  padding: calc(80px + ${(props) => props.extraPaddingTop}px) 0
    calc(80px + ${(props) => props.extraPaddingBottom}px);

  ${(props) =>
    props.applyTopPadding === false ? 'padding-top: 0 !important;' : ''}
  ${(props) =>
    props.applyBottomPadding === false ? 'padding-bottom: 0 !important;' : ''}

  @media screen and (max-height: 940px) {
    padding: calc(80px + ${(props) => props.extraPaddingTop}px) 0
      calc(80px + ${(props) => props.extraPaddingBottom}px);

    ${(props) =>
      props.applyTopPadding === false ? 'padding-top: 0 !important;' : ''}
    ${(props) =>
      props.applyBottomPadding === false ? 'padding-bottom: 0 !important;' : ''}
  }
`

// For medium sections
// Average section
const M = styled.div`
  padding: calc(80px + ${(props) => props.extraPaddingTop}px) 0
    calc(80px + ${(props) => props.extraPaddingBottom}px);

  ${(props) =>
    props.applyTopPadding === false ? 'padding-top: 0 !important;' : ''}
  ${(props) =>
    props.applyBottomPadding === false ? 'padding-bottom: 0 !important;' : ''}

  @media screen and (max-height: 940px) {
    padding: calc(80px + ${(props) => props.extraPaddingTop}px) 0
      calc(80px + ${(props) => props.extraPaddingBottom}px);

    ${(props) =>
      props.applyTopPadding === false ? 'padding-top: 0 !important;' : ''}
    ${(props) =>
      props.applyBottomPadding === false ? 'padding-bottom: 0 !important;' : ''}
  }
`

// For small sections
// Small sections need exra padding
const S = styled.div`
  padding: calc(80px + ${(props) => props.extraPaddingTop}px) 0
    calc(80px + ${(props) => props.extraPaddingBottom}px);

  ${(props) =>
    props.applyTopPadding === false ? 'padding-top: 0 !important;' : ''}
  ${(props) =>
    props.applyBottomPadding === false ? 'padding-bottom: 0 !important;' : ''}

  @media screen and (max-height: 940px) {
    padding: calc(80px + ${(props) => props.extraPaddingTop}px) 0
      calc(80px + ${(props) => props.extraPaddingBottom}px);

    ${(props) =>
      props.applyTopPadding === false ? 'padding-top: 0 !important;' : ''}
    ${(props) =>
      props.applyBottomPadding === false ? 'padding-bottom: 0 !important;' : ''}
  }
`

const PaddingWrapper = ({
  size = 'm',
  children,
  hasBreadcrumbs,
  hasQuoteTop,
  hasQuoteBottom,
  additionalTopQuotePadding = 0,
  applyTopPadding,
  applyBottomPadding,
  additionalBottom = 0,
}) => {
  const mapSize = {
    s: S,
    m: M,
    l: L,
    xl: XL,
  }

  // Determine if extra paddings are neeed
  const quouteTopPadding = hasQuoteTop ? 80 : 0
  const quouteBottomPadding = hasQuoteBottom ? 120 : 0

  const breadCrumbPadding = hasBreadcrumbs ? 0 : 0

  // Top
  const extraPaddingTop =
    breadCrumbPadding + quouteTopPadding + additionalTopQuotePadding
  const extraPaddingBottom = quouteBottomPadding + additionalBottom

  // Render
  const Wrapper = mapSize[size]

  return (
    <Wrapper
      extraPaddingTop={extraPaddingTop}
      extraPaddingBottom={extraPaddingBottom}
      applyTopPadding={applyTopPadding}
      applyBottomPadding={applyBottomPadding}
    >
      {children}
    </Wrapper>
  )
}
export default PaddingWrapper
