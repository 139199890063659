import styled from 'styled-components'
import React from 'react'

export const WIDTH = 1700

export const PageWrapperEle = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: ${WIDTH}px;
  position: relative;

  @media screen and (max-width: 2050px) {
    padding: 0 120px;
    max-width: 100%;
  }

  @media screen and (max-width: 1280px) {
    padding: 0 60px;
    max-width: 100%;
  }

  @media screen and (max-width: 1000px) {
    padding: 0 8vw;
    max-width: 100%;
  }
`

export const PageWrapper = ({
  children,
  dontRenderWidthContainer,
  eleRef,
  className,
}) => {
  if (dontRenderWidthContainer) {
    return (
      <div className={className} ref={eleRef}>
        {' '}
        {children}{' '}
      </div>
    )
  }

  return (
    <PageWrapperEle className={className} ref={eleRef}>
      {children}
    </PageWrapperEle>
  )
}
