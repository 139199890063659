import React from 'react'

import CalculationBlock from '../../../core/modules/calculationBlock'
import SectionWrapper from '../../../core/modules/sectionBackgroundWrapper'

const defaultSectionData = {
  type: 'blank',
  paddingType: 'blank',
  notFullWidth: true,
}

const Section = (props) => {
  const newProps = { ...defaultSectionData, ...props.sectionData }
  return (
    <SectionWrapper
      {...newProps}
      topQuote={props.topQuote}
      bottomQuote={props.bottomQuote}
      hasBreadcrumbs={props.hasBreadcrumbs}
    >
      <CalculationBlock {...props} />
    </SectionWrapper>
  )
}

Section.defaultProps = {
  sectionData: {
    type: 'blank',
    paddingType: 'blank',
  },
}
export default Section
