import React from 'react'
import styled from 'styled-components'

// Core
import { P, TintedBlockH3 } from '../../elements/typography'

// Placeholder image
// import imageElement from '../../../assets/media/person.png'
import BuildLinkElement from '../../elements/linkBuilder'
import BuildImage from '../../elements/imageBuilder'

// Module styling
const FeaturedBlockWrapper = styled.div`
  background-size: auto 100%;
  padding: 60px 0;

  .textWrapper {
    width: 60%;
    display: inline-block;
    vertical-align: top;
    padding-right: 115px;

    .titleWrapper {
      line-height: 4.2rem;
      min-height: 4.2rem;

      h3 {
        font-size: 2.5rem;
      }
    }
  }

  .imageWrapper {
    display: inline-block;
    width: 40%;
    margin-top: 30px;

    img {
      max-width: 100%;
      float: right;
    }
  }

  .linkWrapper {
    display: block;
    vertical-align: top;
    padding-top: 60px;

    span {
      a {
        font-size: 1rem;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .imageWrapper,
    .textWrapper {
      width: 100%;

      img {
        float: none;
        display: block;
      }
    }

    .textWrapper {
      padding: 0;
      padding-bottom: 40px;
    }
  }
`

const FeaturedBlock = ({ title, image = {}, desc, link = {} }) => {
  return (
    <FeaturedBlockWrapper backgroundImage={image}>
      <div className="textWrapper">
        <div className="titleWrapper">
          <TintedBlockH3> {title} </TintedBlockH3>
        </div>
        <P text={desc} />
      </div>
      <div className="imageWrapper">
        <BuildImage image={image} />
      </div>
      <div className="linkWrapper">
        <BuildLinkElement link={link} linkStyle="ViewAllLink" showArrow />
      </div>
    </FeaturedBlockWrapper>
  )
}
FeaturedBlock.defaultProps = {}
export default FeaturedBlock
