import styled from 'styled-components'
import { createTheme } from '@material-ui/core/styles'

export default styled.div`
  // This helps us fade in route changes
  .page {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .page-enter {
    opacity: 0.3;
    transition: opacity 0.5s ease;
    transition-delay: 0s;
  }
  .page-enter-active {
    opacity: 1;
    transition-delay: 500ms;
  }
  .page-exit {
    opacity: 1;
    transition-delay: 500ms;
  }
  .page-exit-active {
    opacity: 0;
    transition: opacity 0.5s ease;
    transition-delay: 0s;
  }

  .MuiCheckbox-colorPrimary.Mui-checked {
    color: #005496 !important;
  }

  // Application global styling
  font-family: 'Ekster', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 200;

  .scale11 {
    transform: scale(1.5);
    margin-left: 5px;
    position: relative;
    top: -1px;
  }

  .MuiInputLabel-root {
    text-transform: inherit !important;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  div {
    display: block;
  }

  div {
    box-sizing: border-box;
  }

  a {
  }

  ul,
  li {
    padding: 0;
  }
`

export const materialUiTheme = () => {
  const theme = createTheme({
    overrides: {
      MuiInputBase: {
        input: {
          height: '20px',
          color: '#6a9ac2',
          fontSize: '14px',
          marginBottom: '22px',
        },
      },
      MuiInput: {
        underline: {
          '&:before': {
            borderBottom: '1px solid #87a9ca !important',
          },
          '&:after': {
            display: 'none !important',
          },
        },
      },
      MuiButtonBase: {
        root: {
          fontFamily: "'Ekster', sans-serif !important",
        },
      },
      MuiInputLabel: {
        root: {
          fontSize: '0.8rem',
          fontWeight: '400',
          textTransform: 'inherit',
          fontFamily: "'Ekster', sans-serif !important",
          color: '#005496',
          '&:focused': {
            color: '#005496',
          },
        },
      },
      MuiSelect: {
        root: {
          fontSize: '0.8rem',
          fontWeight: '200',
          textTransform: 'inherit',
          fontFamily: "'Ekster', sans-serif !important",
          color: '#005496',
          '&:focused': {
            background: 'none !important',
          },
        },
        select: {
          background: 'none !important',
          '&:focused': {
            background: 'none !important',
          },
        },
      },
      MuiMenuItem: {
        root: {
          fontSize: '0.8rem',
          fontWeight: '200',
          textTransform: 'inherit',
          fontFamily: "'Ekster', sans-serif !important",
          color: '#005496',
        },
      },
      MuiBox: {
        root: {
          border: 'none !important',
        },
      },
    },
    components: {
      // Name of the component ⚛️
      MuiButtonBase: {
        defaultProps: {
          // The props to apply
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
      },
    },
    props: {
      // Name of the component
      MuiButtonBase: {
        // The properties to apply
        disableRipple: true, // No more ripple, on the whole application!
      },
    },
  })

  return theme
}
