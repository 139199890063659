import React from 'react'
import styled from 'styled-components'

import { QuoteH2 } from '../elements/typography'

const QuoteWrapper = styled.div`
  text-align: center;
  position: relative;
  margin: 0 auto;
  ${(props) => (props.posKey ? `${props.posKey}: -85px;` : '')}

  max-width: 1200px;

  ${(props) =>
    props.givespacing
      ? `
    padding: 40px;
    
    @media screen and (max-width: 750px) {
      padding: 40px 0;
    }
  `
      : ''}

  .author {
    display: block;
    max-width: 1200px;
    margin: 0 auto;
    text-align: right;
    font-size: 0.8rem;
    padding-top: 12px;
    color: #005496;
    font-weight: bold;
  }
`

const QuoteElement = ({ quote, top, bottom, author, nopad, givespacing }) => {
  return (
    <QuoteWrapper
      givespacing={givespacing}
      posKey={!nopad ? (top ? 'top' : bottom ? 'bottom' : '') : ''}
    >
      <QuoteH2>"{quote}"</QuoteH2>
      {author && <div className="author">- {author}</div>}
    </QuoteWrapper>
  )
}
export default QuoteElement
