// 3rd party modules
import React from 'react'

import { createGlobalStyle } from 'styled-components'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

// Material UI - Used for extra element / modules such as form elements
import { ThemeProvider } from '@material-ui/core/styles'

// Internal modules
import ApplicationStyling, { materialUiTheme } from './styles'
import ApplicationRouting from './router'
import ApplicationState from './context'
import ApplicationApiState from './core/utils/api/apiStateContext'

// Global styling
const GlobalStyle = createGlobalStyle`
  body, html {
    padding: 0 !important;
    margin: 0 !important;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .MuiTypography-body1 {
    font-family: 'Ekster', sans-serif !important;
    -webkit-font-smoothing: antialiased !important;
    font-weight: 200 !important;
  }

  .MuiCheckbox-root .MuiTypography-body1 {
    font-size: 0.8rem !important;
    color: #2E72A8 !important;
  }

  ul, li {
    list-style: none;
  }

  .allowListStyling {
    ul li {
      list-style: initial;
      padding: initial;
    }
    ol li {
      list-style: unset;
    }

    ul {
      padding-left: 22px !important;
    }
  }

  @media screen and (max-width: 600px) {
    body, html {
      font-size: 16px;
    }
  }

  .shareicon {
    margin: 0px 12px 0 0;
    position: relative;
    top: 8px;
  }
`

// Main application wrapper
// Here we add our global styling, set global context and routing.
// We also use this wrapper inside storybook, to auto inherit all our state
// children will only be passed in storybook, otherwise it will load the whole app.
const RouterWrapper = ({ children }) => {
  const theme = materialUiTheme()
  const csrfEndpoint = '/sanctum/csrf-cookie'

  return (
    <Router>
      <Switch>
        <Route path="*">
          <ApplicationStyling>
            <ThemeProvider theme={theme}>
              <ApplicationApiState
                CSRFTokenEndpoint={csrfEndpoint}
                CSRFTokenHeader="X-XSRF-TOKEN"
              >
                <ApplicationState>
                  <GlobalStyle />
                  {!children && <ApplicationRouting />}
                  {children}
                </ApplicationState>
              </ApplicationApiState>
            </ThemeProvider>
          </ApplicationStyling>
        </Route>
      </Switch>
    </Router>
  )
}

export default RouterWrapper
