import React from 'react'
import styled from 'styled-components'

import TextField from '@material-ui/core/TextField'

// SelectBox overwrites
const SelectBoxStyling = styled.div`
  label[data-shrink='true'] {
    transform: translate(0, -10px) scale(1) !important;
    transform-origin: top left !important;
    font-weight: 200;
    color: #005496 !important;
  }

  label[data-shrink='false'] {
    transform: translate(0, -10px) scale(1) !important;
    transform-origin: top left !important;
    font-weight: 200;
    color: #005496 !important;
  }
`

// TextField
const InputField = (props) => {
  return (
    <SelectBoxStyling>
      <TextField {...props} onChange={props.handleChange} />
    </SelectBoxStyling>
  )
}
export default InputField
