import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import TextField from '@material-ui/core/TextField'
import { ReactComponent as Search } from '../../assets/svg/ic_search.svg'
import { ReactComponent as ArrowRight } from '../../assets/svg/arrow_right.svg'

// SelectBox overwrites
const SelectBoxStyling = styled.div`
  display: block;
  background: #00a1d5;

  @media screen and (max-width: 680px) {
    background: #fff;
  }

  .searchbutton {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    width: 42px;
    height: 48px;
    background-color: #00a1d5;

    svg {
      width: 20px;
      height: 20px;
      margin: 14px 12px;
      g,
      line {
        stroke: #fff;
      }
    }
  }

  label[data-shrink='true'] {
    transform: translate(0, -10px) scale(1) !important;
    transform-origin: top left !important;
    font-weight: 200;
    color: #005496 !important;
  }

  label[data-shrink='false'] {
    transform: translate(0, -10px) scale(1) !important;
    transform-origin: top left !important;
    font-weight: 200;
    color: #005496 !important;
  }

  input {
    color: #000000;
    ::before {
      display: none;
    }
  }
  .searchinput {
    display: inline-block;
    vertical-align: middle;
    height: 48px;
    border: 1px solid #005496;
    border-right: 0;
    padding-top: 10px;
    padding-left: 30px;
    position: relative;
    padding-right: 0.5rem;
    width: calc(100% - 42px);
    background-color: #fff;
    ${(props) =>
      props.fullWidth ? `width: calc(100% - 42px)` : 'max-width: 530px;'}
  }

  .MuiInput-underline:before,
  .MuiInput-underline:after {
    display: none;
  }
  .backButton {
    display: none;
  }

  @media screen and (max-width: 680px) {
    .searchbutton {
      height: 42px;
      width: 42px;

      svg {
        margin: 10px;
      }
    }
    .searchinput {
      /* width: calc(100% - 32px); */
      height: 42px;
      padding-left: 1rem;
      input {
        padding: 10px 0;
      }
    }
    ${(props) =>
      props.fullWidth
        ? `
            padding: 1.5rem;
            padding-bottom: 0;

            .backButton {
                display: inline-block;
                vertical-align: middle;
                width: 36px;
                height: 42px;
                svg {
                    transform: rotate(180deg);
                    width: 28px;
                    height: 42px;
                }
            }
            .searchinput {
                width: calc(100% - 78px);
            }
          `
        : `
            .searchinput {
                width: calc(100% - 42px);
            }
        `}
        padding-bottom: 0;

        .backButton {
            display: inline-block;
            vertical-align: middle;
            width: 36px;
            height: 42px;
            svg {
                transform: rotate(180deg);
                width: 28px;
                height: 42px;
            }
        }
        .searchinput {
            width: calc(100% - 78px);
            height: 42px;
            padding-left: 1rem;
            input {
                padding: 0px 0;
            }
        }
        .searchbutton {
            height: 42px;
            width: 42px;
            svg {
                margin: 10px;
            }
        } */
  }

  .errorwrapper {
    color: red;
    font-size: 0.8rem;
    padding: 22px 32px 12px;
  }
`

// TextField
const SearchbarV2 = ({
  search,
  setSearch,
  handleSearch,
  hide,
  texts,
  setSelectedFilters,
  fullWidth = false,
  lengthError,
  handleAutoFocus,
}) => {
  const keypress = (event) => {
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault()
      event.stopPropagation()
      // Trigger the button element with a click
      handleSearch()
    }
  }

  if (hide) {
    return null
  }

  let searchHandler = (e) => {
    if (!e.target?.value) {
      // reset query
      setSearch('')
      setSelectedFilters({
        category: 'Alles',
      })
      handleSearch()
    }
  }

  return (
    <SelectBoxStyling id="searchbar" fullWidth={fullWidth}>
      <div className="backButton">
        <Link to={(location) => `${location.pathname}`}>
          <ArrowRight />
        </Link>
      </div>
      <div className="searchinput">
        <TextField
          placeholder={texts.inputPlaceholder}
          id="searchinput"
          autoFocus={handleAutoFocus}
          onKeyDown={(e) => keypress(e)}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="search"
          ref={(element) => ((element || {}).onsearch = searchHandler)}
        />
      </div>
      <div
        id="searchbutton"
        className="searchbutton"
        onClick={() => handleSearch()}
      >
        <Search />
      </div>
      {lengthError && (
        <div className="errorwrapper">
          {texts.lengthError || 'U moet een zoekterm van 3 tekens invoeren'}
        </div>
      )}
    </SelectBoxStyling>
  )
}
SearchbarV2.defaultProps = {
  handleSearch: () => console.log('Search!'),
}
export default SearchbarV2
