import React from 'react'
import styled from 'styled-components'

import expertise_image from '../../../assets/svg/ic_expertise_1.svg'

// Card Wrap
const Card = styled.img`
  max-width: calc(100% - 4rem);
  max-height: 100%;
  overflow: hidden;
  padding: 2rem;
  width: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 680px) {
    max-width: 150px;
  }
`

const Wrapper = styled.div`
  display: inline-block !important;
  vertical-align: top;
  width: 33%;
  height: 100%;
  overflow: hidden;

  @media screen and (max-width: 680px) {
    width: 100%;
  }
`

// Element
const CardImageElement = () => {
  return (
    <Wrapper>
      <Card src={expertise_image} alt="" />
    </Wrapper>
  )
}
CardImageElement.defaultProps = {}

export default CardImageElement
