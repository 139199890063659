import React from 'react'

import SectionWrapper from '../../../core/modules/sectionBackgroundWrapper'
import AllCompanyBlock from '../../../core/modules/companyFamily'

// Config for this section
const defaultSectionData = {
  type: 'blank',
  notFullWidth: true,
}

// Render the section
const AllBVs = ({
  title,
  desc,
  companies,
  cards,
  sectionData,
  topQuote,
  topQuoteAuthor,
  bottomQuote,
  bottomQuoteAuthor,
}) => {
  const newProps = { ...defaultSectionData, ...sectionData }
  return (
    <SectionWrapper
      paddingSize="l"
      {...newProps}
      topQuote={topQuote}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
    >
      <AllCompanyBlock
        title={title}
        desc={desc}
        companies={companies}
        cards={cards}
      />
    </SectionWrapper>
  )
}
AllBVs.defaultProps = {}
export default AllBVs
