import React from 'react'
import styled from 'styled-components'

const LoaderElement = styled.div`
  margin: 80px auto;
  width: 80px;
  height: 80px;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 6px solid #005496;
    border-radius: 50%;
    animation: lds-ring 0.8s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #005496 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.25s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.1s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const Loader = () => {
  return (
    <LoaderElement>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </LoaderElement>
  )
}
export default Loader
