import React from 'react'
import styled from 'styled-components'

// Placeholder image
import BuildLinkElement from '../../elements/linkBuilder'

// Module styling
const AllLinkWrapper = styled.div`
  width: 100%;
`

const CompanyLink = styled.span`
  width: 50%;
  display: inline-block !important;
  line-height: 2.5rem;
  font-weight: bold;

  span {
    a {
      font-size: 1.25rem;
    }
  }

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`

const LinkBlock = ({ links }) => {
  if (!links || links?.length === 0) {
    return null
  }

  return (
    <AllLinkWrapper>
      {links &&
        links.map &&
        links.map((link) => {
          return (
            <CompanyLink key={link?.linkText}>
              <BuildLinkElement link={link} linkStyle="CompanyLink" />
            </CompanyLink>
          )
        })}
    </AllLinkWrapper>
  )
}
LinkBlock.defaultProps = {}
export default LinkBlock
