import React from 'react'
import styled from 'styled-components'
import InfoElement from '../contactPersonInfo'
import AddressElement from './contactAdress'

const Wrapper = styled.div`
  width: 100%;

  > div {
    padding-left: 12%;
    svg {
      width: 6rem;
      g {
        path,
        line {
          stroke: #fff;
          fill: #fff;
        }
      }
    }
    .textWrapper {
      p {
        color: #fff !important;
      }
    }

    :first-child {
      padding-top: 0;
    }
  }

  @media screen and (max-width: 1500px) {
    > div {
      padding-left: 8%;
    }
  }

  @media screen and (max-width: 1400px) {
    > div {
      padding-left: 5%;
    }
  }

  @media screen and (max-width: 1300px) {
    > div {
      padding-left: 0%;
    }
  }
`

// Element
const ContactInfo = ({ contact }) => {
  return (
    <Wrapper>
      <AddressElement smallIcon {...contact.visitAddress} />
      <AddressElement {...contact.postAddress} />
      <InfoElement {...contact.contact} />
    </Wrapper>
  )
}
ContactInfo.defaultProps = {
  contact: {
    visitAddress: {},
    postAddress: {},
    contact: {},
  },
}

export default ContactInfo
