import React from 'react'
import styled from 'styled-components'
// import { useHistory } from 'react-router-dom'

// Core
import { AlleNieuwsH3 } from '../../elements/typography'
import { ReactComponent as Rightarrow } from '../../../assets/svg/arrow_right.svg'
import { BuildLinkWrapper } from '../../elements/linkBuilder'

// Card Wrap
const Card = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background: #02a1d5;

  img {
    min-width: 100%;
    min-height: 100%;
    max-width: 600px;
  }

  .information {
    position: absolute;
    top: 0;
    left: 0;
    padding: 22px;
    color: #fff !important;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    width: 100%;

    h3 {
      color: #fff !important;
      max-width: 260px;
    }

    a {
      color: #fff !important;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    span {
      font-size: 1rem;
      position: absolute;
      bottom: 22px;
      right: 22px;
      font-weight: 400;

      svg {
        display: inline-block;
        vertical-align: middle;
        width: 2rem;
        height: 2rem;

        g path,
        g line {
          stroke: #fff;
        }
      }
    }
  }
`

// Card variants
const Small = styled.div`
  width: 100%;
  height: 350px;
  min-height: 260px;
`
// Element
const CardElement = ({ title, link }) => {
  const Wrapper = Small
  // const history = useHistory()

  // const handleClick = useCallback(() => {
  //   history.push(link?.href || '/')
  // }, [history, link])

  return (
    <Wrapper>
      <BuildLinkWrapper link={link}>
        <Card>
          <div className="information">
            <AlleNieuwsH3> {title} </AlleNieuwsH3>
            <span>
              {link?.linkText}
              <Rightarrow />
            </span>
          </div>
        </Card>
      </BuildLinkWrapper>
    </Wrapper>
  )
}
export default CardElement
