import React from 'react'
import styled from 'styled-components'
import { Characteristic } from '../../elements/typography'
import { ReactComponent as PhoneIcon } from '../../../assets/svg/ic_call.svg'
import ProtectedLink from '../../elements/protectedLink'

const Wrapper = styled.div`
  display: inline-block !important;
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: #005496;

  svg {
    display: inline-block;
    vertical-align: middle;
    padding-right: 22px;
    width: 3rem;

    g path {
      stroke: #fff;
      fill: #fff;
    }
  }

  .textWrapper {
    display: inline-block;
    vertical-align: middle;
    p {
      color: #fff;
      font-weight: bold;
    }
  }

  @media screen and (max-width: 400px) {
    padding: 1rem 0;

    svg {
      padding: 0;
      display: block;
      margin-bottom: 20px;
      margin-left: 10px;
      display: none;
    }
    .textWrapper {
      padding: 8px 0;
    }
  }
`

const EmailIcon = styled.div`
  display: inline-block !important;
  vertical-align: middle;
  width: 78px;
  height: 3rem;
  font-size: 2.5rem;
  color: #fff;
  font-weight: bold;

  @media screen and (max-width: 400px) {
    display: none !important;
  }
`

const typestringMap = {
  phone: 'Telefoon',
  email: 'E-mail',
}

// Element
const InfoElement = ({ type, input }) => {
  let typestring = typestringMap[type]

  return (
    <Wrapper>
      {type == 'phone' ? <PhoneIcon /> : <EmailIcon>@</EmailIcon>}
      <div className="textWrapper">
        <Characteristic>
          {typestring}: <ProtectedLink link={input} type={type} />
        </Characteristic>
      </div>
    </Wrapper>
  )
}
InfoElement.defaultProps = {}

export default InfoElement
