import React from 'react'
import styled from 'styled-components'

const ErrorElement = styled.div`
  padding: 22px;
  color: #fff;
  background: rgba(150, 0, 0, 0.6);
  margin: 12px 0;
  white-space: break-spaces;
`

const ErrorComponent = ({
  message = 'Er is een onverwachte fout opgetreden',
}) => {
  return (
    <ErrorElement>
      <strong>Fout:</strong> {message}
    </ErrorElement>
  )
}
export default ErrorComponent
