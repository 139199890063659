import React from 'react'

// Components
import SwipableVacancyComponent from '../../modules/swipeableVacanciesBlock'
import SwipableComponent from '../../modules/swipableProjectCards/v2'
import SectionWrapper from '../../modules/sectionBackgroundWrapper'

// Cards
import VacancyCard from '../../modules/vacancyCard/vacancyCard'
import ProjectCard from '../../modules/projectCard'

const cardMapping = {
  vacancy: VacancyCard,
  project: ProjectCard,
}

// View
const VacanciesSectionWrapper = (props) => {
  const {
    viewType,
    cardType,
    cardSize,
    backgroundType,
    titleType,
    topQuote,
    topQuoteAuthor,
    bottomQuote,
    bottomQuoteAuthor,
    hasBreadcrumbs,
    breadcrumbs,
    data,
    title,
    subTitle,
    sectionData,
    linkCard,
    showMoreCardInformation,
    showCardArrows,
    portraitImage,
    imagePosition
  } = props

  const baseSectionData = {
    type: backgroundType,
    titleType: titleType,
    notFullWidth: viewType === 'slider' ? false : true,
  }

  const elementRef = React.useRef()
  const newProps = { ...baseSectionData, ...sectionData }
  const Card = cardMapping[cardType]
  const backgroundColorOfParent = elementRef
    ? elementRef?.current?.closest('[data-background-color]').dataset
        ?.backgroundColor
    : 'null'

  let portraitImageProp = portraitImage || false
  data.forEach((item) => {
    if (item?.image?.portraitImage) {
      portraitImageProp = true
    }
  })

  return (
    <SectionWrapper
      {...newProps}
      breadcrumbs={breadcrumbs}
      hasBreadcrumbs={hasBreadcrumbs}
      topQuote={topQuote}
      topQuoteAuthor={topQuoteAuthor}
      bottomQuote={bottomQuote}
      bottomQuoteAuthor={bottomQuoteAuthor}
      title={title && title.length > 0 ? title : null}
      subtitle={subTitle && subTitle.length > 0 ? subTitle : null}
    >
      <div ref={elementRef}></div>
      {viewType === 'slider' && (
        <>
          {cardType === 'vacancy' && linkCard && (
            <SwipableVacancyComponent
              data={data}
              portraitImage={portraitImageProp}
              imagePosition={imagePosition}
              showCardArrows={showCardArrows}
              allVacanciesTitle={linkCard.title}
              allVacanciesDesc={linkCard.desc}
              allVacanciesLink={linkCard.link}
              variant={cardType}
              cardSize={cardSize}
              backgroundColorOfParent={backgroundColorOfParent}
              showMoreCardInformation={showMoreCardInformation}

            />
          )}
          {cardType !== 'vacancy' && (
            <SwipableComponent
              linkCard={linkCard}
              data={data}
              portraitImage={portraitImageProp}
              imagePosition={imagePosition}
              showCardArrows={showCardArrows}
              variant={cardSize}
              backgroundColorOfParent={backgroundColorOfParent}
              showMoreCardInformation={showMoreCardInformation}
            />
          )}
        </>
      )}

      {viewType === 'list' && (
        <div style={{ padding: '40px 0' }}>
          {data &&
            data.map &&
            data.map((cardData, i) => {
              return (
                <Card
                  key={`vacancy-${i}-${cardData.title}`}
                  {...cardData}
                  showMoreCardInformation={showMoreCardInformation}
                  type={cardSize}
                  variant={cardSize}
                  portraitImage={portraitImageProp}
                  listView
                />
              )
            })}
        </div>
      )}
    </SectionWrapper>
  )
}
export default VacanciesSectionWrapper
